import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {

//==================================UNIDADES==========================================
    register_units({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "master/unity/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_units({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "master/unity/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_units({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "master/unity/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_units({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "master/unity/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadUnits({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "master/unity/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

//=================================TRABAJADORES=====================================

    register_workers({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "worker/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_workers({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "worker/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_workers({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "worker/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_workers({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "worker/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadWorkers({ commit, dispatch }, ) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "worker/all";
        // if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

//====================================ROLES===========================================

    register_role({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "master/rol/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_role({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "master/rol/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_role({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "master/rol/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_role({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "master/rol/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadRoles({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "master/rol/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

//======================================CATEGORIAS======================================
    register_category({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "master/category/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_category({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "master/category/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_category({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "master/category/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadCategories({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "master/category/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_category({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "master/category/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

//======================================CONTADORES INICIO =============================
    loadCantsAll({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "master/statistics/dashboard/counters";
        if (query) endpoint += query;
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};