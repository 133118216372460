<template>
  <div id="AllMaterials">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
             <h5>Materiales</h5>
             <br/>
          <p>En esta ventana, usted podrá agregar, modificar o eliminar materiales existentes.</p>
          <p style="font-weight:600">Cantidad de materiales: {{ element_count }}</p>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button  block class="primary" :to="{ name: 'admin-materials-new' }">Crear material</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <b-row v-show="!tablet">
        <b-col lg="4" class="my-1">
          <b-form-group
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
             

              <b-form-input
                id="filterInput"
                v-model="filter"
                v-on:keyup="allElements(1)"
                type="text"
                placeholder="Buscar material"
              />

              <b-input-group-append>
                <button
                  class="primary2"
                  :disabled="!filter"
                  @click="clearFilter"
                >
                  <font-awesome-icon class="iconTable" :icon= "['fas', 'times']" title="Limpiar busqueda"/>
                </button>
              </b-input-group-append>

               <b-input-group-append>
                <button class="primary2">
                   <font-awesome-icon class="iconTable" :icon= "['fas', 'search']" title="Buscar"/>
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
           <b-col lg="4" class="my-1" >
             <b-form-group 
            label="Categoría:"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              id="perPageSelect"
              v-model="category"
              size="sm"
              :options="listCategory"
              @change="allElements(1)"
              value-field="id" text-field="name" > 
            <b-form-select-option :value="null" >Seleccione una categoría</b-form-select-option>
             </b-form-select > 
            
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
        </b-col>
        <b-col lg="2" class="my-1">
        </b-col>
      </b-row>
       <b-row v-show="tablet">
             
          <b-col md="6" class="my-1">
             <b-form-group
            >
              <b-input-group size="sm">
              <b-input-group-append>
                  <button class="primary2" > <font-awesome-icon class="iconTable" :icon= "['fas', 'search']"  title="Buscar" /></button>
                </b-input-group-append>
              
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Buscar material"
                />
                
                <b-input-group-append>
                  <button class="primary2" :disabled="!filter" @click="clearFilter"><font-awesome-icon class="iconTable" :icon= "['fas', 'times']"  title="Limpiar busqueda"/></button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          
          </b-col>
             <b-col md="6" class="my-1">
             <b-form-group 
            label="Categoría:"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              id="perPageSelect"
              v-model="category"
              size="sm"
              :options="listCategory"
              @change="filterCategory()"
              value-field="id" text-field="name" > 
            <b-form-select-option :value="null" >Seleccione una categoría</b-form-select-option>
             </b-form-select > 
            
          </b-form-group>
          
          </b-col>
        </b-row>
      <b-row class="mt-3 px-3">
        <b-col sm="12">
          <div>
            <b-table
              responsive
              id="my-table"
              show-empty
              small
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="elementsTMP"
              :fields="field"
              
              :per-page="perPage"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              empty-text="No hay materiales disponibles"
              :busy="isBusy"
            
            >
              <!--                :filter="filter"-->
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>

               <template v-slot:cell(date)="row">
                  <p>{{dateFormat(row.item.created_at)}} </p>
                </template>
                     <template v-slot:cell(image)="row">
                    <img v-if="row.item.image !=null"
              class="img-responsive center-block d-block mx-auto"
              style="
                width: 80px;
                text-align: center;
              "
              :src="row.item.image"
            />
            <img v-if="row.item.image ==null  || row.item.image=='' "  style="
                width: 80px;
                text-align: center;
              " class="img-responsive center-block d-block mx-auto imgStyles_"
              src="../../../../assets/img/image.png"/>
            
              </template>
              <template v-slot:cell(unityName)="row">
                <p style="margin-left:15px">{{row.item.unity.name}}</p>
                </template>

              <template v-slot:cell(actions)="row">
                  <button
                      size="sm"
                      class="primary2"
                      @click="add_element(row.item)"
                  >
               <font-awesome-icon class="iconTable" icon="plus" style="color: black!important;"  title="Agregar a Stock base"/>
                  </button>
                 <button
                      size="sm"
                      class="primary2"
                      @click="edit_element(row.item)"
                  >
               <font-awesome-icon class="iconTable" icon="pencil-alt" style="color: black!important;"  title="Editar"/>
                  </button>
                   <button
                    v-if="row.item.active!=1"
                      class="primary2"
                      size="sm"
                      title="Desactivado"
                      @click="changeStatus(row.item)"
                  >

                   <font-awesome-icon class="iconTable" :icon= "['far', 'square']"  title="Desactivado"/>


                  </button>
                     <button
                     v-if="row.item.active==1"
                      size="sm"
                      class="primary2"
                      title="Activado"
                      @click="changeStatus(row.item)" 
                  >

                     <font-awesome-icon class="iconTable" :icon= "['far', 'check-square']"  title="Activado"/>
                  </button>

               
                   <button
                      size="sm"
                      class="primary2"
                      @click="delete_element(row)"
                  >

                      <font-awesome-icon class="iconTable" icon="trash" style="color: red!important;"  title="Eliminar"/>
                  </button>
                </template>

            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group>
            <span v-if="mobile == false && tablet==false" style="margin-left:-70px;margin-top:25" class="mt-3">Página actual: {{ currentPage }}</span>
             <span v-if="mobile == false && tablet==true" style="margin-left:300px;margin-top:25" class="mt-3">Página actual: {{ currentPage }}</span> <p
              v-if="mobile == true && tablet==false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
            <div class="d-flex justify-content-center mb-5">
                            <ul class="pagination mb-0">

                                <template v-if='currentPage!=1'>
                                    <a class="page-link" @click="allElements(currentPage - 1)" aria-label="Atras">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Atras</span>
                                    </a>
                                </template>

                                <template v-if='currentPage==1'>
                                    <a disabled class="page-link" style="pointer-events: none" aria-label="Atras">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span class="sr-only">Atras</span>
                                    </a>
                                </template>


                                <template v-if='currentPage==1'>
                                    <li class="page-item active"><a class="page-link" @click="allElements(1)">1</a>
                                    </li>
                                    <li v-if='lastPage > 1' class="page-item"><a class="page-link"
                                            @click="allElements(2)">2</a></li>
                                </template>

                                <template v-if='currentPage>1 && currentPage!==lastPage'>
                                    <li class="page-item"><a class="page-link"
                                            @click="allElements(currentPage-1)">{{currentPage - 1}}</a>
                                    </li>
                                    <li class="page-item active"><a class="page-link"
                                            @click="allElements(currentPage)">{{currentPage}}</a>
                                    </li>
                                    <li v-if='lastPage > 2 && currentPage !== lastPage' class="page-item"><a class="page-link"
                                            @click="allElements(currentPage+1)">{{currentPage + 1}}</a></li>
                                </template>

                                <template v-if='currentPage==lastPage && lastPage > 2'>
                                    <li class="page-item"><a class="page-link"
                                            @click="allElements(currentPage-1)">{{currentPage - 1}}</a>
                                    </li>
                                    <li class="page-item active"><a class="page-link"
                                            @click="allElements(currentPage)">{{currentPage}}</a>
                                    </li>
                                </template>

                                <template v-if="currentPage!==lastPage">
                                    <a class="page-link" @click="allElements(currentPage + 1)" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Siguiente</span>
                                    </a>
                                </template>

                                <template v-if="currentPage==lastPage">
                                    <a disabled class="page-link" style="pointer-events: none" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span class="sr-only">Siguiente</span>
                                    </a>

                                </template>

                            </ul>
                        </div>
       
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
import moment from 'moment'

export default {
  name: "AllMaterials",
  data() {
    return {
      element_count: 0,
      isBusy: true,
      id: "",
      items: [{
            text: "Materiales",
          },],
      category:null,
      listCategory: [],
      listCountries: [],
      listCities: [],
      item_table: null,
      ttap_selected: null,
      field: [
            {
          key: "image",
          label: "Imagen",
          sortable: true,
        },
      
        {
          key: "code",
          label: "Código",
          sortable: true,
        },
        {
          key: "name",
          label: "Descripción",
          sortable: true,
         
        },
        {
          key: "unity.name",
          label: "Unidad",
          sortable: true,
        },
        {
          key: "category.name",
          label: "Categoría",
          sortable: true,
        },
         {
          key: "actions",
          label: "Acciones",
          sortable: false,

        }


      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      elements: [],
      elementsTMP: [],
      loading: false,
      error: "",
      mobile:false,
      tablet:false,
      page:1,
      lastPage:0
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    },
    rows() {
      return this.elementsTMP.length;
    }
  },
  mounted() {
      if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }

    this.allElements(1);
    this.allCategories();
   
  },
  methods: {
    ...mapActions([
      "loadMaterial",
      "loadMaterialPaginate",
      "loadCategories",
      "changeStatus_material"
    ]),
    dateFormat(date, format = 'DD/MM/YYYY') {
            return moment(date).format(format);
        },
    clearFilter() {
      this.elementsTMP = this.elements;
      this.filter = ''
    
      this.allElements(1);
    },

    searchInput() {

      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.elements.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            elementsTMP.push(el)
          }
        })
        this.elementsTMP = elementsTMP;
        this.element_count = this.elementsTMP.length
      } else {
        this.elementsTMP = this.elements;
        this.element_count = this.elements.length
      }
      return true;
    },
     filterCategory() {
  this.isBusy = true;

     


    
    },
       
    allElements(page) {

      if(!page){
        page = this.page
      }
      this.isBusy = true;
      let form ={
        page: page
      }
      this.elements = []
      this.elementsTMP = [];

      if(this.category){
        form.id_category = this.category
      }
      if(this.filter){
        form.query = this.filter;
      }
      this.loadMaterialPaginate(form)
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false;
             
              
              this.elements = response.data.data;
              this.elementsTMP = response.data.data;
              this.element_count = this.elements.length
             
              this.element_count = response.data.meta.total;
              this.perPage = response.data.meta.per_page
              this.currentPage = page
              this.lastPage= response.data.meta.last_page
             
      


            }
          })
          .catch(error => {
            console.log(error)
          });
    },
     allCategories() {


      this.loadCategories()
          .then(response => {
            if (response.code === 200) {
              this.listCategory = response.data;
              this.listCategory=this.listCategory.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    edit_element(data) {
      this.$router.push("/materiales/modificar/" + data.id);
    },
    add_element(data) {
      this.$router.push("/stocks/agregar/material/" + data.id);
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "¿Desea desactivar este elemento?" : "¿Desea activar este elemento?";
      // let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "Elemento inactivado"
              : "Elemento activado";
      Swal.fire({
        title: title,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Cambiar estatus",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeStatus_material(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    text: text,
                    icon: "success",
                    confirmButtonColor: "#00373B",
                  });
                  this.allElements(1);
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_element(row) {
      Swal.fire({
        title: "¿Desea eliminar este elemento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.changeStatus_material(form)
              .then(response => {
                if (response.code === 200) {
                  this.allElements(1)
                  Swal.fire({
                    title:"Elemento eliminado",
                    confirmButtonColor: "#00373B",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
  
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.primary {
  background: linear-gradient(180deg, #00373B 0%, #00373B 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
  color: white !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>