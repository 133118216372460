<template>
  <div id="AddUser">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Usuarios</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
         <b-button block class="primary3" :to="{ name: 'admin-users' }">Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
      <b-col md="10" style="text-align: -webkit-left">
           <p style="text-align: -webkit-left">Crear usuario</p>
        </b-col>
       </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="Nombre completo:">
              <b-input-group size="md">
                <b-form-input v-model="full_name"  v-bind:class="{ 'validateInput':validateInput(this.full_name,'text') }" placeholder="Nombre Completo"></b-form-input>
              </b-input-group>
              <span v-show="validateInput(this.full_name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.full_name,'text') }">El nombre es requerido</span>
            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group label="Correo Electronico:">
              <b-input-group size="md">
                <b-form-input type="email" v-model="email" v-bind:class="{ 'validateInput':validateInput(this.email,'email') }" placeholder="Correo Electronico"></b-form-input>
              </b-input-group>
              <span v-show="validateInput(this.email,'email')"  v-bind:class="{ 'validateMessage':validateInput(this.email,'email') }">El Correo Electronico es requerido</span>
            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group label="Password:">
              <b-input-group size="md">
                <b-form-input append='' v-bind:type="variable" v-model="password" v-bind:class="{ 'validateInput':validateInput(this.password,'password') }"  placeholder="* * * * * *"></b-form-input>
                <button class="primary2" @click="showPassword()" variant="success">
                    <font-awesome-icon  v-if="variable=='password'" class="iconTable" icon="eye" />
                      <font-awesome-icon  v-if="variable=='text'" class="iconTable" icon="eye-slash" />
                  </button>
              </b-input-group>
              <span v-show="validateInput(this.password,'password')" class="validateMessage">La Contraseña es requerida</span>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Roles:">
              <b-select id="perPageSelectRoles"   v-model="id_rol"  :options="listRoles" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option :value="null" >Seleccione un rol</b-form-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
           
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" style="text-align: center;">
            <br>
            <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
          </b-col>
          <b-col md="4"></b-col>
          <b-col md="4">
                  <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
              <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
        
          </b-col><!--v-on:click="add_element()"-->
        </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";


export default {
  name: "AddUser",
  data() {
    return {
      validateForm:true,
      full_name:null,
      email:null,
      password:null,
      id_rol:null,
      listRoles:[],
      listLocation:[],
      id_location:null,
      variable:"password",
      progress: false,
      btnLogin: false,
    
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
  
    this.allLocations();
     this.allRoles();
    
  },
  methods: {
    ...mapActions(["register","loadRoles","loadLocation"]),
      validateAllForm(){
        if(this.full_name == null ||this.email == null ||this.password == null){
          this.validateForm =true;
          return true
        }else{
          if(this.full_name==''||this.full_name==' '||this.full_name=='  ' || this.email==''||this.email==' '||this.email=='  ' ||this.password==''||this.password==' '||this.password=='  '){
            this.validateForm =false;
            return true
          }
          this.validateForm =false;
          return false
        }
      },
      validateInput(val,type ='text'){
        if(type == 'text' || type == 'password'){
          if(val==''||val==' '||val=='  '){
            this.validateForm =false;
            return true
          }else{
            return false
          }
        }
        if(type == 'email'){
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if(val != null && !pattern.test(val)){
            return true
          }else if(val==''||val==' '||val=='  '){ 
            return true
          }else{
            return false
          }
        }
      },
      onSubmit() {
        
        if(!this.validateAllForm()){
             this.add_element();
        }
      },
      allLocations() {
      this.isBusy = true;
      let query = "?active=1";

      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listLocation = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
     allRoles() {
      this.isBusy = true;
      let query="?active=1"
      this.loadRoles(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listRoles = response.data;
            this.listRoles=this.listRoles.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      showPassword(){
      if(this.variable=="password"){
        this.variable="text";
      }else{
        this.variable="password";
      }
      
    },
    showPassword2(){
      if(this.variable2=="password"){
        this.variable2="text";
      }else{
        this.variable2="password";
      }
      
    },
    add_element() {
       this.progress = true;
      if (
        this.full_name == null || this.email==null || this.password==null || this.id_rol==null 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        
      }else if (!this.email.includes('@')) {
        Swal.fire({
          title:"Error en el email",
          text: "Por favor verifica tu email",
          icon: 'warning',
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok"
        });
         this.progress = false;
         return;
      }

     
       let form = {
         full_name: this.full_name,
         email: this.email,
         password: this.password,
         id_rol: this.id_rol

       };


      this.register(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/usuarios/listado");
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "El email ya existe",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
            if (error.code == 409) {
            Swal.fire({
              title: "Email duplicado",
               text: "El email indicado ya está en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.name="";
              }
            });
          }
        });
    },
  },
};
</script>

<style>
.validateInput{
    border: 1px solid #dd137b!important;
    color: #dd137b!important;
    box-shadow: 0 0 0 1px!important;
}
.validateMessage{
    color: #dd137b!important;
}
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>