<template>
  <div id="AddProject">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Proyectos</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
         <b-button block class="primary3" :to="{ name: 'admin-projects-all' }">Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="10" v-show="!tablet" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left">Crear proyecto</p>
        </b-col>
      </b-row>
     <b-row v-show="mobile">
           <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre del proyecto"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col><!--v-on:click="add_element()"-->
      </b-row>

      <b-row v-show="!mobile && !tablet">
        <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre del proyecto"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
       
       
      </b-row>
      <b-row v-show="!mobile && !tablet">
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col><!--v-on:click="add_element()"-->
      </b-row>
     <b-row v-show="tablet">
         <b-col lg="12" >
          <p style="text-align: -webkit-left">Crear proyecto</p>
        </b-col>
           <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre del proyecto"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
             <b-col md="6">
        </b-col> 
             <br>
        <b-col md="12" style="text-align: center;">
     
          <p v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</p>
           <p v-show="!this.validateAllForm()">Rellene el formulario para continuar con el registro...</p>
           
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col>
        <b-col md="4"></b-col><!--v-on:click="add_element()"-->
      </b-row>
   
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AddProject",
  data() {
    return {
      validateForm:true,
      name:null,
      progress:false,
      mobile:false,
      tablet:false
      
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
    if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
   
   
  },
  methods: {
    ...mapActions([
      "register_project",
    ]),
    validateAllForm(){
      if(this.name == null){
        this.validateForm =true;
        return true
      }else{
        if(this.name==''||this.name==' '||this.name=='  '){
          this.validateForm =false;
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
    onSubmit() {
      if(!this.validateAllForm()){
          this.add_element();
      }
    },
    add_element() {
      this.progress = true;
      if (
        this.name == "" ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
     
      let form = {
        name: this.name
      };
     
      this.register_project(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/proyectos/listado");
              }
            });
          }

          if (response.code == 400) {
            Swal.fire({
              title: "Nombre duplicado",
              text: "el nombre indicado, ya se encuentra en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.name="";
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>
