<template>
  <div id="UpdateUnit">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Unidades</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary3" :to="{ name: 'admin-units-all' }">
            Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left" v-show="!tablet">Modificar unidad</p>
        </b-col>
      </b-row>

     <b-row v-show="mobile">
           <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre de la unidad"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Modificar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col><!--v-on:click="add_element()"-->
      </b-row>

      <b-row v-show="!mobile  && !tablet">
        <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre de la unidad"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
       
       
      </b-row>
      <b-row v-show="!mobile && !tablet">
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Modificar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col><!--v-on:click="add_element()"-->
      </b-row>
          <b-row v-show="tablet">
           <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre de la unidad"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
             <b-col md="6">
        </b-col> 
             <br>
        <b-col md="12" style="text-align: center;">
     
          <p v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</p>
           <p v-show="!this.validateAllForm()">Rellene el formulario para continuar con el registro...</p>
           
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
               <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
        </b-col>
        <b-col md="4"></b-col><!--v-on:click="add_element()"-->
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "UpdateUnit",
  data() {
    return {
      validateForm:true,
      name: null,
      progress: false,
      mobile:false,
      tablet:false
      
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
     if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    this.allUnits();
    this.consult()
  },
  methods: {
    ...mapActions([
      "modify_units","consult_units","loadUnits",
    ]),
    onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        
        this.createImage(files[0]);
    },
    allUnits() {
      this.isBusy = true;
      this.loadUnits()
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listUnits = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    consult() {
      let form = {
        id: this.$route.params.id,
      };
      this.consult_units(form)
        .then((response) => {
          if (response.code == 200) {
            this.name = response.data.name;
            
          }
        })
        .catch((error) => {
          // Swal.fire({
          //   title: this.$i18n.t("error_catch"),
          //   icon: "warning",
          //   reverseButtons: true,
          //   confirmButtonColor: "#AE0B25",
          //   confirmButtonText: this.$i18n.t("btnok"),
          // });
          console.log(error);
        });
    },
    createImage(file) {
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
            vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.image = reader;
        document.getElementById("file").value = ""
    },
    resetImg(){
        this.image = null;
    },
    validateAllForm(){
      if(this.name == null){
        this.validateForm =true;
        return true
      }else{
        if(this.name==''||this.name==' '||this.name=='  '){
          this.validateForm =false;
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
    onSubmit() {
      if(!this.validateAllForm()){
          this.add_element();
      }
    },
    add_element() {
      this.progress = true;
      if (
        this.name == "" 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }

      let data = {
        id:this.$route.params.id,
        name: this.name
      }

      this.modify_units(data)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Modificación exitosa",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/unidades/listado");
              }
            });
          }
          

         
        })
        .catch((error) => {
          console.log(error);
           if (error.code == 409) {
            Swal.fire({
              title: "Nombre duplicado",
              text: "el nombre indicado, ya se encuentra en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.name="";
              }
            });
          }
        });
    },
  },
};
</script>

<style>


/*input*/
.fileUpload {
    position: relative!important;
    overflow: hidden!important;
    padding: 0.25rem 0.5rem!important;
    font-size: 0.875rem!important;
    line-height: 1.5!important;
    border-radius: 0.2rem!important;
}

.fileUpload input.upload {
    position: absolute!important;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.imgStyles{
  width: 110px!important;
  height: 110px!important;
  object-fit: scale-down!important;
  filter: drop-shadow(rgba(221, 19, 123, 0.5) 2px 2px 3px)!important;
  border-radius: 50%!important;
  text-align: center!important; 
}
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>
