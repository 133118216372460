var url_domain = window.location.host;
var config_url = "";
if (url_domain == "localhost:8080") {
//   config_url = "https://arbapi.myticktap.com/api/1.0/";
  config_url = "https://api.arbibestock.com/api/1.0/";
 
  //config_url = 'https://apiultratest.myticktap.com/api/1.0/';
 //config_url = 'http://127.0.0.1:3333/api/1.0/';
}
if (url_domain == "arbstock.myticktap.com") {
  config_url = "https://arbapi.myticktap.com/api/1.0/";
  //config_url = 'https://apiultratest.myticktap.com/api/1.0/';
}
if (url_domain == "arbibestock.com") {
  config_url = "https://api.arbibestock.com/api/1.0/";
}
// if (url_domain == "appecyd.ticktaps.com") {
//   //config_url = 'http://127.0.0.1:3333/api/1.0/';
//   config_url = "https://apiecyd.ticktaps.com/api/1.0/";
// }
//config_url = 'https://apischool.myticktap.com/api/1.0/';
// console.log("Backend Conected: " + config_url);
export const apiPath = config_url;

//TODO name: ['admin','commercial','teacher','personal','student','father','school'][i],
