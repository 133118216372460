<template>
  <div id="AllStock">
    <br />
    <div class="content">
       <b-modal v-model="detailModal" title="Detalle de Stock">

         <b-row>
           <b-col md="12">
             <span v-if="detail_stock.notes">Notas agregadas: {{detail_stock.notes}}</span>
             <span v-else>No hay notas agregadas</span>
           </b-col>
         </b-row>
           <template #modal-footer>
                <div class="row">
                  <div class="col-sm-12 text-center">
                   
                    <b-button class="primaryBlack btn-md" @click="detailModal = false">
                      Cancelar
                    </b-button>
                  </div>
                </div>

                <!-- Emulate built in modal footer ok and cancel button actions -->
              </template>


       </b-modal>
      <b-row>
        <b-col md="7" style="text-align: -webkit-left">
          <h5>Papelera de Stock eliminado</h5>
          <br />
          <p>
            En este listado podrá visualizar stock que ha sido eliminado desde Stock Base y Stock General
          </p>
          <p style="font-weight:600">
            Cantidad de materiales: {{ element_count }}
          </p>
        </b-col>
        <b-col md="2" style="text-align: -webkit-right"> 
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
         
        </b-col>
        <!-- <br  v-if="mobile == true"/>
         <br  v-if="mobile == true"/>
          <br  v-if="mobile == true"/>
         <b-col md="2" style="text-align: -webkit-right">
          <b-button v-if="id_rol ==1"  block class="primary" :to="{ name: 'admin-stocks-transfer' }">Transferir stock</b-button
          >
         
        </b-col> -->
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <b-row v-if="!tablet">
        <b-col lg="4" class="my-1">
          <br />
          <b-form-group
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-input-group-append>
                <button class="primary2">
                  <font-awesome-icon
                    class="iconTable"
                    :icon="['fas', 'search']"
                    title="Busqueda"
                  />
                </button>
              </b-input-group-append>

              <b-form-input
                id="filterInput"
                v-model="filter"
                v-on:keyup="allElements(1)"
                type="text"
                placeholder="Buscar material"
              />

              <b-input-group-append>
                <button
                  class="primary2"
                  :disabled="!filter"
                  @click="clearFilter"
                >
                  <font-awesome-icon
                    class="iconTable"
                    :icon="['fas', 'times']"
                    title="Limpiar busqueda"
                  />
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="2" class="my-1">
          <br />
          <b-form-group>
            <span
              v-if="mobile == false && tablet == false"
              class="mt-3"
              style="margin-left:-20px"
              >Pág. actual: {{ currentPage }}</span
            >
            <span
              v-if="mobile == false && tablet == true"
              class="mt-3"
              style="margin-left:300px"
              >Pág. actual: {{ currentPage }}</span
            >
            <p
              v-if="mobile == true && tablet == false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <div class="d-flex justify-content-center mb-5">
            <ul class="pagination mb-0">
              <template v-if="currentPage != 1">
                <a
                  class="page-link"
                  @click="allElements(currentPage - 1)"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <li class="page-item active">
                  <a class="page-link" @click="allElements(1)">1</a>
                </li>
                <li v-if="lastPage > 1" class="page-item">
                  <a class="page-link" @click="allElements(2)">2</a>
                </li>
              </template>

              <template v-if="currentPage > 1 && currentPage !== lastPage">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
                <li
                  v-if="lastPage > 2 && currentPage !== lastPage"
                  class="page-item"
                >
                  <a class="page-link" @click="allElements(currentPage + 1)">{{
                    currentPage + 1
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage == lastPage && lastPage > 2">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage !== lastPage">
                <a
                  class="page-link"
                  @click="allElements(currentPage + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>

              <template v-if="currentPage == lastPage">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row v-show="tablet">
        <b-col md="6" class="my-1">
          <b-form-group>
            <b-input-group size="sm">
              <b-input-group-append>
                <button class="primary2">
                  <font-awesome-icon
                    class="iconTable"
                    :icon="['fas', 'search']"
                    title="Buscar"
                  />
                </button>
              </b-input-group-append>

              <b-form-input
                id="filterInput"
                v-model="filter"
                v-on:keyup="searchInput"
                type="text"
                placeholder="Buscar material"
              />

              <b-input-group-append>
                <button
                  class="primary2"
                  :disabled="!filter"
                  @click="clearFilter"
                >
                  <font-awesome-icon
                    class="iconTable"
                    :icon="['fas', 'times']"
                    title="Limpiar busqueda"
                  />
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1"> </b-col>
        <b-col md="2">
          <b-form-group style="margin-top:10px">
            <span v-if="tablet == true">Pág. actual: {{ currentPage }}</span>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <div class="d-flex justify-content-center mb-5">
            <ul class="pagination mb-0">
              <template v-if="currentPage != 1">
                <a
                  class="page-link"
                  @click="allElements(currentPage - 1)"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <li class="page-item active">
                  <a class="page-link" @click="allElements(1)">1</a>
                </li>
                <li v-if="lastPage > 1" class="page-item">
                  <a class="page-link" @click="allElements(2)">2</a>
                </li>
              </template>

              <template v-if="currentPage > 1 && currentPage !== lastPage">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
                <li
                  v-if="lastPage > 2 && currentPage !== lastPage"
                  class="page-item"
                >
                  <a class="page-link" @click="allElements(currentPage + 1)">{{
                    currentPage + 1
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage == lastPage && lastPage > 2">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage !== lastPage">
                <a
                  class="page-link"
                  @click="allElements(currentPage + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>

              <template v-if="currentPage == lastPage">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3 px-3">
        <b-col sm="12">
          <div>
            <b-table
              responsive
              id="my-table"
              show-empty
              small
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="elementsTMP"
              :fields="field"
              :per-page="perPage"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              empty-text="No hay materiales disponibles"
              :busy="isBusy"
            >
              <!--                :filter="filter"-->
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>
              <template v-slot:cell(date_start)="row">
                <span>{{ row.item.date_start }}</span>
              </template>

              <template v-slot:cell(manager)="row">
                <span v-if="row.item.id_manager != null"
                  >{{ row.item.manager.first_name }}
                  {{ row.item.manager.last_name }}</span
                >
              </template>
              <template v-slot:cell(quantityUnit)="row">
                <span
                  >{{ row.item.quantity }}
                  {{ row.item.material.unity.name }}</span
                >
              </template>
              <template v-slot:cell(quantityUnitProcess)="row">
                <span v-if="row.item.location.type != 1"
                  >{{ row.item.transfer_counts }}
                  {{ row.item.material.unity.name }}</span
                >
                <p v-if="row.item.location.type == 1"><strong>-</strong></p>
              </template>
              <template v-slot:cell(quantityUnitNext)="row">
                <span v-if="row.item.location.type != 1"
                  >{{ row.item.next_quantity }}
                  {{ row.item.material.unity.name }}</span
                >
                <p v-if="row.item.location.type == 1"><strong>-</strong></p>
              </template>
              <template v-slot:cell(date)="row">
                <p>{{ dateFormat(row.item.updated_at) }}</p>
              </template>

              <template v-slot:cell(actions)="row">
               
                <button
                  v-if="id_rol == 1"
                  size="sm"
                  class="primary2"
                  @click="restore_element(row)"
                >
                  <font-awesome-icon
                    class="iconTable"
                    icon="check"
                    style="color: green!important;"
                    title="Restaurar"
                  />
                </button>

               
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group>
            <span
              v-if="mobile == false && tablet == false"
              class="mt-3"
              style="margin-left:-20px"
              >Pág. actual: {{ currentPage }}</span
            >
            <span
              v-if="mobile == false && tablet == true"
              class="mt-3"
              style="margin-left:300px"
              >Pág. actual: {{ currentPage }}</span
            >
            <p
              v-if="mobile == true && tablet == false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <div class="d-flex justify-content-center mb-5">
            <ul class="pagination mb-0">
              <template v-if="currentPage != 1">
                <a
                  class="page-link"
                  @click="allElements(currentPage - 1)"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Atras"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Atras</span>
                </a>
              </template>

              <template v-if="currentPage == 1">
                <li class="page-item active">
                  <a class="page-link" @click="allElements(1)">1</a>
                </li>
                <li v-if="lastPage > 1" class="page-item">
                  <a class="page-link" @click="allElements(2)">2</a>
                </li>
              </template>

              <template v-if="currentPage > 1 && currentPage !== lastPage">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
                <li
                  v-if="lastPage > 2 && currentPage !== lastPage"
                  class="page-item"
                >
                  <a class="page-link" @click="allElements(currentPage + 1)">{{
                    currentPage + 1
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage == lastPage && lastPage > 2">
                <li class="page-item">
                  <a class="page-link" @click="allElements(currentPage - 1)">{{
                    currentPage - 1
                  }}</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" @click="allElements(currentPage)">{{
                    currentPage
                  }}</a>
                </li>
              </template>

              <template v-if="currentPage !== lastPage">
                <a
                  class="page-link"
                  @click="allElements(currentPage + 1)"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>

              <template v-if="currentPage == lastPage">
                <a
                  disabled
                  class="page-link"
                  style="pointer-events: none"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Siguiente</span>
                </a>
              </template>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  name: "AllStock",
  data() {
    return {
      element_count: 0,
      isBusy: false,
      id: "",
      detailModal:false,
      detail_stock:{},
      id_rol: Cookies.get("id_rol"),
      items: [
        {
          text: "Stock",
        },
      ],
      listLocation: [],
      listCities: [],
      location: null,
      item_table: null,
      ttap_selected: null,
      field: [
        {
          key: "material.name",
          label: "Material",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén base",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cant. actual",
          sortable: true,
        },

        {
          key: "date",
          label: "Actualizado",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      lastPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      elements: [],
      elementsTMP: [],
      loading: false,
      error: "",
      tablet: false,
      mobile: false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.elementsTMP.length;
    },
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.mobile = false;
      this.tablet = false;
    } else if (window.innerWidth >= 500 && window.innerWidth < 1024) {
      this.mobile = false;
      this.tablet = true;
    } else {
      this.mobile = true;
      this.tablet = false;
    }
    this.allLocation();
    this.allElements(1);
  },
  methods: {
    ...mapActions(["loadStockPaginate", "loadLocation", "changeStatus_stock"]),
    viewDetails(row) {
      console.log(row)
      
      this.detail_stock = row;
      this.detailModal = true;
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    clearFilter() {
      this.elementsTMP = this.elements;
      this.filter = "";
      this.allElements(1);
    },
    searchInput() {
      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.elements.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.elementsTMP = elementsTMP;
      } else {
        this.elementsTMP = this.elements;
      }
      return true;
    },
    allLocation() {
      let query = "?active=1";
      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.listLocation = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterLocation() {
      this.isBusy = true;
      let form = {
        order_key: "id",
        order_value: "desc",
        active: "all",
      };
      this.loadStock(form)
        .then((response) => {
          if (response.code === 200) {
            this.elements = response.data.filter(
              (x) => x.id_location === this.location
            );
            this.elementsTMP = this.elements;
            this.element_count = this.elements.length;
            this.isBusy = false;
            //location.type
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    allElements(page) {
      this.isBusy = true;

      if (!page) {
        page = this.page;
      }
      let form = {
        page: page,
        active: "2",
      };
      if (this.filter) {
        form.query = this.filter;
      }
      this.loadStockPaginate(form)
        .then((response) => {
          if (response.code === 200) {
            this.elements = response.data.data;

            this.isBusy = false;

            this.elements = response.data.data;
            this.elementsTMP = response.data.data;
            this.element_count = this.elements.length;

            this.element_count = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.currentPage = page;
            this.lastPage = response.data.meta.last_page;

            //location.type
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_element(data) {
      this.$router.push("/stocks/modificar/" + data.id);
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title =
        data.active == 1
          ? "¿Desea desactivar este elemento?"
          : "¿Desea activar este elemento?";
      // let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
        data.active === 1 ? "Elemento inactivado" : "Elemento activado";
      Swal.fire({
        title: title,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Cambiar estatus",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.changeStatus_stock(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  text: text,
                  icon: "success",
                  confirmButtonColor: "#00373B",
                });
                this.allElements(1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    restore_element(row) {
      Swal.fire({
        title: "¿Desea restaurar este elemento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Restaurar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 1,
          };
          this.changeStatus_stock(form)
            .then((response) => {
              if (response.code === 200) {
                this.elements.splice(row.index, 1);
                Swal.fire({
                  title: "Elemento restaurado",
                  confirmButtonColor: "#00373B",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.primary {
  background: linear-gradient(180deg, #00373b 0%, #00373b 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
  color: white !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373b !important;
  border-color: #00373b !important;
}
</style>
