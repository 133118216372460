<template>
  <div id="UpdateStock">
    <br />
     <br v-show="loading"/>
         <br v-show="loading"/>
          <br v-show="loading"/>
     <div v-show="loading" v-if="!tablet" class="d-flex justify-content-center mb-3">
       
    <b-spinner style="width: 3rem; height: 3rem;"  v-show="loading" label="Cargando"></b-spinner>
  </div>
   <br v-show="loading"/>
   <div v-show="loading" v-if="!tablet" class="d-flex justify-content-center mb-3">
    <p v-show="loading">Por favor espere un momento, mientras cargan los datos correspondientes</p>
  </div>
    <div v-show="!loading" class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Stock</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary3" :to="{ name: 'admin-stocks-all' }">
            Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left">Modificar stock</p>
        </b-col>
      </b-row>
      <b-row>
         <b-col md="6">
          <b-form-group label="Almacén destino:">
            <b-select id="perPageSelectStocks" disabled  v-model="id_stock"  :options="listStocks" value-field="id" text-field="name" > 
              <template #first>
                <b-form-select-option :value="null" >Seleccione un almacén</b-form-select-option>
              </template>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Material:">
           <v-select style="height:30px" placeholder="Seleccione un material" id="perPageSelectStocks" label="name" value="id"  v-model="id_material"  :options="listMaterials"> 
             
            </v-select>
          </b-form-group>
          <br v-if="tablet">
          <br v-if="tablet">
        </b-col>
        <b-col md="6">
          <b-form-group label="Cantidad:">
            <b-input-group size="md">
              <b-form-input v-model="capacity" type="number" v-bind:class="{ 'validateInput':validateInput(this.capacity,'text') }" placeholder="Cantidades"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.capacity,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.capacity,'text') }">La cantidad es requerida</span>
          </b-form-group>
        </b-col> 
        <b-col md="6">
          <b-form-group label="Nota:">
            <b-input-group size="md">
              <b-form-input v-model="note"  v-bind:class="{ 'validateInput':validateInput(this.note,'text') }" placeholder="Nota del material"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col> 
      </b-row>
      <b-row>
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
             <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Modificar</b-button>
            
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);


export default {
  name: "UpdateStock",
  data() {
    return {
      loading:true,
      validateForm:true,
      listStocks:[],
      id_stock:null,
      id_stock_base:null,
      id_material:null,
      listMaterials:[],
      capacity: null,
      note: null,
      progress: false,
      tablet:false
      
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
     if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    this.allLocations();
    this.allMaterials();
    this.consult();
    // setTimeout(() => {
    //   this.id_stock = this.listStocks[0].id
    //   this.id_material = this.listMaterials[0].id
    // }, 500);
  },
  methods: {
    ...mapActions([
      "modify_stock",
      "consult_stock",
      "loadLocation",
      "loadMaterial",
    ]),
    validateAllForm(){
      if(this.capacity == null||this.note == null){
        this.validateForm =true;
        return true
      }else{
        if(this.note==''||this.note==' '||this.note=='  ' || this.capacity==''||this.capacity==' '||this.capacity=='  '){
          this.validateForm =false;
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
     consult() {
      let form = {
        id: this.$route.params.id,
      };
      this.consult_stock(form)
        .then((response) => {
          if (response.code == 200) {
            this.note = response.data.notes;
            this.id_stock_base = response.data.id_location_base;
            this.id_stock = response.data.id_location;
            
            this.capacity = response.data.quantity;

            this.id_material = {name:response.data.material.name.substring(0,40)+"...",id:response.data.material.id}
            
          }
        })
        .catch((error) => {
          // Swal.fire({
          //   title: this.$i18n.t("error_catch"),
          //   icon: "warning",
          //   reverseButtons: true,
          //   confirmButtonColor: "#AE0B25",
          //   confirmButtonText: this.$i18n.t("btnok"),
          // });
          console.log(error);
        });
    },
    allLocations() {
      this.isBusy = true;
      let query ="?active=1"
      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listStocks = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allMaterials() {
      this.isBusy = true;
      let query ="?active=1"
      this.loadMaterial(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
         //   this.listMaterials = response.data;
             this.listMaterials = response.data.map(f => {
            return {name:f.name.substring(0,40)+"...",id:f.id};
          });
          this.listMaterials=this.listMaterials.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
           this.loading=false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if(!this.validateAllForm()){
          this.add_element();
      }
    },
    add_element() {
      this.progress = true;
      if (
        this.note == "" ||
        this.id_stock == null ||
        this.id_material == null ||
        this.capacity == null 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
  
      let form = {
        id: this.$route.params.id,
        id_material: this.material,
        id_location_base: this.id_stock_base,
        id_location_destiny: this.id_stock,
        quantity: this.capacity,
        notes: this.note,
      };
   
      this.modify_stock(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Modificación exitosa",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/stocks/listado");
              }
            });
          }

          
        })
        .catch((error) => {
          console.log(error);
          if (error.code == 409) {
            Swal.fire({
              title: "No existe material en el almacén base",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>
