<template>
  <div id="AddMaterial">
    <br/>
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Materiales</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary3" :to="{ name: 'admin-materials-all' }">
            Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left">Crear material</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group label="Descripción:">
            <b-input-group size="md">
              <b-form-textarea v-model="name" max-rows="6"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Descripción del material"></b-form-textarea>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">La descripción es requerido</span>
          </b-form-group>
          <br/>
        </b-col> 
        <b-col md="6">
         
           <br/>
        </b-col> 
        <b-col md="6">
          <b-form-group label="Unidades:">
           <b-select id="perPageSelectStocks"   v-model="units"  :options="listUnits" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option :value="null" >Seleccione una unidad</b-form-select-option>
                </template>
              </b-select>
          </b-form-group>
        </b-col> 
        <b-col md="6">
          <b-form-group label="Código:">
            <b-input-group size="md">
              <b-form-input v-model="code"  v-bind:class="{ 'validateInput':validateInput(this.code,'text') }" placeholder="Código del material"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.code,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.code,'text') }">El código es requerido</span>
          </b-form-group>
        </b-col> 
        <b-col md="6">
           <b-form-group label="Categorias:">
           <b-select id="perPageSelectStocks"   v-model="category"  :options="listCategories" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option :value="null" >Seleccione una categoría</b-form-select-option>
                </template>
              </b-select>
          </b-form-group>
        </b-col> 
        <b-col md="6" style="text-align:center">
           <h6>Agregar imagen del material</h6>
          <div style="text-align:center;margin-top:20px">
            <img v-if="image_read==null" class="img-responsive center-block d-block mx-auto imgStyles_"
              src="../../../../assets/img/image.png"/>
            <img v-if="image_read!=null" class="img-responsive center-block d-block mx-auto imgStyles_" style="width:250px"
              :src="image_read"/>
               <div style="text-align: center">
            <b-modal id="modal2" ref="modal2" title="Agregar imagen">
              <template>
                <form ref="form"></form>
              </template>
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="image"
                :state="Boolean(image)"
                placeholder="Imagen de material"
                drop-placeholder="Drop file here..."
                @input="handleFileChange"
              ></b-form-file>
              <template #modal-footer>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <b-button
                      class="primary btn-md"
                      style="margin-right: 5px"
                      @click="cancelP2"
                    >
                      Aceptar </b-button
                    ><span></span>
                    <b-button class="primaryBlack btn-md" @click="cancelP2">
                      Cancelar
                    </b-button>
                  </div>
                </div>

                <!-- Emulate built in modal footer ok and cancel button actions -->
              </template>
            </b-modal>
          </div>
          </div>
          <b-row v-show="!tablet">
              <b-col md="2"></b-col> 
              <b-col md="4">
              <br>
                  <b-button class="" size="sm" variant="primary" style="margin-right:5px" v-b-modal.modal2 >Subir imagen</b-button>
              
              </b-col> 
              <b-col md="4">
              <br>
                <b-button class="" size="sm" variant="danger" style="margin-right:5px" v-on:click="resetImg()">Eliminar</b-button>
              </b-col> 
           </b-row>
            <b-row v-show="tablet">
               <b-col md="1"></b-col> 
              <b-col md="5">
              <br>
                  <b-button class="" size="sm" variant="primary" style="margin-right:5px" v-b-modal.modal2 >Subir imagen</b-button>
              
              </b-col> 
              <b-col md="6">
              <br>
                <b-button class="" size="sm" variant="danger" style="margin-right:5px" v-on:click="resetImg()">Eliminar</b-button>
              </b-col> 
           </b-row>
        </b-col>
      </b-row>
   
      <b-row>
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
      </b-row>
          
          <b-row>
        <b-col md="4"></b-col>
        <b-col md="4">
              <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
            <b-col md="5"></b-col>
          </b-row>
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
        
        </b-col><!--v-on:click="add_element()"-->
         <b-col md="4">
        </b-col><!--v-on:click="add_element()"-->
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AddMaterial",
  data() {
    return {
      validateForm:true,
      name: null,
      units: null,
      category: null,
      description: null,
      code: null,
      listUnits:[],
      listCategories:[],
      image:null,
      image_read:null,
      progress:false,
      tablet:false
      
    };
  },
  computed: {

    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
        if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    this.allUnits();
    this.allCategories();
  },
  methods: {
    ...mapActions([
      "register_material","loadUnits","loadCategories",
    ]),
     handleFileChange(file) {
      const reader = new FileReader();
      let imageFile = file;
      reader.readAsDataURL(imageFile);
      reader.onload = (e) => {
        this.image_read = e.target.result;
      };
    },
    allUnits() {
      this.isBusy = true;
      let query ="?active=1"
      this.loadUnits(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listUnits = response.data;
              this.listUnits=this.listUnits.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
     allCategories() {
      this.isBusy = true;
      let query ="?active=1"
      this.loadCategories(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listCategories = response.data;
            this.listCategories=this.listCategories.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetImg(){
        this.image = null;
        this.image_read = null;
    },
    validateAllForm(){
      if(this.name == null||this.units == null||this.code == null){
        this.validateForm =true;
        return true
      }else{
        if(this.name==''||this.name==' '||this.name=='  ' || this.units==''||this.units==' '||this.units=='  '|| this.description==''||this.description==' '||this.description=='  '||this.code==''||this.code==' '||this.code=='  ' ){
          this.validateForm =false;
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
    onSubmit() {
      if(!this.validateAllForm()){
        // this.progress = true;
     this.add_element();
      }
    },
     cancelP2() {
      this.$refs["modal2"].hide();
    },
    add_element() {
      this.progress = true;
      if (
        this.name == "" ||
        this.units == null ||
        this.code == null ||
        this.category == null 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
      //  if (
      //   this.image_headquarters == null
      // ) {
      //   Swal.fire({
      //     title:"Debes seleccionar una imagen",
      //     icon: "warning",
      //     reverseButtons: true,
      //     confirmButtonColor: "#00373B",
      //     confirmButtonText: "Ok",
      //   });
      //   this.progress = false;
      //   return;
      // }

   
      let data = new FormData();
      data.append("name", this.name);
      data.append("description", this.description);
      data.append("id_unity", this.units);
      data.append("code", this.code);
      data.append("id_category", this.category);
      data.append("image", this.image);

      this.register_material(data)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/materiales/listado");
              }
            });
          }
            if (response.code == 400) {
            Swal.fire({
              title: "Código duplicado",
              text: "el código indicado, ya se encuentra en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.code="";
              }
            });
        }

          if (response.code == 409) {
            this.progress = false;
            Swal.fire({
              title: "Registro duplicado",
              text:"Un material con este código o nombre, ya se encuentra registrado",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>


/*input*/
.fileUpload {
    position: relative!important;
    overflow: hidden!important;
    padding: 0.25rem 0.5rem!important;
    font-size: 0.875rem!important;
    line-height: 1.5!important;
    border-radius: 0.2rem!important;
}

.fileUpload input.upload {
    position: absolute!important;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.imgStyles{
  width: 110px!important;
  height: 110px!important;
  object-fit: scale-down!important;
  filter: drop-shadow(rgba(221, 19, 123, 0.5) 2px 2px 3px)!important;
  border-radius: 50%!important;
  text-align: center!important; 
}
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>
