import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {
    
    import_stock({ commit, dispatch }, data) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios(
          {
            headers: {'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: Config.apiPath + "documents/read_file/"+data.get("id"),
            data: data,
            
            })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      import_materials({ commit, dispatch }, data) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios(
          {
            headers: {'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: Config.apiPath + "documents/read_materials",
            data: data,
            
            })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },

      export_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "documents/stock_report/"+form.id_location)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    export_materials({ commit, dispatch }) {
      console.log(dispatch);
      console.log(commit);
      return new Promise((resolve, reject) => {
          axios.get(Config.apiPath + "documents/material_report")
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  reject(error.response.data);
              });
      });
  },
      



};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};