<template class="main">
  <div class="heigthNew" id="div2">
    <b-row>
      <b-col md="6" class="backgroundimg1">
        <br />
        <div class="row">
          <!-- <img
            class="img-responsive center-block d-block mx-auto"
            style="margin-left: auto; max-width: 250px; text-align: center"
            src=""
          /> -->
        </div>
        <br />
        <h1 class="textimg">Iniciar sesión</h1>
      </b-col>
     
      <b-col md="6">
         <br/>
        <div class="row">
          <img
            v-if="$i18n.locale == 'es'"
            class="img-responsive center-block d-block mx-auto"
            style="max-width: 200px; text-align: center"
            src="../../assets/img/logo_colab_es.png"
          />
          <img
            v-if="$i18n.locale == 'en'"
            class="img-responsive center-block d-block mx-auto"
            style="max-width: 100px; text-align: center"
            src=""
          />
        </div>
          <b-row v-if="tablet">
          <b-col md="12">
            <div center style="background: transparent; padding: 20px">
              <br />
              <div style="text-align: center">
                <h6 class="text2">¡Bienvenido(a)! Ingresa tus datos para iniciar sesión</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-row >
                 <b-col md="10">
                   <b-row>
                       <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>

                   </b-row>

          
                  
                </b-col>
                 <b-col md="1">
                    <b-row>
                   </b-row>
                   <br/>
                   <b-row>
                   </b-row>
                     
                  
                </b-col>
              </b-row>
              <br>
                <b-row >

                 <b-col md="10">
                   <b-row>
                         <b-input-group size="lg" prepend="Contraseña">
                          <b-form-input
                            v-bind:type="variable"
                            v-model="password"
                          >
                          </b-form-input>
                        </b-input-group>
                   </b-row>
                </b-col>

                 <b-col md="1" >
                        <button size="sm"  class="primary2" style="margin-top:12px" @click="showPassword()">
                  <font-awesome-icon 
                    v-if="variable == 'password'"
                    class="iconTable"
                    icon="eye"
                  />
                   <font-awesome-icon 
                    v-if="variable == 'text'"
                    class="iconTable"
                    icon="eye-slash"
                  />
                 
                </button>   
                </b-col>

              </b-row>
               <div v-if="mobile && !tablet">
                 <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              <br />

              <b-input-group size="lg" prepend="Contraseña">
                <b-form-input
                  v-bind:type="variable"
                  v-model="password"
                ></b-form-input>
                 <button size="sm"  class="primary2" @click="showPassword()">
                  <font-awesome-icon
                    v-if="variable == 'password'"
                    class="iconTable"
                    icon="eye"
                  />
                  <font-awesome-icon
                    v-if="variable == 'text'"
                    class="iconTable"
                    icon="eye-slash"
                  />
                </button>
               
              </b-input-group>
              </div>
           
              <br />
              <div style="text-align: right">
              
              </div>
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary"  v-on:click="onSubmit">Iniciar sesión</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align: center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
              <div style="text-align: center">
                
              </div>
            </div>
          </b-col>

        </b-row>
        <b-row v-if="!tablet">
          <b-col md="2"> </b-col>
          <b-col md="8">
            <div center style="background: transparent; padding: 20px">
              <br />
              <div style="text-align: center" v-if="!tablet">
                <h6 class="text2">¡Bienvenido(a)! Ingresa tus datos para iniciar sesión</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-row  v-if="!mobile & !tablet">
                <b-col md="1">

                </b-col>
                 <b-col md="10">
                   <b-row>
                       <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>

                   </b-row>

          
                  
                </b-col>
                 <b-col md="1">
                    <b-row>
                   </b-row>
                   <br/>
                   <b-row>
                   </b-row>
                     
                  
                </b-col>
              </b-row>
              <br>
                <b-row  v-if="!mobile && !tablet">
                <b-col md="1">

                </b-col>

                 <b-col md="10">
                   <b-row>
                         <b-input-group size="lg" prepend="Contraseña">
                          <b-form-input
                            v-bind:type="variable"
                            v-model="password"
                          >
                          </b-form-input>
                        </b-input-group>
                   </b-row>
                </b-col>

                 <b-col md="1" >
                        <button size="sm"  class="primary2" style="margin-top:12px" @click="showPassword()">
                  <font-awesome-icon 
                    v-if="variable == 'password'"
                    class="iconTable"
                    icon="eye"
                  />
                   <font-awesome-icon 
                    v-if="variable == 'text'"
                    class="iconTable"
                    icon="eye-slash"
                  />
                 
                </button>   
                </b-col>

              </b-row>
               <div v-if="mobile && !tablet">
                 <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              <br />

              <b-input-group size="lg" prepend="Contraseña">
                <b-form-input
                  v-bind:type="variable"
                  v-model="password"
                ></b-form-input>
                 <button size="sm"  class="primary2" @click="showPassword()">
                  <font-awesome-icon
                    v-if="variable == 'password'"
                    class="iconTable"
                    icon="eye"
                  />
                  <font-awesome-icon
                    v-if="variable == 'text'"
                    class="iconTable"
                    icon="eye-slash"
                  />
                </button>
               
              </b-input-group>
              </div>
           
              <br />
              <div style="text-align: right">
              
              </div>
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary"  v-if="!tablet" v-on:click="onSubmit">Iniciar sesión</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align: center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
              <div style="text-align: center">
                
              </div>
            </div>
          </b-col>

          <b-col md="2"> </b-col>
        </b-row>
        <br />
        <br />
        <b-row>
          <b-col md="1"> </b-col>
          <b-col md="10">
          </b-col>

          <b-col md="1"> </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import "../../assets/custom-vars.scss";
//import Permission from "../../permission";
import { validate } from "vee-validate";
import Cookies from "js-cookie";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      email: "",
      password: "",
      listRoles: [],
      auth: {},
      lang: "es",
      langs: ["es", "en"],
      variable: "password",
      mobile:false,
      tablet:false
    };
  },

  computed: {
    ...mapState({}),
    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted() {
     if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
  },
  methods: {
    ...mapActions(["login", "addToastMessage"]),
    showPassword() {
      if (this.variable == "password") {
        this.variable = "text";
      } else {
        this.variable = "password";
      }
    },
    onSubmit() {
      if (this.email == "" || this.password == "") {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor . verifica que los datos no esten vacios",
          icon: "warning",
          confirmButtonColor: "#00373B",
        });
      } else {
        validate().then((result) => {
          if (result) {
            this.progress = true;
            this.btnLogin = true;
              // this.$router.push("/admin");

            this.login({
              email: this.email,
              password: this.password,
            })
              .then((data) => {
                if (data.code == 200) {

                  this.auth = data;
                  this.btnLogin = false;
                  Cookies.set("id_user",data.data.id);
                   Cookies.set("email",data.data.email);
                   Cookies.set("id_rol",data.data.id_rol);
                this.$router.push("/admin/dashboard");
                  this.progress = false;
          
                }
                // if (data.code == 402) {
                //   Swal.fire({
                //     title: this.$i18n.t("error_problem_active"),
                //     icon: "warning",
                //     confirmButtonColor: "#00373B",
                //   }).then((result2) => {
                //     console.log(result2);
                //   });
                // }
                if (data.code == 403) {
                   this.progress = false;
                  Swal.fire({
                    title: 'Atención',
                    text: "Clave incorrecta",
                    icon: "error",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                 if (data.code == 404) {
                   this.progress = false;
                   Swal.fire({
                     title: 'Atención',
                     text: "Este usuario no existe",
                     icon: "warning",
                     confirmButtonColor: "#00373B",
                   }).then((result2) => {
                     console.log(result2);
                   });
                 }
                //  if (data.code == 405) {
                //   Swal.fire({
                //     title: this.$i18n.t("error_login_user"),
                //     icon: "warning",
                //     confirmButtonColor: "#00373B",
                //   }).then((result2) => {
                //     console.log(result2);
                //     this.progress = false;
                //   });
                // }
              })

              .catch((data) => {
                this.progress = false;
                this.btnLogin = false;
                if (data.code == 404) {
                  this.addToastMessage({
                    text: this.$i18n.t("wrong_credentials"),
                    icon: "warning",
                  });
                  this.progress = false;
                }
                if (data.code == 401) {
                  this.addToastMessage({
                    text: this.$i18n.t("incorrect_password"),
                    icon: "warning",
                  });
                  this.progress = false;
                }
                if (data.code == 402) {
                  Swal.fire({
                    title: this.$i18n.t("error_problem_active"),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                if (data.code == 403) {
                  Swal.fire({
                    title: this.$i18n.t("error_bloq"),
                    text: this.$i18n.t("text_error_bloq"),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }
                 if (data.code == 405) {
                  Swal.fire({
                    title: this.$i18n.t("error_login_user"),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                    this.progress = false;
                  });
                }
              });
          }
        });
      }
    },
  },
};
</script>


<style lang="scss">
.heigthNew {
  min-height: 100%;
}
#div2{
  flex: 1;
}
body {
   height:100%;
  margin:0;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
.backgroundimg1 {
  display: flex;
  flex-direction: column;
  height:100vh;
  min-height: 100%;
  background-image: url("../../assets/img/KAANA-min.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.backgroundimg::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}
.textimg {
  font-family: "Syncopate", sans-serif;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
}
.text {
  text-align: right;
  color: #00373B;
  font-weight: 600;
}
.text2 {
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 8;
}

.textNew {
  text-align: right;
   color: #DD137B;
  font-weight: 600;
}
.text3 {
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 0.8rem;
}

.primary {
  background-color: #00373B;
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
}
.form-control2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  background-color: #fff;
  border: 0px solid #ffffff;
}
.langMobile {
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 8;
  margin-left: 10px;
}
.langNotMobile {
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 8;
}
</style>