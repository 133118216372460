import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {

    register_material({ commit, dispatch }, data) {
        console.log(dispatch)
        console.log(commit)
        return new Promise((resolve, reject) => {
          axios(
          {
            headers: {'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: Config.apiPath + "material/save",
            data: data,
            
            })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },


    consult_material({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "material/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    modify_material({ commit, dispatch }, data) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "material/update/" + data.get("id"), data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    changeStatus_material({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "material/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


    loadMaterial({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "material/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadMaterialPaginate({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "material/all_paginate";
       

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint,{params:form})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};