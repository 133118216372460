import Vue from "vue";
import Vuex from "vuex";
// import Vuex, {Payload, Store} from 'vuex'
import VuexPersist from "vuex-persist";
import Cookies from "js-cookie";
import createLogger from "vuex/dist/logger";
import toast from "./modules/toast";

//=====MODULES =====

//ASIS
import location from "./modules/location";
import material from "./modules/material";
import projects from "./modules/projects";
import reports from "./modules/reports";
import stocks from "./modules/stocks";
import users from "./modules/users";
import masters from "./modules/masters";
//import messages from "./modules/messages";
import money from "v-money";

Vue.use(money, { precision: 4 });
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
var inHalfADay = 0.5;

const vuexCookie = new VuexPersist({
  key: "store",
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) =>

    Cookies.set(key, state, {
      expires: inHalfADay
    }),
  //modules: ["auth", "route"] //only save user module
  modules: ["auth"]
  // filter: (mutation) => (mutation.type == 'logIn' || mutation.type == 'logOut')
});

export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger(), vuexCookie.plugin] : [vuexCookie.plugin],
  modules: {
   // auth,
    toast,
    location,
    material,
    projects,
    reports,
    stocks,
    users,
    masters,
   // messages
   
  }
});
