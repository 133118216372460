<template>
  <div id="AllUsers">
    <br/>
    <div class="content">
        <b-row>
          <b-col md="9" style="text-align: -webkit-left">
           <h5 >Usuarios
            </h5>
            <br/>
            <p>En esta ventana, usted podrá crear y modificar usuarios que tendrán acceso a la aplicación, entre ellos, administradores y operadores.</p>
            <p style="font-weight:600">
             Cantidad de usuarios: {{ users_count }}
            </p>
          </b-col>
          <b-col md="3" style="text-align: -webkit-right">
            <b-button block class="primary" :to="{ name: 'admin-addUser' }">
            Crear usuario</b-button
          >
          </b-col>
          <b-col md="1" style="text-align: -webkit-right">
          </b-col>
        </b-row>
        <b-row v-show="!tablet">
          <b-col lg="4" class="my-1">
            <b-form-group
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="sm">
              <b-input-group-append>
                  <button class="primary2" > <font-awesome-icon class="iconTable" :icon= "['fas', 'search']"  title="Buscar" /></button>
                </b-input-group-append>
              
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Buscar usuario"
                />
                
                <b-input-group-append>
                  <button class="primary2" :disabled="!filter" @click="clearFilter"><font-awesome-icon class="iconTable" :icon= "['fas', 'times']"  title="Limpiar busqueda"/></button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="my-1">
          </b-col>
        </b-row>
          <b-row v-show="tablet">
             
          <b-col md="6" class="my-1">
             <b-form-group
            >
              <b-input-group size="sm">
              <b-input-group-append>
                  <button class="primary2" > <font-awesome-icon class="iconTable" :icon= "['fas', 'search']"  title="Buscar" /></button>
                </b-input-group-append>
              
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Buscar usuario"
                />
                
                <b-input-group-append>
                  <button class="primary2" :disabled="!filter" @click="clearFilter"><font-awesome-icon class="iconTable" :icon= "['fas', 'times']"  title="Limpiar busqueda"/></button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col sm="12">
            <div>
              <b-table responsive
                  id="my-table"
                  show-empty
                  small
                  stacked="md"
                  sticky-header="true"
                  hover
                  :primary-key="id"
                  :items="usersTMP"
                  :fields="field"
                  :current-page="currentPage"
                  :per-page="perPage"
                  empty-text="No hay usuarios disponibles"
              
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  @filtered="onFiltered"
                  :busy="isBusy"
              >
                <!--                :filter="filter"-->
                <thead >
                <tr>
                  <th></th>
                     <th></th>
               

                </tr>
                </thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                  <template v-slot:cell(city)="row">
                  <p >{{ row.item.city_residence.name }} ({{ row.item.country_residence.name }} ) </p>
                </template>
                <template v-slot:cell(fecha)="row">
                  <p>{{dateFormat(row.item.created_at)}} </p>
                </template>
                
                <template v-slot:cell(actions)="row">

                  <button
                      size="sm"
                      class="primary2"
                      @click="edit_User(row.item)"
                      title="visualizar"
                  >
                    <font-awesome-icon class="iconTable" icon="pencil-alt" style="color: black!important;" title="Editar"/>
                  </button>
                  <button
                    class="primary2"
                    v-if="row.item.active === 0"
                    size="sm"
                    title="Desactivado"
                    @click="changeStatus(row.item)"
                  >
                  <font-awesome-icon class="iconTable" :icon= "['far', 'square']" title="Desactivado"/>
                  </button>
                     <button
                      v-if="row.item.active === 1"
                      size="sm"
                      class="primary2"
                      title="Activado"
                      @click="changeStatus(row.item)"
                  >
                  <font-awesome-icon class="iconTable" :icon= "['far', 'check-square']" title="Activado"/>
                  </button>
                  <!--<button
                      class="primary2"
                      v-if="row.item.active === 2"
                      size="sm"
                  >
                    <font-awesome-icon class="iconTable" :icon="['far', 'check-square']" style="color: #00373b00!important;"/>
                  </button>-->
                  <button
                      size="sm"
                      class="primary2"
                      @click="delete_User(row)"
                      title="Eliminar"
                  >

                    <font-awesome-icon class="iconTable" icon="trash" style="color: red!important;" title="Eliminar"/>
                  </button>
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="my-1">
          </b-col>
          <b-col lg="4" class="my-1">
          </b-col>
           <b-col lg="2" class="my-1">
            <b-form-group
            >
              <span class="mt-3" v-if="mobile == false && tablet==false">Página actual: {{ currentPage }}</span>
              <span class="mt-3" v-if="mobile == false && tablet==true" style="font-size: 15px;margin-left:300px">Página actual: {{ currentPage }}</span>
               <p
              v-if="mobile == true && tablet==false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
            </b-form-group>
          </b-col>
              <b-col lg="2" class="my-1">
            <b-form-group v-if="mobile == false && tablet==false" style="margin-left:-40px"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
              ></b-pagination>
            </b-form-group>
             <b-form-group
            v-if="mobile == true && tablet==false"
            style="margin-left: 130px; margin-top: -25px"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
           <b-form-group
            v-if="mobile == false && tablet==true"
            style="margin-left: 300px;margin-top: -25px"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
          
          </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    "á": "a",
    "é": "e",
    "í": "i",
    "ó": "o",
    "ú": "u",
    "à": "a",
    "è": "e",
    "ì": "i",
    "ò": "o",
    "ù": "u",
    "ñ": "n",
    "Á": "A",
    "É": "E",
    "Í": "I",
    "Ó": "O",
    "Ú": "U",
    "À": "A",
    "È": "E",
    "Ì": "I",
    "Ò": "O",
    "Ù": "U",
    "Ñ": "N"
  }
  let expr = /[áàéèíìóòúùñ]/ig;
  let res = cadena.replace(expr, function (e) {
    return chars[e]
  });
  return res;
}

import {mapState, mapActions} from "vuex";
import Swal from 'sweetalert2';
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  name: "AllUsers",
  data() {
    return {
      tablet:false,
      users_count: 0,
      isBusy: false,
      id: "",
        items: [
          {
            text: "Usuarios",
          },
        ],
      item_table: null,
      ttap_selected: null,
      field: [
     
        {
          key: "full_name",
          label:"Nombres",
          sortable: true,
        },
         {
          key: "rol.name",
          label: "Rol",
          sortable: true,
        },
         {
          key: "email",
          label:"Correo",
          sortable: true,
        },
        {
          key: "fecha",
          label:"Fecha ingreso",
          sortable: true,
        },
         {
          key: "actions",
          label: "Acciones",
          sortable: false
        }

   
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detail_student: {},
      ttap_list: [],
      users: [],
      usersTMP: [],
      loading: false,
      error: "",
      mobile: false
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    }),
    sortOptions() {
      return this.field
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    },
    rows() {
      return this.usersTMP.length;
    }
  },
  mounted() {

  if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }

    this.allUsers();
    
   

  },
  methods: {
    ...mapActions([
      "loadUsers",
      "changeStatus_user"
    ]),
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.usersTMP = this.users;
      this.filter = ''
      return true;
    },
   dateFormat(date, format = 'DD/MM/YYYY') {
            return moment(date).format(format);
        },
    searchInput() {

      let filterTMP = this.filter;
      let usersTmp = [];
      if (filterTMP) {
        this.users.filter(function (el, i) {
          let cadena = JSON.stringify(el);
          if (clearAccents(cadena).toLowerCase().indexOf(filterTMP.toLowerCase(), i) > -1) {
            usersTmp.push(el)
          }
        })
        this.usersTMP = usersTmp;
      } else {
        this.usersTMP = this.users;
      }
      return true;
    },
    allUsers() {
      this.isBusy = true;
      /*let form = {
        active:'all'
      }*/
      this.loadUsers()
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false;
              this.users = response.data;
              this.usersTMP = response.data;
              this.users_count = this.users.length
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    edit_User(data) {
       this.$router.push("/usuarios/modificar/" + data.id);
    
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "¿Desea desactivar este elemento?" : "¿Desea activar este elemento?";
      // let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "Elemento inactivado"
              : "Elemento activado";
      Swal.fire({
        title: title,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Cambiar estatus",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeStatus_user(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    text: text,
                    icon: "success",
                    confirmButtonColor: "#00373B",
                  });
                  this.allUsers();
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_User(row) {
      Swal.fire({
        title: "¿Desea eliminar este elemento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.changeStatus_user(form)
              .then(response => {
                if (response.code === 200) {
                  this.users.splice(row.index, 1);
                  Swal.fire({
                    title:"Elemento eliminado",
                    confirmButtonColor: "#00373B",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2{
 background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
 margin-right: 0.5rem !important;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #00373B !important;
    border-color: #00373B !important;
}
</style>