<template>
  <div id="SchoolContainer">
    <div class="d-flex" id="wrapper">
      <!-- Sidebar -->
      <div v-if="showNotMobile==true" class="bg-light border-right" id="sidebar-wrapper">
        <b-row>
          <b-col md="1"> </b-col>
          <b-col md="10">
            <div style="text-align:center">
              <img
                src="../../assets/img/logo_colab_es.png"
                width="200px"
                style="padding:10px"
                alt="ECYD Logo"
              />
            </div>
          </b-col>
          <b-col md="1"> </b-col>
        </b-row>

        <div class="list-group list-group-flush">
          <router-link
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin_welcome' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="home" />
            <span style="color:black;padding-left:10px" class="text2">Inicio</span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path!='/usuarios/agregar' && !this.$route.path.includes('usuarios/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-users'}"
            exact
            id="adminUsers"
          >
            <font-awesome-icon style="color:black" icon="user-friends" />
            <span style="color:black;padding-left:10px" class="text2">Usuarios</span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path=='/usuarios/agregar' || this.$route.path.includes('usuarios/modificar/'))
           "
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-users'}"
            exact
            id="adminUsers"
          >
            <font-awesome-icon style="color:black" icon="user-friends" />
            <span style="color:black;padding-left:10px" class="text2">Usuarios</span>
          </router-link>
        <router-link v-if="id_rol ==1  && (this.$route.path!='/proyectos/agregar' && !this.$route.path.includes('/proyectos/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-projects-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Proyectos
            </span>
          </router-link>
            <router-link v-if="id_rol ==1  && (this.$route.path=='/proyectos/agregar' || this.$route.path.includes('/proyectos/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-projects-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Proyectos
            </span>
          </router-link>
          <router-link v-if="id_rol ==1  && (this.$route.path!='/materiales/agregar' && !this.$route.path.includes('/materiales/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-materials-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="file-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Materiales
            </span>
          </router-link>
           <router-link v-if="id_rol ==1  && (this.$route.path=='/materiales/agregar' || this.$route.path.includes('/materiales/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item menu-item router-link-active"
            :to="{ name: 'admin-materials-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="file-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Materiales
            </span>
          </router-link>
          <router-link v-if="id_rol ==1 && (this.$route.path!='/almacenes/agregar' && !this.$route.path.includes('/almacenes/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-warehouses-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Almacenes
            </span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path=='/almacenes/agregar' || this.$route.path.includes('/almacenes/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-warehouses-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Almacenes
            </span>
          </router-link>
           <router-link v-if="this.$route.path!='/stocks/agregar' && !this.$route.path.includes('/stocks/modificar/')"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-stocks-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
              Stock Base
            </span>
          </router-link>
           <router-link v-if="this.$route.path=='/stocks/agregar' || this.$route.path.includes('/stocks/modificar/')"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-stocks-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
              Stock Base
            </span>
          </router-link>
           <router-link v-if="this.$route.path!='/stocks/transferir'"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-stocks-alltransfer' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
             Stock General
            </span>
          </router-link>
           <router-link v-if="this.$route.path=='/stocks/transferir'"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-stocks-alltransfer' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
             Stock General
            </span>
          </router-link>
           <router-link v-if="id_rol ==1"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-operations-all' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="sign-out-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Operaciones
            </span>
          </router-link>
           <router-link
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-report-index' }"
            exact
          >
            <font-awesome-icon style="color:black" icon="download" />
            <span style="color:black;padding-left:10px;" class="text2">
              Reportes
            </span>
          </router-link>
           <b-button v-if="id_rol ==1"
            class="list-group-item list-group-item-action bg-light menu-item"
            v-on:click="activeConfig()"
          >
            <b-row>
              <b-col md="1"
                ><font-awesome-icon style="color: black" icon="tools"
              /></b-col>
              <b-col md="8"
                ><span style="color: black; padding-left: 0px" class="text2">Configuración</span></b-col
              >
              <b-col md="2"
                > <font-awesome-icon v-if="activeConfiguration == 0" style="color: black" icon="chevron-down"
              />
              <font-awesome-icon v-if="activeConfiguration == 1" style="color: black" icon="chevron-up"
              />
               </b-col>
            </b-row>
          </b-button>
          <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/unidades/agregar' && !this.$route.path.includes('/unidades/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-units-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Unidades
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/unidades/agregar' || this.$route.path.includes('/unidades/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-units-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Unidades
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/trabajadores/agregar' && !this.$route.path.includes('/trabajadores/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-workers-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Trabajadores
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/trabajadores/agregar' || this.$route.path.includes('/trabajadores/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-workers-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Trabajadores
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/roles/agregar' && !this.$route.path.includes('/roles/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-roles-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Roles
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/roles/agregar' || this.$route.path.includes('/roles/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-roles-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Roles
            </span>
          </router-link>
            <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/categorias/agregar' && !this.$route.path.includes('/categorias/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-categories-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Categorias
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/categorias/agregar' || this.$route.path.includes('/categorias/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-categories-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Categorias
            </span>
          </router-link>

        
         
        </div>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper" v-if="showNotMobile==true">
        <b-navbar
          toggleable="lg"
          type="light"
          variant="light"
          :class="{ 'd-none': $route.path === '/login' }"
        >
          <b-navbar-brand href="#" style="color:black" @click="toggleNav">
            <b-icon-list></b-icon-list>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse" />

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-row>
              </b-row>

              <div style="margin-left:15px">
              </div>


              <div class="dropdown">
                <button class="dropbtn">
                  <span style="font-size: 30px;margin-right:8px">
                    <!-- <i
                     
                      style="color:black;margin-left:20px"
                      class="fas fa-user-circle"
                    ></i> -->
                    <font-awesome-icon style="color: black;margin-left:20px;vertical-align:middle" icon="user-circle"
              />
                    <!-- <b-avatar
                      v-if="profile_img != null"
                      variant="info"
                      v-bind:src="profile_img"
                      size="mr-3"
                    ></b-avatar> -->
                  </span>
                  <span class="mr-auto text2" style="margin-top:15px">{{ username }}</span
                  ><i
                    style="color:black;margin-left:20px;font-size:12px"
                    class="fas fa-chevron-down"
                  ></i>
                </button>
                <div class="dropdown-content">
                  <button
                    class="buttonDr"
                    v-on:click="logoutMenu"
                    style="color:black"
                  >
                    Cerrar sesión
                  </button>
                </div>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>

       <div id="page-content-wrapper" v-if="showNotMobile==false">
      
        <b-navbar
          toggleable="lg"
          type="light"
          variant="light"
          :class="{ 'd-none': $route.path === '/login' }"
        >
          <b-navbar-toggle target="nav-collapse" />

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
             <b-row>
          <b-col md="1"> </b-col>
          <b-col md="10">
            <div style="text-align:center">
              <img
                src="../../assets/img/logo_colab_es.png"
                width="100px"
                style="padding:10px"
                alt="ARBIBE Logo"
              />
            </div>
          </b-col>
          <b-col md="1"> </b-col>
        </b-row>

        <div class="list-group list-group-flush">
          <router-link 
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin_welcome' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="home" />
            <span style="color:black;padding-left:10px" class="text2">Inicio</span>
          </router-link>
         <router-link v-if="id_rol ==1 && (this.$route.path!='/usuarios/agregar' && !this.$route.path.includes('usuarios/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-users' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="user-friends" />
            <span style="color:black;padding-left:10px" class="text2">Usuarios</span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path=='/usuarios/agregar' || this.$route.path.includes('usuarios/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-users' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="user-friends" />
            <span style="color:black;padding-left:10px" class="text2">Usuarios</span>
          </router-link>
           <router-link v-if="id_rol ==1  && (this.$route.path!='/proyectos/agregar' && !this.$route.path.includes('/proyectos/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-projects-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Proyectos
            </span>
          </router-link>
           <router-link v-if="id_rol ==1  && (this.$route.path=='/proyectos/agregar' || this.$route.path.includes('/proyectos/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-projects-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Proyectos
            </span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path!='/materiales/agregar' && !this.$route.path.includes('/materiales/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-materials-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="file-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Materiales
            </span>
          </router-link>
            <router-link v-if="id_rol ==1 && (this.$route.path=='/materiales/agregar' || this.$route.path.includes('/materiales/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-materials-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="file-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Materiales
            </span>
          </router-link>
          <router-link v-if="id_rol ==1 && (this.$route.path!='/almacenes/agregar' && !this.$route.path.includes('/almacenes/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-warehouses-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Almacenes
            </span>
          </router-link>
           <router-link v-if="id_rol ==1 && (this.$route.path=='/almacenes/agregar' || this.$route.path.includes('/almacenes/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-warehouses-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="building" />
            <span style="color:black;padding-left:10px;" class="text2">
              Almacenes
            </span>
          </router-link>
        <router-link v-if="this.$route.path!='/stocks/agregar' && !this.$route.path.includes('/stocks/modificar/')"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-stocks-all' }"
             v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
              Stock Base
            </span>
          </router-link>
          <router-link v-if="this.$route.path=='/stocks/agregar' || this.$route.path.includes('/stocks/modificar/')"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-stocks-all' }"
             v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
              Stock Base
            </span>
          </router-link>
           <router-link v-if="this.$route.path!='/stocks/transferir'"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-stocks-alltransfer' }"
             v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
             Stock General
            </span>
          </router-link>
           <router-link v-if="this.$route.path=='/stocks/transferir'"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-stocks-alltransfer' }"
             v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="boxes" />
            <span style="color:black;padding-left:10px;" class="text2">
             Stock General
            </span>
          </router-link>
           <router-link v-if="id_rol ==1"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-operations-all' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="sign-out-alt" />
            <span style="color:black;padding-left:10px;" class="text2">
              Operaciones
            </span>
          </router-link>
           <router-link
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-report-index' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="download" />
            <span style="color:black;padding-left:10px;" class="text2">
              Reporte
            </span>
          </router-link>
            <b-button v-if="id_rol ==1"
            class="list-group-item list-group-item-action bg-light menu-item"
            v-on:click="activeConfig()"
          >
            <b-row>
              <font-awesome-icon style="color: black; margin-left:20px"  icon="tools"/><span style="color: black; margin-left:10px" class="text2">Configuración</span><i
                  style="color: black; margin-left:20px"
                  class="fas fa-chevron-down"
                ></i
              >
            </b-row>
          </b-button>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/unidades/agregar' && !this.$route.path.includes('/unidades/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-units-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Unidades
            </span>
          </router-link>
          <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/unidades/agregar' || this.$route.path.includes('/unidades/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-units-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Unidades
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/trabajadores/agregar' && !this.$route.path.includes('/trabajadores/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-workers-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Trabajadores
            </span>
          </router-link>
          <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/trabajadores/agregar' || this.$route.path.includes('/trabajadores/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-workers-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Trabajadores
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/roles/agregar' && !this.$route.path.includes('/roles/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-roles-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Roles
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/roles/agregar' || this.$route.path.includes('/roles/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-active"
            :to="{ name: 'admin-roles-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Roles
            </span>
          </router-link>
           <router-link
           v-if="activeConfiguration == 1 && (this.$route.path!='/categorias/agregar' && !this.$route.path.includes('/categorias/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'admin-categories-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Categorias
            </span>
          </router-link>
          <router-link
           v-if="activeConfiguration == 1 && (this.$route.path=='/categorias/agregar' || this.$route.path.includes('/categorias/modificar/'))"
            class="list-group-item list-group-item-action bg-light menu-item router-link-activeaa"
            :to="{ name: 'admin-categories-all' }"
            v-b-toggle.nav-collapse
          >
           
            <span style="color:black;padding-left:10px;" class="text2">
              Categorias
            </span>
          </router-link>
        

          <router-link
            v-if="user == 2 && approved1 == 1"
            class="list-group-item list-group-item-action bg-light menu-item"
            :to="{ name: 'profile' }"
            v-b-toggle.nav-collapse
          >
            <font-awesome-icon style="color:black" icon="user-friends" /><span
              style="color:black;padding-left:10px"
              class="text2"
            >
              Perfil
            </span>
          </router-link>
            <b-button
            class="list-group-item list-group-item-action bg-light menu-item"
            v-on:click="activeProfile()"
          >
         <span
              style="color:black;padding-left:10px"
              class="text2"
            >
            <b-avatar
                  
                      variant="info"
                      v-bind:src="profile_img"
                      size="mr-3"
                    ></b-avatar>{{ username }}</span
            ><i
              style="color:black;margin-left:40px"
              class="fas fa-chevron-down"
            ></i>
          </b-button>
           <b-button
            class="list-group-item list-group-item-action bg-light menu-item"
            v-if="activePro==1"
            v-on:click="logoutMenu"
          >
         <span style="color:black;padding-left:30px;display:block" class="text2">
              Cerrar sesión
            </span>
          </b-button>
          
        </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
export default {
  name: "DirectorContainer",
  data() {
    return {
      approved1: localStorage.getItem("aproval"),
      profile_img: localStorage.getItem("profile_img"),
      lang: "es",
      langs: ["es", "en"],
      activeConfiguration: 0,
      activeUser: 0,
      activePro:0,
      user: 1,
      id_rol:Cookies.get("id_rol"),
      showNotMobile: false,
      username:  Cookies.get("email"),
      URLactual:"",
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted: function() {

    this.URLactual = window.location;

    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

  
  if(window. innerWidth>=1024){
    this.showNotMobile=true;

  }
   

  },
 
  methods: {
    ...mapActions(["logout", "set_homeSchool", "addToastMessage"]),
    toggleNav() {
      $("#wrapper").toggleClass("toggled");
    },
    activeConfig() {
      if (this.activeConfiguration === 0) {
        this.activeConfiguration = 1;
      } else {
        this.activeConfiguration = 0;
      }
    },
    activeUsers() {
      if (this.activeUser === 0) {
        this.activeUser = 1;
      } else {
        this.activeUser = 0;
      }
    },
    activeProfile() {
      if (this.activePro === 0) {
        this.activePro = 1;
      } else {
        this.activePro = 0;
      }
    },
    logoutMenu() {
      Swal.fire({
        title: "¿Desea cerrar la sesión?",
        icon: "question",
        showCancelButton: true,
        reverseButtons: false,
        confirmButtonText: "Cerrar sesión",
        confirmButtonColor: "#00373B",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#DD137B",
      }).then((result) => {
        if (result.value) {
          Cookies.remove('email');
            Cookies.remove('email');
           Cookies.remove('id_rol');
           Cookies.remove('id_user');
          this.$router.push("/");
        }
      });
    },
    backAdmin() {
      Swal.fire({
        title: "Atención!",
        text: "¿Esta seguro de volver al entorno administrativo?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#37bd25",
        cancelButtonColor: "#DD137B",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, Volver",
      }).then((result) => {
        if (result.value) {
          this.set_homeSchool(null).then((response) => {
            console.log(response);
            this.$router.push("/admin/dashboard");
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.backgroundGray{
  background-color:#f8f9fc;
}
.text5 {
  text-align: left;
  color: #000000;
  font-weight: 600;
  font-size: 4;
}
/* Style The Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: #f8f9fa;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: -15px;
}

.dropdown2 {
  position: relative;
  display: inline-block;
  margin-top: -15px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  right: 0;
  display: none;
  position: absolute;
  background-color: #f8f9fa;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.buttonDr {
  border: none;
  background-color: #f8f9fa;
  height: 50px;
}

.menu-item {
  color: white;
}
.menu-item:hover {
  background-color: #00873d;
  background: #00873d;
}
#wrapper {
  overflow-x: hidden;
}
.router-link-active {
  /* background-color: #606b75;*/
  background-color: #ffffff !important;
  border-left: 10px solid #DD137B !important;
}
.stylenew {
  border-width: 1px;
  border-left-color: #000;
}
@media only screen and (min-width: 1024px) {
 #sidebar-wrapper {
  min-height: 100vh;
  margin-left: -70rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  color: white;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: white;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
  .form-control3 {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    background-color: #f8f9fa;
    border: 0px solid #ffffff;
  }
}

@media only screen and (max-width: 1024px) {
 #sidebar-wrapper {
  min-height: 100vh;
  margin-left: -70rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  color: white;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: white;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
  .form-control3 {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    background-color: #f8f9fa;
    border: 0px solid #ffffff;
  }
}
}





}
</style>
