<template>
  <div id="AddOperation">
    <br />
    <div class="content">
     
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AddOperation",
  data() {
    return {
      element: "",
      loading: false,
      listCountries: [],
      listTerritories: [],
      id_country: "",
      id_territory: "",
      error: "",
      mobile:false,
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
      if(window. innerWidth>=1024){
    this.mobile=false;
  }else{
    this.mobile=true;
  }
  },
  methods: {
    ...mapActions(["register_city", "loadCountries", "load_territories"]),

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    add_element() {
      if (
        this.element == "" ||
        this.id_country == "" ||
        this.id_territory == ""
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        return;
      }
      let form = {
        name: this.element,
        id_country: this.id_country,
        id_territory: this.id_territory
      };

      this.register_city(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/city");
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "Registro duplicado",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>
