<template>
  <div id="UpdateLocation">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Almacenes</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary3" :to="{ name: 'admin-warehouses-all' }">Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left">Modificar almacén</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nombre:">
            <b-input-group size="md">
              <b-form-input v-model="name"  v-bind:class="{ 'validateInput':validateInput(this.name,'text') }" placeholder="Nombre del almacén"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.name,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
        <b-col md="6">
          <b-form-group label="Dirección:">
            <b-input-group size="md">
              <b-form-input v-model="address"  v-bind:class="{ 'validateInput':validateInput(this.address,'text') }" placeholder="Dirección del almacén"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.address,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.address,'text') }">La dirección es requerida</span>
          </b-form-group>
        </b-col> 
            <b-col md="6">
          <b-form-group label="Superintendente:">
           <b-input-group size="md">
              <b-form-input v-model="super_manager"  v-bind:class="{ 'validateInput':validateInput(this.super_manager,'text') }" placeholder="Nombre del superintendente"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.super_manager,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.super_manager,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Encargado del Almacén:">
           <b-input-group size="md">
              <b-form-input v-model="stock_manager"  v-bind:class="{ 'validateInput':validateInput(this.stock_manager,'text') }" placeholder="Nombre del encargado del almacén"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.stock_manager,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.stock_manager,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Ingeniero Residente:">
            <b-input-group size="md">
              <b-form-input v-model="engineer_manager"  v-bind:class="{ 'validateInput':validateInput(this.engineer_manager,'text') }" placeholder="Nombre del ingeniero"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.engineer_manager,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.engineer_manager,'text') }">El nombre es requerido</span>
          </b-form-group>
        </b-col> 
        <b-col md="6">
          <b-form-group label="Proyecto:">
            <b-select id="perPageSelectInCharges"   v-model="id_project"  :options="listProjects" value-field="id" text-field="name" > 
            </b-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Nota:">
            <b-input-group size="md">
              <b-form-input v-model="notes"  v-bind:class="{ 'validateInput':validateInput(this.notes,'text') }" placeholder="Notas"></b-form-input>
            </b-input-group>
                <span v-show="validateInput(this.notes,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.notes,'text') }">El nota es requerida</span>
          </b-form-group>
        </b-col> 
        <b-col md="6" style="text-align:center">
           <h6>Agregar imagen del almacén</h6>
          <div style="text-align:center;margin-top:20px">
            <img v-if="image_read==null || image_read==''" class="img-responsive center-block d-block mx-auto imgStyles_"
              src="../../../../assets/img/image.png"/>
            <img v-if="image_read!=null" class="img-responsive center-block d-block mx-auto imgStyles_" style="width:250px"
              :src="image_read"/>
          </div>
             <b-modal id="modal2" ref="modal2" title="Agregar imagen">
              <template>
                <form ref="form"></form>
              </template>
              <b-form-file
                accept="image/jpeg, image/png, image/gif"
                v-model="image"
                :state="Boolean(image)"
                placeholder="Imagen de almacén"
                drop-placeholder="Drop file here..."
                @input="handleFileChange"
              ></b-form-file>
              <template #modal-footer>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <b-button
                      class="primary btn-md"
                      style="margin-right: 5px"
                      @click="cancelP2"
                    >
                      Aceptar </b-button
                    ><span></span>
                    <b-button class="primaryBlack btn-md" @click="cancelP2">
                      Cancelar
                    </b-button>
                  </div>
                </div>

                <!-- Emulate built in modal footer ok and cancel button actions -->
              </template>
            </b-modal>
          <b-row v-show="!tablet">
              <b-col md="2"></b-col> 
              <b-col md="4">
              <br>
               <b-button class="" size="sm" variant="primary" style="margin-right:5px" v-b-modal.modal2 >Subir imagen</b-button>
              </b-col> 
              <b-col md="4">
              <br>
                <b-button class="" size="sm" variant="danger" style="margin-right:5px" v-on:click="resetImg()">Eliminar</b-button>
              </b-col> 
           </b-row>
             <b-row v-show="tablet">
              <b-col md="1"></b-col> 
              <b-col md="5">
              <br>
               <b-button class="" size="sm" variant="primary" style="margin-left:30px" v-b-modal.modal2 >Subir imagen</b-button>
              </b-col> 
              <b-col md="4">
              <br>
                <b-button class="" size="sm" variant="danger" style="margin-right:-20px" v-on:click="resetImg()">Eliminar</b-button>
              </b-col> 
           </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" style="text-align: center;">
          <br>
          <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
             <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
            <b-col md="5"></b-col>
          </b-row>
            <b-button block class="primary mt-4" v-on:click="onSubmit()" >Modificar</b-button>
            
        </b-col><!--v-on:click="add_element()"-->
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "UpdateLocation",
  data() {
    return {
      validateForm:true,
      name: null,
      address: null,
      // listSuper:[{'id':1,'name':'superin.. 1'}],
      // id_super:null,
      super_manager: null,
      stock_manager: null,
      engineer_manager: null,
      listProjects:[{'id':1,'name':'project 1'}],
      id_project:null ,
      notes:null,
      image:null,
      image_read:null,
      progress:false,
      tablet:false
      
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {
      if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
   this.allProjects();
    this.consult()
   
  },
  methods: {
    ...mapActions([
      "modify_location","consult_location","loadProjects"
    ]),
    onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        
        this.createImage(files[0]);
    },
       allProjects() {

    let query="?active=1"


      this.loadProjects(query)
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false;
              this.listProjects = response.data;
                this.listProjects=this.listProjects.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    createImage(file) {
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
            vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.image = reader;
        document.getElementById("file").value = ""
    },
    resetImg(){
        this.image = null;
        this.image_read = null;
    },
    validateAllForm(){
      if(this.name == null||this.super_manager == null ||this.stock_manager == null ||this.engineer_manager == null||this.address == null||this.notes == null){
        this.validateForm =true;
        
        return true
      }else{
        if(this.name==''||this.name==' '||this.name=='  ' || this.super_manager == '' || this.super_manager == ' ' || this.super_manager == '  ' ||this.stock_manager == '' 
        ||this.stock_manager == ' ' ||this.stock_manager == '  '||this.engineer_manager == '' ||this.engineer_manager == '  ' ||this.engineer_manager == '   '|| this.address==''||this.address==' '||this.address=='  '||this.notes==''||this.notes==' '||this.notes=='  ' ){
          this.validateForm =false;
         
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
    
    consult() {
      let form = {
        id: this.$route.params.id,
      };
      this.consult_location(form)
        .then((response) => {
          if (response.code == 200) {
            this.name = response.data.name;
            this.super_manager = response.data.super_manager;
            this.stock_manager = response.data.stock_manager;
            this.engineer_manager = response.data.engineer_manager;
            this.stock_manager = response.data.stock_manager;
            this.address = response.data.address;
            this.notes = response.data.notes;
            this.id_project = response.data.id_project;
            this.image_read= response.data.image;
            this.image = response.data.image;

            
            
          }
        })
        .catch((error) => {
          // Swal.fire({
          //   title: this.$i18n.t("error_catch"),
          //   icon: "warning",
          //   reverseButtons: true,
          //   confirmButtonColor: "#AE0B25",
          //   confirmButtonText: this.$i18n.t("btnok"),
          // });
          console.log(error);
        });
    },
    onSubmit() {
      if(!this.validateAllForm()){
          if(this.image_read==null){
        this.image="";
      }
          this.add_element();
      }
    },
       cancelP2() {
      this.$refs["modal2"].hide();
    },
    handleFileChange(file) {
      const reader = new FileReader();
      let imageFile = file;
      reader.readAsDataURL(imageFile);
      reader.onload = (e) => {
        this.image_read = e.target.result;
      };
    },
    add_element() {

      this.progress = true;
      if (
        this.name == "" ||
        this.address == "" ||
        this.super_manager == null ||
        this.stock_manager== null ||
        this.engineer_manager == null ||
        this.notes == null ||
        this.id_project == null 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }

      // if (
      //   this.image == null
      // ) {
      //   Swal.fire({
      //     title:"Debes seleccionar una imagen",
      //     icon: "warning",
      //     reverseButtons: true,
      //     confirmButtonColor: "#00373B",
      //     confirmButtonText: "Ok",
      //   });
      //   this.progress = false;
      //   return;
      // }


      let data = new FormData();
      data.append("id", this.$route.params.id);
      data.append("name", this.name);
      data.append("address", this.address);
      data.append("super_manager", this.super_manager);
      data.append("stock_manager", this.stock_manager);
      data.append("engineer_manager", this.engineer_manager);
      data.append("notes", this.notes);
      data.append("id_project", this.id_project);
      data.append("image", this.image);

      this.modify_location(data)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Modificación exitosa",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/almacenes/listado");
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "Registro duplicado",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
           if (error.code == 409) {
            Swal.fire({
              title: "Nombre duplicado",
              text: "el nombre indicado, ya se encuentra en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.name="";
              }
            });
          }
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>
