<template>
  <div id="AddStock">
    <br />
     <br v-show="loading"/>
         <br v-show="loading"/>
          <br v-show="loading"/>
     <div v-show="loading" v-if="!tablet" class="d-flex justify-content-center mb-3">
       
    <b-spinner style="width: 3rem; height: 3rem;"  v-show="loading" label="Cargando"></b-spinner>
  </div>
  
   <br v-show="loading"/>
   <div v-show="loading"  v-if="!tablet" class="d-flex justify-content-center mb-3">
    <p v-show="loading">Por favor espere un momento, mientras cargan los datos correspondientes</p>
  </div>
  

    <div   v-show="!loading" class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
           <h5>Stock</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary3" :to="{ name: 'admin-stocks-all' }">
            Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left;font-weight: 800;"><strong>Agregar stock al almacen base</strong></p>
        </b-col>
      </b-row>
        <b-row>
          <b-col md="8">
          <b-form-group label="Material:">
            <v-select style="height:30px" placeholder="Seleccione un material" id="perPageSelectStocks" label="name" value="id"  v-model="material"  :options="listMaterials"> 
             
            </v-select>
           
          </b-form-group>
          <br v-if="tablet">
          
        </b-col>
        <b-col md="4" v-if="mobile==false &&  material!=null">
          <b-form-group label="Imagen de material:">
            <img width="100px"  class="img-responsive"
              :src="materialImage"/>
          </b-form-group>
        </b-col> 
        </b-row>
        <b-row>
           <b-col md="8"> <small>{{material_detail.name}}</small></b-col>
            <b-col md="4"> <span v-if="material_stock_available">Cantidad actual en almacén base: {{material_stock_available.quantity}}</span>
            <span v-else>Cantidad actual en almacén base: 0</span>
            </b-col>
        </b-row>
      <b-row>
       
       
        <b-col md="4">
          <hr>
          <b-form-group label="Nueva cantidad:">
            <b-input-group size="md">
              <b-form-input v-model="capacity" type="number" v-bind:class="{ 'validateInput':validateInput(this.capacity,'text') }" placeholder="Cantidades"></b-form-input>
            </b-input-group>
            <span v-show="validateInput(this.capacity,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.capacity,'text') }">La cantidad es requerida</span>
          </b-form-group>
        </b-col> 
        
        <b-col md="6">
          <hr>
          <b-form-group label="Nota:">
            <b-input-group size="md">
              <b-form-input v-model="note" id="note" name="note"  placeholder="Nota del material"></b-form-input>
            </b-input-group>
         
          </b-form-group>
        </b-col> 
      </b-row>
      <b-row>
        <b-col md="12" style="text-align: center;">
          <br>
          <!-- <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span> -->
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
                  <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
               </b-row>
            <b-button v-show="!this.validateAllForm()" block class="primary mt-4" v-on:click="onSubmit()" >Guardar</b-button>
               <b-button v-show="this.validateAllForm()" disabled block class="secondary mt-4" >Guardar</b-button>
       
        </b-col>
      </b-row>
      <br/>
         <b-row class="mt-3 px-3">
           <h6><strong>Materiales agregados recientemente</strong></h6>
            <br/>
        <b-col sm="12">
          <div>
            <b-table
              responsive
              id="my-table"
              show-empty
              fixed
              small
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="save"
              :fields="field"
              empty-text="No hay datos disponibles"
            >
              <!--                :filter="filter"-->
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>
          

           

            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div></div>
        </b-col>
      </b-row>
      <br/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);


export default {
  name: "AddStock",
  data() {
    return {
       id: "",
      loading:true,
      validateForm:true,
      material:null,
      listMaterials:[],
      save:[],
      capacity: null,
      note: null,
      progress:false,
      mobile:false,
      tablet:false,
      materialImage:null,
      material_id:null,
      material_detail:{},
      material_stock_available:{},
      field: [
     
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Cantidad",
          sortable: true,
        },
        
        {
          key: "notes",
          label: "Nota",
          sortable: true,
        },        


      ],
      
    };
  },
  
  computed: {
    
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  updated() {
    if(this.material!=null && (this.material_id!=this.material.id)){
      this.filterMaterial();
      this.material_id=this.material.id
    }
  },
  mounted() {

    if(this.$route.params.id!==undefined){
     this.consult();
    }


 if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    this.allLocations();
    this.allMaterials();
    // setTimeout(() => {
    //   this.id_stock = this.listStocks[0].id
    //   this.material = this.listMaterials[0].id
    // }, 500);
  },
  methods: {
    ...mapActions([
      "register_stock",
      "loadLocation",
      "loadMaterial",
      "consult_material",
      "consult_stock_base"
    ]),
    validateAllForm(){
      if(this.capacity == null){
        this.validateForm =true;
        return true
      }else{
        if(this.capacity==''||this.capacity==' '||this.capacity=='  '){
          this.validateForm =false;
          return true
        }
        this.validateForm =false;
        return false
      }
    },
    validateInput(val,type ='text'){
      if(type == 'text'){
        if(val==''||val==' '||val=='  '){
          this.validateForm =false;
          return true
        }else{
          return false
        }
      }
    },
    allLocations() {
      this.isBusy = true;
      let query ="?active=1"
      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listStocks = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterMaterial(){

     let form = {
        id: this.material.id,
      };
      this.consult_material(form)
        .then((response) => {
          if (response.code == 200) {
            this.materialImage = response.data.image;
            this.material_detail = response.data;
            this.getOnBaseLocation();
          }
        })
        .catch((error) => {
        
          console.log(error);
        });
      
    },
    consult(){

      
      let form = {
        id: this.$route.params.id,
      };
      this.consult_material(form)
        .then((response) => {
          if (response.code == 200) {
            
             this.material = {name:response.data.name,id:response.data.id}
          }
        })
        .catch((error) => {
        
          console.log(error);
        });

    },
     getOnBaseLocation(){

     let form = {
        id: this.material_detail.id,
      };
      this.consult_stock_base(form)
        .then((response) => {
          if (response.code == 200) {
         
            this.material_stock_available = response.data[0];
          }
        })
        .catch((error) => {
        
          console.log(error);
        });
      
    },
    allMaterials() {
      
      this.isBusy = true;
      let query ="?active=1&lite=1"
      this.loadMaterial(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
             this.listMaterials = response.data.map(f => {
            return {name:f.code+' - '+f.name.substring(0,80)+"...",id:f.id};
          });
           this.listMaterials=this.listMaterials.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
            
            this.loading=false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if(!this.validateAllForm()){
          this.add_element();
      }
    },
    add_element() {
      this.progress = true;
      if (this.material == null ||
        this.capacity == null 
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
  
      let form = {
        material:this.material.name,
        id_material: this.material.id,
        quantity: this.capacity,
        notes: this.note,
      };
   
      this.register_stock(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              text:"El material ha sido agregado al almacén base",
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.material="";
                this.capacity=null;
                this.note="";
                this.material_detail = {}

              //  this.$router.push("/stocks/listado");
              }
              this.save.unshift(form);
            });
          }
          
        })
        .catch((error) => {
          console.log(error);
          if (error.code == 409) {
            this.progress = false;
            Swal.fire({
              title: "No existe material en el almacén base",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}



</style>

