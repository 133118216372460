<template>
  <div id="AllOperations">
    <br />
    <div class="content">
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <h5>Operaciones</h5>
          <br />
          <p>
            Las operaciones representan las transacciones realizadas por los
            usuarios a través del dashboard o desde la aplicación móvil.
          </p>
        </b-col>
        <b-col md="2" style="text-align: -webkit-right">
          <!-- <b-button  block class="primary" :to="{ name: 'admin-stocks-new' }">Agregar stock</b-button
          > -->
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>

      <div>
        <b-card no-body>
          <b-tabs
            content-class="mt-3"
            justified
            active-nav-item-class="font-weight-bold text-unique"
          >
            <b-tab title="Transferencias de Material" active>
                <transfer-all></transfer-all>
             
            </b-tab>
            <b-tab title="Entradas a Almacén">
                <stock-in></stock-in>
              
            </b-tab>
            <b-tab title="Entregas de Material">
                  <stock-out></stock-out>
            
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  
  
  
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}
import TransferAll from './transfers/all';
import StockIn from './stock_in/all';
import StockOut from './stock_out/all';
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  name: "AllOperations",
  components:{
      TransferAll:TransferAll,
      StockIn:StockIn,
      StockOut:StockOut
  },
  data() {
    return {
      element_count: 0,
      element_count1: 0,
      element_count2: 0,
      isBusy: false,
      id: "",
      items: [
        {
          text: "Operaciones",
        },
      ],
      listCountries: [],
      listCities: [],
      item_table: null,
      ttap_selected: null,
      itemsExitMaterials: [],
      itemsEntryMaterials: [],
      itemsMaterials: [],
      itemsMaterials1: [],
      itemsMaterials2: [],
      fieldMaterials: [
        {
          key: "user.full_name",
          label: "Usuario",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "date",
          label: "Fecha ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldMaterialsT: [
        {
          key: "user.full_name",
          label: "Usuario",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldMaterials1: [
        {
          key: "user.full_name",
          label: "Usuario",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },

        {
          key: "date",
          label: "Fecha ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldMaterials2: [
        {
          key: "user.full_name",
          label: "Operador",
          sortable: true,
        },

        {
          key: "worker.name",
          label: "Trabajador",
          sortable: true,
        },

        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },

        {
          key: "date",
          label: "Fecha ",
          sortable: true,
        },

        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldMaterials2T: [
        {
          key: "user.full_name",
          label: "Usuario",
          sortable: true,
        },

        {
          key: "worker.name",
          label: "Trabajador",
          sortable: true,
        },

        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },

        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      perPage: 20,
      perPage1: 20,
      perPage2: 20,
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      totalRows: 1,
      totalRows1: 1,
      totalRows2: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filter1: null,
      filter2: null,
      filterOn: [],
      elements: [],
      data: [],
      data2: [],
      data3: [],
      listLocation: [],
      location: null,
      location1: null,
      location2: null,
      type: null,
      elementsTMP: [],
      elementsTMP1: [],
      elementsTMP2: [],
      loading: false,
      error: "",
      dateFilter: "",
      dateFilter1: "",
      dateFilter2: "",
      mobile: false,
      tablet: false,
      rows: 0,
      rows1: 0,
      rows2: 0,
      worker: null,
      workers: [],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.mobile = false;
      this.tablet = false;
    } else if (window.innerWidth >= 500 && window.innerWidth < 1024) {
      this.mobile = false;
      this.tablet = true;
    } else {
      this.mobile = true;
      this.tablet = false;
    }

    this.allLocation();
    this.allWorkers();
    this.allElements();
    this.alltype2();
    this.alltype3();
  },
  methods: {
    ...mapActions([
      "loadTransfersAll",
      "loadLocation",
      "loadOperationAllByType",
      "change_active_campus",
      "delete_campus",
      "loadWorkers",
    ]),

    clearFilter() {
      this.allElements();
      this.filter = "";
      return true;
    },
    clearFilter1() {
      this.alltype2();
      this.filter1 = "";
      return true;
    },
    clearFilter2() {
      this.alltype3();
      this.filter2 = "";
      return true;
    },
    clearFilterDate() {
      this.filterData();
      this.dateFilter = "";
      return true;
    },
    clearFilterDate1() {
      this.filterData1();
      this.dateFilter1 = "";
      return true;
    },
    clearFilterDate2() {
      this.filterData2();
      this.dateFilter2 = "";
      return true;
    },
    clearFilterLocation2() {
      this.filterData2();
      this.location2 = null;
      return true;
    },
    clearFilterWorker2() {
      this.worker = null;
      this.filterData2();

      return true;
    },
    clearFilterLocation1() {
      this.filterData1();
      this.location1 = null;
      return true;
    },
    clearFilterLocation() {
      this.filterData();
      this.location = null;
      return true;
    },
    clearFilterType() {
      this.filterData();
      this.type = null;
      return true;
    },
    consult(row) {
      this.data = this.itemsMaterials.filter((x) => x.id === row);
    },
    consult2(row) {
      this.data2 = this.itemsMaterials1.filter((x) => x.id === row);
    },
    consult3(row) {
      this.data3 = this.itemsMaterials2.filter((x) => x.id === row);
    },
    allLocation() {
      let query = "?active=1";
      this.isBusy = true;

      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listLocation = response.data;
            this.listLocation = this.listLocation.sort(function(a, b) {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allWorkers() {
      let query = "?active=1";
      this.isBusy = true;

      this.loadWorkers(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.workers = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInput() {
      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.itemsMaterials.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.itemsMaterials = elementsTMP;
        this.element_count = this.itemsMaterials.length;
        this.rows = this.itemsMaterials.length;
      } else {
        this.itemsMaterials = this.elementsTMP;
        this.element_count = this.itemsMaterials.length;
        this.rows = this.itemsMaterials.length;
      }
      return true;
    },
    searchInput1() {
      let filterTMP = this.filter1;
      let elementsTMP = [];
      if (filterTMP) {
        this.itemsMaterials1.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.itemsMaterials1 = elementsTMP;
        this.element_count1 = this.itemsMaterials1.length;
        this.rows1 = this.itemsMaterials1.length;
      } else {
        this.itemsMaterials1 = this.elementsTMP1;
        this.rows1 = this.itemsMaterials1.length;
        this.element_count1 = this.itemsMaterials1.length;
      }
      return true;
    },
    searchInput2() {
      let filterTMP = this.filter2;
      let elementsTMP = [];
      if (filterTMP) {
        this.itemsMaterials2.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.itemsMaterials2 = elementsTMP;
        this.element_count2 = this.itemsMaterials2.length;
        this.rows2 = this.itemsMaterials2.length;
      } else {
        this.itemsMaterials2 = this.elementsTMP2;
        this.element_count2 = this.itemsMaterials2.length;
        this.rows2 = this.itemsMaterials2.length;
      }
      return true;
    },
    admin() {
      let form = {
        admin_id: parseInt(localStorage.getItem("id_admin")),
      };

      this.adminByID(form)
        .then((response) => {
          if (response.code == 200) {
            //  if(response.data.territories_managed.length>0){
            // this.listCountries=response.data.countries_managed;
            // }

            if (response.data.countries_managed.length > 0) {
              let countries = [];
              for (var i = 0; i < response.data.countries_managed.length; i++) {
                countries.push(response.data.countries_managed[i]);
                this.listCountries = countries;
              }
            }

            if (response.data.territories_managed.length > 0) {
              // let  cities=[];
              let countries = [];
              let cities = [];
              for (
                var x = 0;
                x <= response.data.territories_managed.length;
                x++
              ) {
                //  cities.push(response.data.territories_managed[i].countries_with_cities)
                // this.listCities=cities;
                // this.country_residence=

                for (
                  var p = 0;
                  p <
                  response.data.territories_managed[x].countries_with_cities
                    .length;
                  p++
                ) {
                  countries.push(
                    response.data.territories_managed[x].countries_with_cities[
                      p
                    ]
                  );
                  this.listCountries = countries;
                  for (
                    var y = 0;
                    y <
                    response.data.territories_managed[x].countries_with_cities[
                      p
                    ].cities.length;
                    y++
                  ) {
                    cities.push(
                      response.data.territories_managed[x]
                        .countries_with_cities[p].cities[y]
                    );
                  }
                  this.listCitiesTerri = cities;
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterData() {
      let query = this.dateFilter;
      this.isBusy = true;

      this.loadTransfersAll(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials = response.data.filter(
              (x) => x.id_type_transaction === 2 || x.id_type_transaction === 1
            );
            if (this.type !== null) {
              this.itemsMaterials = response.data.filter(
                (x) => x.id_type_transaction === this.type
              );
            }
            if (this.location !== null) {
              this.itemsMaterials = this.itemsMaterials.filter(
                (x) => x.id_location === this.location
              );
            }

            this.element_count = this.itemsMaterials.length;
            this.rows = this.itemsMaterials.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterData1() {
      this.isBusy = true;

      let query = this.dateFilter1;
      let form = {
        order_key: "updated_at",
        order_value: "desc",
        id_type_transaction: 3,
      };
      if (query) {
        form.query = query;
      }

      this.loadOperationAllByType(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials1 = response.data;

            if (this.location1 !== null) {
              this.itemsMaterials1 = this.itemsMaterials1.filter(
                (x) => x.id_location === this.location1
              );
            }

            this.element_count1 = this.itemsMaterials1.length;
            this.rows1 = this.itemsMaterials1.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterData2() {
      this.isBusy = true;
      let query = this.dateFilter2;
      let form = {
        order_key: "updated_at",
        order_value: "desc",
        id_type_transaction: 4,
      };
      if (query) {
        form.query = query;
      }
      if (this.worker) {
        form.id_worker = this.worker;
      }
      this.loadOperationAllByType(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials2 = response.data;

            if (this.location2 !== null) {
              this.itemsMaterials2 = this.itemsMaterials2.filter(
                (x) => x.id_location === this.location2
              );
            }

            this.element_count2 = this.itemsMaterials2.length;
            this.rows2 = this.itemsMaterials2.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allElements() {
      this.isBusy = true;

      this.loadTransfersAll()
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials = response.data.filter(
              (x) => x.id_type_transaction === 2 || x.id_type_transaction === 1
            );
            this.elementsTMP = this.itemsMaterials;
            this.element_count = this.itemsMaterials.length;
            this.rows = this.elementsTMP.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    alltype2() {
      this.isBusy = true;

      let form = {
        order_key: "updated_at",
        order_value: "desc",
        id_type_transaction: 3,
      };

      this.loadOperationAllByType(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials1 = response.data;
            this.elementsTMP1 = this.itemsMaterials1;
            this.element_count1 = this.itemsMaterials1.length;
            this.rows1 = this.elementsTMP1.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    alltype3() {
      this.isBusy = true;

      let form = {
        order_key: "updated_at",
        order_value: "desc",
        id_type_transaction: 4,
      };

      this.loadOperationAllByType(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.itemsMaterials2 = response.data;
            this.elementsTMP2 = this.itemsMaterials2;
            this.element_count2 = this.itemsMaterials2.length;
            this.rows2 = this.elementsTMP2.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideModal() {
      this.$refs["modal"].hide();
    },
    hideModal2() {
      this.$refs["modal2"].hide();
    },
    hideModal3() {
      this.$refs["modal3"].hide();
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";

      Swal.fire({
        title: title,
        text: "¿ Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.change_active_campus(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
                this.allElements();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_element(row) {
      Swal.fire({
        title: "Eliminar elemento",
        text: "¿Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.delete_campus(form)
            .then((response) => {
              if (response.code === 200) {
                this.elements.splice(row.index, 1);
                Swal.fire({
                  title: "Elemento eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered1(filteredItems) {
      this.totalRows1 = filteredItems.length;
      this.currentPage1 = 1;
    },
    onFiltered2(filteredItems) {
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}

.primary {
  background: linear-gradient(180deg, #00373b 0%, #00373b 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
  color: white !important;
}

.text-unique {
  color: #dd137b !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373b !important;
  border-color: #00373b !important;
}

.iconTable {
  color: #00373b !important;
}
.iconTable:hover {
  color: #dd137b !important;
}
</style>
