import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {

    register_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "stock/add_stock_base", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    transfer_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "stock/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "stock/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_stock_base({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "stock/get_on_base_location?active=1&id_material=" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "stock/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadStock({ commit, dispatch }, ) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "stock/all?order_key=id&order_value=desc";


        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadStockPaginate({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "stock/all_paginate?order_key=id&order_value=desc";

        if(form.active==1){
            endpoint= endpoint + "&active=1";
        }


        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint,{params:form})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadStockByQuantity({ commit, dispatch },form ) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "stock/all?id_location="+form.id_location_base+"&id_material="+form.id_material;


        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_stock({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "stock/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadTransfersAll({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "transfer/all?order_key=id&order_value=desc";
        if (query) endpoint +="&query="+ query;
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadTransfersAllPaginate({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "transfer/all_paginate";
       
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint,{params:form})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadOperationAllByType({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);

        let endpoint = "operation/all";
       
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint,{params:form})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadOperationAllByTypePaginate({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);

        let endpoint = "operation/all_paginate";
       
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint,{params:form})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};