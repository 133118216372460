<template>
  <b-card-text>
    <b-row class="mt-3 px-3" v-show="!tablet">
      <b-col sm="12">
        <div>
          <p style="font-weight:600">
            Cantidad de entregas de material: {{ element_count }}
          </p>

          <b-row>
            <b-col lg="6" class="my-1">
              <b-form-group
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="sm">
                  <b-input-group-append>
                    <button class="primary2">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'search']"
                        title="Buscar"
                      />
                    </button>
                  </b-input-group-append>

                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="getAllStockOut(1)"
                    type="text"
                    placeholder="Buscar solicitud"
                  />

                  <b-input-group-append>
                    <button
                      class="primary2"
                      :disabled="!filter"
                      @click="clearFilter"
                    >
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filtrar por fecha:"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="lg">
                  <b-form-datepicker
                    placeholder="Seleccionar fecha"
                    id="datepicker-sm3"
                    v-model="dateFilter"
                    size="sm"
                    locale="es"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    style="margin-top:2px;height:30px;font-family: Roboto;font-size:14px;border-radius: 0px;"
                    class="mb-2"
                    @context="getAllStockOut(1)"
                  ></b-form-datepicker>

                  <b-input-group-append>
                    <button class="primary2" @click="clearFilterDate()">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                style="margin-left:-10px"
                label="Almacén:"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="sm">
                  <b-form-select
                    id="perPage11Select"
                    v-model="location"
                    size="sm"
                    :options="listLocation"
                    @change="getAllStockOut(1)"
                    value-field="id"
                    text-field="name"
                  >
                    <b-form-select-option :value="null"
                      >Seleccione un almacén</b-form-select-option
                    >
                  </b-form-select>

                  <b-input-group-append>
                    <button class="primary2" @click="clearFilterLocation()">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
             
            </b-col>
          </b-row>
          <br />
          <b-table
            responsive
            id="my-table"
            show-empty
            small
            stacked="md"
            sticky-header="true"
            hover
            :primary-key="id"
            :items="itemsStockIn"
            :fields="fieldMaterials"
           
            :per-page="perPage"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :busy="isBusy"
            empty-text="No hay transferencias disponibles"
          >
            <!--                :filter="filter"-->
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Cargando ...</strong>
              </div>
            </template>
            <template v-slot:cell(date)="row">
              <p>{{ dateFormat(row.item.updated_at) }}</p>
            </template>

            <template v-slot:cell(type)="row">
              <b-badge
                v-if="row.item.id_type_transaction == 3"
                variant="info"
                >{{ row.item.type_transaction.name }}</b-badge
              >
              <b-badge
                v-if="row.item.id_type_transaction == 4"
                variant="warning"
                >{{ row.item.type_transaction.name }}</b-badge
              >
            </template>
            <template v-slot:cell(material)="row">
              <img
                class="img-responsive mx-auto"
                style="
                width: 30px;
              "
                :src="row.item.material.image"
              />
              <span>{{ row.item.material.name }}</span>
            </template>

            <template v-slot:cell(manager)="row">
              <span v-if="row.item.id_manager != null"
                >{{ row.item.manager.first_name }}
                {{ row.item.manager.last_name }}</span
              >
            </template>
            <template v-slot:cell(quantityUnit)="row">
              <span
                >{{ row.item.quantity }}
                {{ row.item.material.unity.name }}</span
              >
            </template>

            <template v-slot:cell(actions)="row">
              <button
                size="sm"
                class="primary2"
                v-b-modal.modal3
                @click="consult(row.item)"
              >
                <font-awesome-icon
                  class="iconTable"
                  icon="eye"
                  style="color: black!important;"
                  title="Ver detalle"
                />
              </button>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3 px-3" v-show="tablet">
      <b-col sm="12">
        <div>
          <p style="font-weight:600">
            Cantidad de operaciones: {{ element_count }}
          </p>
          <br />
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group>
                <b-input-group size="sm">
                  <b-input-group-append>
                    <button class="primary2">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'search']"
                        title="Buscar"
                      />
                    </button>
                  </b-input-group-append>

                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="getAllStockOut(1)"
                    type="text"
                    placeholder="Buscar solicitud"
                  />

                  <b-input-group-append>
                    <button
                      class="primary2"
                      :disabled="!filter"
                      @click="clearFilter"
                    >
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filtrar por fecha:"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="lg">
                  <b-form-datepicker
                    placeholder="Seleccionar fecha"
                    id="datepicker-sm3"
                    v-model="dateFilter"
                    size="sm"
                    locale="es"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    style="margin-top:2px;height:30px;font-family: Roboto;font-size:14px;border-radius: 0px;"
                    class="mb-2"
                    @context="getAllStockOut(1)"
                  ></b-form-datepicker>

                  <b-input-group-append>
                    <button class="primary2" @click="clearFilterDate()">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6" class="my-1">
              <b-form-group
                style="margin-left:-10px"
                label="Almacén:"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="sm">
                  <b-form-select
                    id="perPage11Select"
                    v-model="location"
                    size="sm"
                    :options="listLocation"
                    @change="getAllStockOut(1)"
                    value-field="id"
                    text-field="name"
                  >
                    <b-form-select-option :value="null"
                      >Seleccione un almacén</b-form-select-option
                    >
                  </b-form-select>

                  <b-input-group-append>
                    <button class="primary2" @click="clearFilterLocation()">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Tipo:"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <br />
                <b-input-group style="margin-top:-30px" size="sm">
                  <b-form-select
                    id="perPage11Select"
                    v-model="type"
                    size="lg"
                    @change="getAllStockOut(1)"
                    value-field="id"
                    text-field="name"
                  >
                    <b-form-select-option value="null"
                      >Seleccione un tipo</b-form-select-option
                    >
                    <b-form-select-option :value="2"
                      >Entrada de almacén</b-form-select-option
                    >
                    <b-form-select-option :value="1"
                      >Salida de almacén</b-form-select-option
                    >
                  </b-form-select>

                  <b-input-group-append>
                    <button class="primary2" @click="clearFilterType()">
                      <font-awesome-icon
                        class="iconTable"
                        :icon="['fas', 'times']"
                        title="Limpiar busqueda"
                      />
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <br />
          <b-table
            responsive
            id="my-table"
            show-empty
            small
            stacked="md"
            sticky-header="true"
            hover
            :primary-key="id"
            :items="itemsStockIn"
            :fields="fieldMaterialsT"
            :current-page="currentPage"
            :per-page="perPage"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :busy="isBusy"
            empty-text="No hay transferencias disponibles"
          >
            <!--                :filter="filter"-->
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Cargando ...</strong>
              </div>
            </template>
            <template v-slot:cell(date)="row">
              <p>{{ dateFormat(row.item.updated_at) }}</p>
            </template>

            <template v-slot:cell(type)="row">
              <b-badge
                v-if="row.item.id_type_transaction == 3"
                variant="success"
                >{{ row.item.type_transaction.name }}</b-badge
              >
              <b-badge
                v-if="row.item.id_type_transaction == 4"
                variant="warning"
                >{{ row.item.type_transaction.name }}</b-badge
              >
            </template>
            <template v-slot:cell(material)="row">
              <img
                class="img-responsive mx-auto"
                style="
                width: 30px;
              "
                :src="row.item.material.image"
              />
              <span>{{ row.item.material.name }}</span>
            </template>

            <template v-slot:cell(manager)="row">
              <span v-if="row.item.id_manager != null"
                >{{ row.item.manager.first_name }}
                {{ row.item.manager.last_name }}</span
              >
            </template>
            <template v-slot:cell(quantityUnit)="row">
              <span
                >{{ row.item.quantity }}
                {{ row.item.material.unity.name }}</span
              >
            </template>

            <template v-slot:cell(actions)="row">
              <button
                size="sm"
                class="primary2"
                v-b-modal.modal3
                @click="consult(row.item)"
              >
                <font-awesome-icon
                  class="iconTable"
                  icon="eye"
                  style="color: black!important;"
                  title="Ver detalle"
                />
              </button>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row v-show="!tablet">
      <b-col lg="4" class="my-1"> </b-col>
      <b-col lg="4" class="my-1"> </b-col>
      <b-col lg="2" class="my-1">
        <div class="d-flex justify-content-center mb-5">
          <ul class="pagination mb-0">
            <template v-if="currentPage != 1">
              <a
                class="page-link"
                @click="getAllStockOut(currentPage - 1)"
                aria-label="Atras"
              >
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Atras</span>
              </a>
            </template>

            <template v-if="currentPage == 1">
              <a
                disabled
                class="page-link"
                style="pointer-events: none"
                aria-label="Atras"
              >
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Atras</span>
              </a>
            </template>

            <template v-if="currentPage == 1">
              <li class="page-item active">
                <a class="page-link" @click="getAllStockOut(1)">1</a>
              </li>
              <li v-if="lastPage > 1" class="page-item">
                <a class="page-link" @click="getAllStockOut(2)">2</a>
              </li>
            </template>

            <template v-if="currentPage > 1 && currentPage !== lastPage">
              <li class="page-item">
                <a class="page-link" @click="getAllStockOut(currentPage - 1)">{{
                  currentPage - 1
                }}</a>
              </li>
              <li class="page-item active">
                <a class="page-link" @click="getAllStockOut(currentPage)">{{
                  currentPage
                }}</a>
              </li>
              <li
                v-if="lastPage > 2 && currentPage !== lastPage"
                class="page-item"
              >
                <a class="page-link" @click="getAllStockOut(currentPage + 1)">{{
                  currentPage + 1
                }}</a>
              </li>
            </template>

            <template v-if="currentPage == lastPage && lastPage > 2">
              <li class="page-item">
                <a class="page-link" @click="getAllStockOut(currentPage - 1)">{{
                  currentPage - 1
                }}</a>
              </li>
              <li class="page-item active">
                <a class="page-link" @click="getAllStockOut(currentPage)">{{
                  currentPage
                }}</a>
              </li>
            </template>

            <template v-if="currentPage !== lastPage">
              <a
                class="page-link"
                @click="getAllStockOut(currentPage + 1)"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Siguiente</span>
              </a>
            </template>

            <template v-if="currentPage == lastPage">
              <a
                disabled
                class="page-link"
                style="pointer-events: none"
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Siguiente</span>
              </a>
            </template>
          </ul>
        </div>
      </b-col>
     
    </b-row>
     <b-modal
      id="modal3"
      ref="modal3"
      scrollable
      title="Detalles de la operación de entrega"
    >
      <template>
        <form ref="form">
          <div>
            <b-list-group>
              <b-list-group-item>
                <b-row>
                  <b-col>
                    <h6>Usuario</h6>
                    <b-input-group size="md">
                     <p class="text2">{{ detail_item3.user.full_name }}</p>
                    </b-input-group>
                  </b-col>
                  <b-col>
                     <h6>Trabajador</h6>
                    <b-input-group size="md">
                     <p class="text2">{{ detail_item3.worker.name }}</p>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Almacén</h6>
                <b-input-group size="md">
                  <p class="text2">{{ detail_item3.location.name }}</p>
                </b-input-group>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                  <b-col>
                    <h6>Cantidad</h6>
                    <b-input-group size="md">
                      <p class="text2">
                        {{ detail_item3.quantity }}
                        {{ detail_item3.material.unity.name }}
                      </p>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <h6>Fecha</h6>
                    <b-input-group size="md">
                      <p class="text2">{{ dateFormat(detail_item3.created_at) }}</p>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <h6>Material</h6>
                <b-input-group size="md">
                  <b-row>
                    <b-col md="2"
                      ><img
                        class="img-responsive mx-auto"
                        style="
                width: 50px;
              "
                        :src="detail_item3.material.image"
                    /></b-col>

                    <b-col md="10"
                      ><p class="text2" v-if="detail_item3.material.name">
                        {{ detail_item3.material.name.substring(0, 80) }}
                      </p></b-col
                    >
                  </b-row>
                </b-input-group>
              </b-list-group-item>
              <b-list-group-item>
                <p class="text2" v-if="detail_item3.notes">Notas: {{ detail_item3.notes }}</p>
              </b-list-group-item>
            </b-list-group>

            <br />
          </div>
        </form>
      </template>

      <template #modal-footer>
        <div class="row">
          <div class="col-sm-12 text-center">
            <b-button class="primary btn-md" @click="hideModal">
              Cancelar
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-card-text>
  
</template>
<script>


import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  name: "StockIn",

  data() {
    return {
      element_count: 0,
      element_count1: 0,
      element_count2: 0,
      isBusy: false,
      id: "",
      items: [
      ],
      listCountries: [],
      listCities: [],
      item_table: null,
      ttap_selected: null,
      itemsExitMaterials: [],
      itemsEntryMaterials: [],
      itemsStockIn: [],
     detail_item3: {
          user:{
              full_name:''
          },
          location:{
              name:''
          },
          type_transactions:{
              name:''
          },
          material:{
              name:'',
              unity:{
                  name:''
              }
          },
          worker:{
              name:''
          }
      },
      fieldMaterials: [
         {
          key: "user.full_name",
          label: "Operador",
          sortable: true,
        },

        {
          key: "worker.name",
          label: "Trabajador",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "date",
          label: "Fecha ",
          sortable: true,
        },
          {
          key: "notes",
          label: "Observación ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      fieldMaterialsT: [
         {
          key: "user.full_name",
          label: "Operador",
          sortable: true,
        },

        {
          key: "worker.name",
          label: "Trabajador",
          sortable: true,
        },
        {
          key: "location.name",
          label: "Almacén",
          sortable: true,
        },
        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantityUnit",
          label: "Cantidad",
          sortable: true,
        },
        {
          key: "notes",
          label: "Observación ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      perPage: 20,

      currentPage: 1,

      totalRows: 1,
        lastPage:1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,

      filterOn: [],
      elements: [],
      data: [],
      data2: [],
      data3: [],
      listLocation: [],
      location: null,

      type: null,
      elementsTMP: [],

      loading: false,
      error: "",
      dateFilter: "",

      mobile: false,
      tablet: false,
      rows: 0,

      worker: null,
      workers: [],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.mobile = false;
      this.tablet = false;
    } else if (window.innerWidth >= 500 && window.innerWidth < 1024) {
      this.mobile = false;
      this.tablet = true;
    } else {
      this.mobile = true;
      this.tablet = false;
    }

    this.allLocation();

    this.getAllStockOut(1);
  },
  methods: {
    ...mapActions([
      "loadOperationAllByTypePaginate",
      "loadLocation",
      "loadOperationAllByType",
      "change_active_campus",
      "delete_campus",
      "loadWorkers",
    ]),

    clearFilter() {
      this.filter = "";
      this.getAllStockOut(1);

      return true;
    },

    clearFilterDate() {
      this.dateFilter = "";
      this.getAllStockOut(1);

      return true;
    },

    clearFilterLocation() {
      this.location = null;
      this.getAllStockOut(1);

      return true;
    },
    clearFilterType() {
      this.type = null;
      this.getAllStockOut(1);

      return true;
    },
    consult(row) {
      
      this.detail_item3 = row
    },

    allLocation() {
      let query = "?active=1";
      this.isBusy = true;

      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listLocation = response.data;
            this.listLocation = this.listLocation.sort(function(a, b) {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

  

    getAllStockOut(page) {
       
      let form = {
        order_key: "id",
        order_value: "desc",
        //limit: 50,
        id_type_transaction:4,
        page:page,
        active: 1,
      };
      if (this.filter) {
        form.query = this.filter;
      }
      if (this.dateFilter) {
        form.date = this.dateFilter;
      }
      if (this.location) {
        form.id_location = this.location;
      }
      if (this.type) {
        form.id_type_transaction = this.type;
      }
      this.isBusy = true;

      this.loadOperationAllByTypePaginate(form)
        .then((response) => {
          if (response.code === 200) {
              this.itemsStockIn = [];
            this.isBusy = false;           
            this.itemsStockIn = response.data.data;

            for(let i in this.itemsStockIn){
              let rest = this.itemsStockIn[i];
              if(rest.notes){
              rest.notes = rest.notes.substr(0, 50);
              }
            }
          

            if(this.itemsStockIn){
                   this.element_count = this.itemsStockIn.length;
            this.rows = this.itemsStockIn.length;
            }     
                        
            this.perPage = response.data.meta.per_page;
            this.currentPage = page;
            this.lastPage = response.data.meta.last_page;
            this.element_count = response.data.meta.total;
          
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },

    hideModal() {
      this.$refs["modal3"].hide();
    },

    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";

      Swal.fire({
        title: title,
        text: "¿ Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.change_active_campus(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
                this.getAllStockOut(1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    delete_element(row) {
      Swal.fire({
        title: "Eliminar elemento",
        text: "¿Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.delete_campus(form)
            .then((response) => {
              if (response.code === 200) {
                this.elements.splice(row.index, 1);
                Swal.fire({
                  title: "Elemento eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
