<template>
  <div id="UpdateUser">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Usuarios</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
         <b-button block class="primary3" :to="{ name: 'admin-users' }">Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
      <b-col md="10" style="text-align: -webkit-left">
           <span style="text-align: -webkit-left">Modificar usuario</span> <b-button
              title="Modificar contraseña"
              style="background: transparent; border: none; margin-left: 10px"
              v-b-modal.modal
              variant="primary"
            >
              <font-awesome-icon class="iconTable" :icon="['fas', 'key']"
            /></b-button>
               <b-modal id="modal" ref="modal" title="Modificar contraseña">
              <template>
                       <b-form-group
                    label="Contraseña"
                  >
                  <b-input-group >
         <b-form-input
                      placeholder="Contraseña"
                      v-model="confirm_password"
                      required
                      v-bind:type="variable"
                    ></b-form-input>
    <b-input-group-append>
                  <button  class="primary2" @click="showPassword()">
                      <font-awesome-icon
                        v-if="variable == 'password'"
                        class="iconTable"
                        icon="eye"
                        size="sm"
                      />
                      <font-awesome-icon
                        v-if="variable == 'text'"
                        class="iconTable"
                        icon="eye-slash"
                      />
                    </button>
    </b-input-group-append>
  </b-input-group>
  </b-form-group>
                   <b-form-group
                    label="Confirmar contraseña"
                  >
                  <b-input-group >
       <b-form-input
                      placeholder="Confirmar contraseña"
                      v-model="password"
                      required
                      size="sm"
                      v-bind:type="variable2"
                    ></b-form-input>
    <b-input-group-append>
                  <button size="sm" class="primary2" @click="showPassword2()">
                      <font-awesome-icon
                        v-if="variable2 == 'password'"
                        class="iconTable"
                        icon="eye"
                      />
                      <font-awesome-icon
                        v-if="variable2 == 'text'"
                        class="iconTable"
                        icon="eye-slash"
                      />
                    </button>
    </b-input-group-append>
  </b-input-group>
  </b-form-group>
              </template>

              <template #modal-footer>
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <b-button
                      class="primary btn-md"
                      style="margin-right: 5px"
                      v-on:click="updatePassword()"
                    >Aceptar
                    </b-button>
                    <span></span>
                    <b-button class="primaryBlack btn-md" @click="cancelP">Cancelar
                    </b-button>
                  </div>
                </div>

                <!-- Emulate built in modal footer ok and cancel button actions -->
              </template>
            </b-modal>
        </b-col>
       </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="Nombre completo:">
              <b-input-group size="md">
                <b-form-input v-model="full_name"  v-bind:class="{ 'validateInput':validateInput(this.full_name,'text') }" placeholder="Nombre Completo"></b-form-input>
              </b-input-group>
              <span v-show="validateInput(this.full_name,'text')"  v-bind:class="{ 'validateMessage':validateInput(this.full_name,'text') }">El nombre es requerido</span>
            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group label="Correo Electronico:">
              <b-input-group size="md">
                <b-form-input type="email" v-model="email" v-bind:class="{ 'validateInput':validateInput(this.email,'email') }" placeholder="Correo Electronico"></b-form-input>
              </b-input-group>
              <span v-show="validateInput(this.email,'email')"  v-bind:class="{ 'validateMessage':validateInput(this.email,'email') }">El Correo Electronico es requerido</span>
            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group label="Roles:">
              <b-select id="perPageSelectRoles"   v-model="id_rol"  :options="listRoles" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option :value="null" >Seleccione un rol</b-form-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Agregar almacén:" v-if="mobile==false">
              <b-row>
                <b-col md="11">
                  <b-select id="perPageSelectStocks"   v-model="id_location"  :options="listLocation" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option  :value="null">Seleccione un almacén</b-form-select-option>
                </template>
              </b-select>
                   </b-col>
                  <b-col md="1">
                      <b-button
                
                style="background: transparent; border: none;margin-left:-20px"
                variant="primary"
                @click="addUserLocation"
              >
                <font-awesome-icon class="iconTable" :icon="['fas', 'plus']"
              /></b-button>
                     </b-col>

              </b-row>
              
             
            </b-form-group>
             <b-form-group label="Agregar almacén:" v-if="mobile==true">
                  <b-select id="perPageSelectStocks" style="width:295px"  v-model="id_location"  :options="listLocation" value-field="id" text-field="name" > 
                <template #first>
                  <b-form-select-option   :value="null">Seleccione un almacén</b-form-select-option>
                </template>
              </b-select>
              <span> <b-button
                
                style="background: transparent; border: none;margin-left:-3px"
                variant="primary"
                @click="addUserLocation"
              >
                <font-awesome-icon class="iconTable" :icon="['fas', 'plus']"
              /></b-button></span>

              
             
            </b-form-group>
          </b-col>
        </b-row>
         <b-row class="mt-3 px-3">
          <b-col sm="12">
            <h6>Almacenes asignados al usuario</h6>
            <div>
              <b-table responsive
                  id="my-table"
                  show-empty
                  small
                  stacked="md"
                  sticky-header="true"
                  hover
                  :primary-key="id"
                  :items="usersTMP"
                  :fields="field"
                  empty-text="No hay datos disponibles"
              >
                <!--                :filter="filter"-->
                <thead >
                <tr>
                  <th></th>
                     <th></th>
               

                </tr>
                </thead>
                <template v-slot:table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Cargando ...</strong>
                  </div>
                </template>
                  <template v-slot:cell(city)="row">
                  <p >{{ row.item.city_residence.name }} ({{ row.item.country_residence.name }} ) </p>
                </template>
                <template v-slot:cell(fecha)="row">
                  <p>{{dateFormat(row.item.created_at)}} </p>
                </template>
                
                <template v-slot:cell(actions)="row">


                  <button
                      size="sm"
                      class="primary2"
                      @click="delete_UserLocation(row)"
                      title="Eliminar"
                  >

                    <font-awesome-icon class="iconTable" icon="trash" style="color: red!important;" title="Eliminar"/>
                  </button>
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col sm="12">
            <div>
              
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" style="text-align: center;">
            <br>
            <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span>
          </b-col>
          <b-col md="4"></b-col>
          <b-col md="4">
                  <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
            <b-col md="5"></b-col>
          </b-row>
              <b-button block class="primary mt-4" v-on:click="onSubmit()" >Modificar</b-button>
          
          </b-col><!--v-on:click="add_element()"-->
        </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";


export default {
  name: "UpdateUser",
  data() {
    return {
      id:null,
      validateForm:true,
      full_name:null,
      email:null,
      emailPassword:null,
      password:null,
      id_rol:null,
      listRoles:[],
      listLocation:[],
      users: [],
      usersTMP: [],
      id_location:null,
      variable:"password",
      variable2:"password",
      progress: false,
      btnLogin: false,
      mobile:false,
      tablet:false,
      confirm_password:null,
      field: [
     
        {
          key: "location.name",
          label:"Almacén",
          sortable: true,
        },
        
         {
          key: "actions",
          label: "Acciones",
          sortable: false
        }

   
      ],
      
    
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  mounted() {

      if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    setTimeout(() => {
      //document.getElementById("adminUsers").classList.add("router-link-active");
      this.id_role = this.listRoles[0].id;
    }, 500);

    this.allLocations();
   
     this.allRoles();
    this.consult();
     this.allUserLocation();
    
  },
  methods: {
    ...mapActions(["modify_user","consult_user","loadRoles","loadLocation","loadUserLocation","delete_userlocation","add_userlocation"]),
      validateAllForm(){
       
        if(this.full_name == null ||this.email == null){
          this.validateForm =true;
          return true
        }else{
          if(this.full_name==''||this.full_name==' '||this.full_name=='  ' || this.email==''||this.email==' '||this.email=='  '){
            this.validateForm =false;
            return true
          }
          this.validateForm =false;
          return false
        }
      },
      validateInput(val,type ='text'){
        if(type == 'email'){
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if(val != null && !pattern.test(val)){
            return true
          }else if(val==''||val==' '||val=='  '){ 
            return true
          }else{
            return false
          }
        }
      },
        cancelP() {
      this.password = null;
      this.confirm_password = null;
      this.$refs["modal"].hide();
    },
    allUserLocation(){
      this.isBusy = true;
      /*let form = {
        active:'all'
      }*/
      this.loadUserLocation(this.$route.params.id)
          .then(response => {
            if (response.code === 200) {
              this.isBusy = false;
              this.users = response.data;
              this.usersTMP = response.data;
            }
          })
          .catch(error => {
            console.log(error)
          });

    },
      onSubmit() {
        this.progress= true;
        if(!this.validateAllForm()){
             this.add_element();
        }
      },
      allLocations() {
        this.isBusy = true;
       let query = "?active=1";

        this.loadLocation(query)
          .then((response) => {
            if (response.code === 200) {
              this.isBusy = false;
              this.listLocation = response.data;
                    this.listLocation=this.listLocation.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
              
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      showPassword() {
      if (this.variable == "password") {
        this.variable = "text";
      } else {
        this.variable = "password";
      }
    },
    showPassword2() {
      if (this.variable2 == "password") {
        this.variable2 = "text";
      } else {
        this.variable2 = "password";
      }
    },
    addUserLocation() {
       
      if (
      this.id_location==null 
      ) {
        Swal.fire({
          title: "Almacén no seleccionado",
          text: "Por favor , selecciona el almacén que deseas agregar",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        return;
      }

     
       let form = {
         id_user:this.$route.params.id,
         id_location: this.id_location

       };

      this.add_userlocation(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro exitoso",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.allUserLocation();
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "El email ya existe",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
            if (error.code == 409) {
            Swal.fire({
              title: "Almacén duplicado",
               text: "este almacén ya ha sido asignado a este usuario",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.id_locarion=null;
              }
            });
          }
        });
    },
    updatePassword() {
      if (
        this.password == "" ||
        this.password == undefined ||
        this.password == null
      ) {
        Swal.fire({
          title: "Completa los campos",
          text: "Por favor ingresa todos los campos",
          icon: "warning",
          confirmButtonColor: "#00373B",
        });
      } else if (this.password !== this.confirm_password) {
        Swal.fire({
          title: "Contraseñas no coinciden",
          text: "Por favor asegúrate de que las contraseñas sean iguales",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: this.$i18n.t("btnok"),
        });
      } else {
        let form = {
          id: this.$route.params.id,
          password: this.password,
          email: this.emailPassword,
          password_confirmation: this.confirm_password,
        };
        this.modify_user(form)
          .then((response) => {
            if (response.code == 200) {
              this.progress = false;
              Swal.fire({
                title: "Contraseña modificada",
                icon: "success",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.value) {
                  location.reload();
                  this.password = null;
                  this.confirm_password = null;
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: this.$i18n.t("error_catch"),
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "OK",
            });
            console.log(error);
          });
      }
    },
       allRoles() {
      this.isBusy = true;
      let query="?active=1"
      this.loadRoles(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listRoles = response.data;
              this.listRoles=this.listRoles.sort(function(a, b){ 
              if(a.name<b.name){
                return -1;
              }else if(a.name>b.name){
                return 1;
              }else{
                return 0;
              }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
     delete_UserLocation(row) {
      Swal.fire({
        title: "¿Desea eliminar esta asignacion de almacén?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
          };
          this.delete_userlocation(form)
              .then(response => {
                if (response.code === 200) {
                  this.users.splice(row.index, 1);
                  Swal.fire({
                    title:"Elemento eliminado",
                    confirmButtonColor: "#00373B",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
      consult() {
        let form = {
          id: this.$route.params.id,
        };
        this.consult_user(form)
          .then((response) => {
            if (response.code == 200) {
              this.full_name = response.data.full_name;
              this.email = response.data.email;
              this.emailPassword = response.data.email;
             // this.id_location = response.data.id_location;
              this.id_rol = response.data.id_rol;
              
            }
          })
          .catch((error) => {
            // Swal.fire({
            //   title: this.$i18n.t("error_catch"),
            //   icon: "warning",
            //   reverseButtons: true,
            //   confirmButtonColor: "#AE0B25",
            //   confirmButtonText: this.$i18n.t("btnok"),
            // });
            console.log(error);
          });
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
    add_element() {
      if (
        this.full_name == null || this.email==null || this.id_rol==null ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
         this.progress= false;
        return;
      }else if (!this.email.includes('@')) {
        Swal.fire({
          title:"Error en el email",
          text: "Por favor verifica tu email",
          icon: 'warning',
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok"
        });
         this.progress = false;
          return;
      }
      
       let form = {
         id: this.$route.params.id,
         full_name: this.full_name,
         email: this.email,
         password: this.password,
         id_rol: this.id_rol

       };

   

      this.modify_user(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Modificación exitosa",
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/usuarios/listado");
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "El email ya existe",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
           if (error.code == 409) {
            Swal.fire({
              title: "Email duplicado",
              text: "el email indicado, ya se encuentra en uso",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.name="";
              }
            });
          }
        });
    },
  },
};
</script>

<style>
.validateInput{
    border: 1px solid #dd137b!important;
    color: #dd137b!important;
    box-shadow: 0 0 0 1px!important;
}
.validateMessage{
    color: #dd137b!important;
}
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>
