<template>
  <div id="Transfer">
    <br />
    <!-- <br v-show="loading"/>
         <br v-show="loading"/>
          <br v-show="loading"/> -->
    <!-- <div v-show="loading" class="d-flex justify-content-center mb-3">
       
    <b-spinner style="width: 3rem; height: 3rem;"  v-show="loading" label="Cargando"></b-spinner>
  </div> -->
    <!-- <br v-show="loading"/>
   <div v-show="loading" class="d-flex justify-content-center mb-3">
    <p v-show="loading">Por favor espere un momento, mientras cargan los datos correspondientes</p>
  </div> -->
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
          <h5>Stock general</h5>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button
            block
            class="primary3"
            :to="{ name: 'admin-stocks-alltransfer' }"
          >
            Volver atrás</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="10" style="text-align: -webkit-left">
          <p style="text-align: -webkit-left">Transferencia de stock</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Almacén que envia:">
            <b-select
              id="perPageSelectStocks"
              v-model="id_stock_base"
              :options="listStocks"
              value-field="id"
              text-field="name"
              @change="allElements(1)"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >Seleccione un almacén</b-form-select-option
                >
              </template>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Almacén que recibe:">
            <b-select
              id="perPageSelectStocks"
              v-model="id_stock"
              :options="listStocks2"
              value-field="id"
              text-field="name"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >Seleccione un almacén</b-form-select-option
                >
              </template>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Materiales: ' + element_count">
            <!-- <v-select style="height:30px" placeholder="Seleccione un material" id="perPageSelectStocks" label="name" value="id"  v-model="stock_selected"  :options="listMaterials">
               <template v-slot:no-options>Lo sentimos, no hay opciones disponibles</template>
                </v-select> -->
            <b-form-group
              label-cols-sm="2"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-input-group-append>
                  <button class="primary2">
                    <font-awesome-icon
                      class="iconTable"
                      :icon="['fas', 'search']"
                      title="Busqueda"
                    />
                  </button>
                </b-input-group-append>

                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  v-on:keyup="allElements(1)"
                  type="text"
                  placeholder="Buscar material"
                />

                <b-input-group-append>
                  <button
                    class="primary2"
                    :disabled="!filter"
                    @click="clearFilter"
                  >
                    <font-awesome-icon
                      class="iconTable"
                      :icon="['fas', 'times']"
                      title="Limpiar busqueda"
                    />
                  </button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <br />
            <div>
              <b-table
                style="height:400px"
                responsive
                id="my-table"
                small
                stacked="sm"
                sticky-header="true"
                hover
                :primary-key="id"
                :items="elementsTMP2"
                :fields="field2"
                :per-page="perPage2"
                :filter-included-fields="filterOn2"
                :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2"
                :sort-direction="sortDirection2"
                empty-text="No hay materiales disponibles"
                :busy="isBusy"
              >
                <template v-slot:cell(actions)="row">
                  <b-button
                    size="sm"
                    class="primary"
                    title="Activado"
                    @click="selectMaterial(row.item)"
                  >
                    <v-icon x-large name="angle-right"></v-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
            <p style="margin-top:8px">
              <span
                ><strong>Material Seleccionado:</strong>
                {{ stock_selected.material.name }} </span
              ><br /><br />
              <span
                ><strong
                  >Cantidad disponible en almacén:
                </strong></span
              >
              <span style="font-size:25px"
                >{{ stock_selected.quantity
                }}<strong v-if="stock_selected != null">
                  {{ stock_selected.material.unity.name }}</strong
                ><strong v-if="stock_selected == null">0</strong></span
              >
            </p>
          <b-form-group label="Cantidad a transferir:">
            <span
              v-show="validateInput(this.capacity, 'text')"
              v-bind:class="{
                validateMessage: validateInput(this.capacity, 'text'),
              }"
              >La cantidad es requerida</span
            >

          
            <b-input-group size="md" >
              <b-form-input
                v-model="capacity"
                type="number"
                v-bind:class="{
                  validateInput: validateInput(this.capacity, 'text'),
                }"
                placeholder="Cantidad a transferir"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Nota:">
            <b-input-group size="md">
              <b-form-input
                v-model="note"
                placeholder="Nota del material"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row>
      <b-row>
        <b-col md="12" style="text-align: center;">
          <br />
          <!-- <span v-show="this.validateAllForm()" class="validateMessage">Rellene el formulario para continuar con el registro...</span> -->
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="4">
          <b-row>
            <b-col md="5"></b-col>
            <b-col md="2" style="text-align: center">
              <b-spinner
                variant="primary"
                v-show="progress"
                label="Text Centered"
              ></b-spinner>
            </b-col>
          </b-row>
          <b-button
            v-show="!this.validateAllForm()"
            block
            class="primary mt-4"
            v-on:click="onSubmit()"
            >Transferir stock</b-button
          >
          <b-button
            v-show="this.validateAllForm()"
            disabled
            block
            class="secondary mt-4"
            >Transferir stock</b-button
          >
        </b-col>
      </b-row>
      <br />
      <b-row class="mt-3 px-3">
        <h6><strong>Materiales transferidos recientemente</strong></h6>
        <br />
        <b-col sm="12">
          <div>
            <b-table
              responsive
              id="my-table"
              show-empty
              fixed
              small
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="save"
              :fields="field"
              :current-page="currentPage"
              :per-page="perPage"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :busy="isBusy"
              empty-text="No hay datos disponibles"
            >
              <!--                :filter="filter"-->

              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div></div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Cookies from "js-cookie";
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}
export default {
  name: "Transfer",
  data() {
    return {
      loading: true,
      validateForm: true,
      istStocks2: [],
      listStocks: [],
      listStocks2: [],
      id_stock: null,
      id_stock_base: null,
      stock_selected: {
        material: {
          name: "",
          unity: {
            name: "",
          },
        },
      },
      listMaterials: [],
      capacity: null,
      note: null,
      progress: false,

      perPage2: 4,
      currentPage2: 1,
      lastPage2: 1,
      totalRows2: 1,
      pageOptions2: [10, 20, 50, 100],
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      elements2: [],
      elementsTMP2: [],
      element_count: 0,
      save: [],
      field: [
        {
          key: "destinatario",
          label: "Almacén que envia",
          sortable: true,
        },
        {
          key: "receptor",
          label: "Almacén que recibe",
          sortable: true,
        },

        {
          key: "material",
          label: "Material",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Cantidad",
          sortable: true,
        },

        {
          key: "notes",
          label: "Nota",
          sortable: true,
        },
      ],
      field2: [
        {
          key: "material.code",
          label: "Código",
          sortable: true,
        },
        {
          key: "material.name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "actions",
          label: "Seleccionar",
          sortable: false,
        },
      ],
      id: "",
      isBusy: false,
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
  },
  updated() {},
  mounted() {
    this.allLocations();

    //  this.allMaterials();
    // setTimeout(() => {
    //   this.id_stock = this.listStocks[0].id
    //   this.stock_selected = this.listMaterials[0].id
    // }, 500);
  },
  methods: {
    ...mapActions([
      "loadStock",
      "transfer_stock",
      "loadLocation",
      "loadMaterial",
      "loadStockByQuantity",
      "loadStockPaginate",
    ]),
    selectMaterial(material) {
      console.log(material);
      this.stock_selected = material;
    },
    validateAllForm() {
      if (this.capacity == null) {
        this.validateForm = true;
        return true;
      } else {
        if (
          this.capacity == "" ||
          this.capacity == " " ||
          this.capacity == "  "
        ) {
          this.validateForm = false;
          return true;
        }
        this.validateForm = false;
        return false;
      }
    },
    validateInput(val, type = "text") {
      if (type == "text") {
        if (val == "" || val == " " || val == "  ") {
          this.validateForm = false;
          return true;
        } else {
          return false;
        }
      }
    },
    searchInput() {
      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.elements.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        console.log(elementsTMP);
        this.listMaterials = elementsTMP;
        this.element_count2 = this.listMaterials.length;
      } else {
        console.log("Without");
        this.listMaterials = this.elements;
        this.element_count2 = this.listMaterials.length;
      }
      return true;
    },
    clearFilter() {
      this.elementsTMP2 = this.elements;
      this.filter = "";
      return true;
    },
    allElements(page) {
      this.stock_selected= {
        material: {
          name: "",
          unity: {
            name: "",
          },
        },
      };
      this.isBusy = true;

      if (!page) {
        page = this.page;
      }
      let form = {
        page: page,
        id_location: this.id_stock_base,
        active:1
      };
      if (this.filter) {
        form.query = this.filter;
      }
      this.loadStockPaginate(form)
        .then((response) => {
          if (response.code === 200) {
            this.elements2 = response.data.data;

            this.isBusy = false;

            this.elements2 = response.data.data;
            this.elementsTMP2 = response.data.data;
            this.element_count = this.elements2.length;

           // this.element_count = response.data.meta.total;
            this.perPage2 = response.data.meta.per_page;
            this.currentPage2 = page;
            this.lastPage2 = response.data.meta.last_page;

            //location.type
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allLocations() {
      this.isBusy = true;
      let query = "?active=1";
      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listStocks = response.data;
            this.listStocks = this.listStocks.sort(function(a, b) {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            });
            this.listStocks2 = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    allMaterials() {
      this.isBusy = true;
      let query = "?active=1&lite=1";
      this.loadMaterial(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listMaterials = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterLocation() {
      this.listMaterials = [];
      this.stock_selected = null;

      this.listStocks2 = this.listStocks.filter(
        (x) => x.id !== this.id_stock_base
      );
     let form={
        order_key:'id',
        order_value:'desc',
        active:'all'
      }
      this.loadStock(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.elements = response.data.filter(
              (x) => x.id_location === this.id_stock_base
            );

            this.elements.forEach((element) => {
              let material = "";
              if (element.quantity > 0) {
                material = {
                  id: element.material.id,
                  code: element.material.code,
                  name: element.material.name,
                  unity: element.material.unity.name,
                  quantity: element.quantity,
                };

                this.listMaterials.push(material);
              }
            });
            this.listMaterials = this.listMaterials.sort(function(a, b) {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit() {
      if (!this.validateAllForm()) {
        this.add_element();
      }
    },
    add_element() {
      this.progress = true;
      if (
        this.id_stock == null ||
        this.stock_selected == null ||
        this.capacity == null
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
      if (this.id_stock_base == this.id_stock) {
        Swal.fire({
          title: "No puedes transferir al mismo almacén",
          text: "Asegúrate de que ambos almacenes sean diferentes",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }
        if (parseInt(this.capacity)<=0) {
        Swal.fire({
          title: "No puedes transferir 0 unidades",
          text: "Asegúrate de que la cantidad sea mayor a 0",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        this.progress = false;
        return;
      }

      let form1 = {
        id_material: this.stock_selected.id_material,
        id_location_base: this.id_stock_base,
      };
  let material_name = this.stock_selected.material.name;
              let quantity = this.capacity;
              let note = this.note;
              console.log(material_name);
      this.loadStockByQuantity(form1)
        .then((response) => {
          if (response.code === 200) {
            if (response.data[0].quantity >= this.capacity) {
              let form = {
                id_material: this.stock_selected.id_material,
                id_location_base: this.id_stock_base,
                id_location_destiny: this.id_stock,
                quantity: parseInt(this.capacity),
                id_user: parseInt(Cookies.get("id_user")),
                notes: this.note,
              };
            
              this.transfer_stock(form)
                .then((response) => {
                  if (response.code == 200) {
                     this.progress = false;
                    Swal.fire({
                      title: "Transferencia exitosa",

                      icon: "success",
                      reverseButtons: true,
                      confirmButtonColor: "#00373B",
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.value) {
                       

                        //this.$router.push("/stocks/listado");
                        let stock_base = this.listStocks;
                        let stock = this.listStocks2;
                        let formNew = {
                          material: material_name,
                          destinatario: stock_base[0].name,
                          receptor: stock[0].name,
                          quantity: quantity,
                          notes: note,
                        };
                        this.save.push(formNew);
                        this.stock_selected = "";
                        this.capacity = null;
                        this.note = "";
                        this.filter=null;
                        this.stock_selected={
                          material: {
                            name: "",
                            unity: {
                              name: "",
                            },
                          },
                        };
                        this.allElements(1);
                      }
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if (error.code == 408) {
                    Swal.fire({
                      title: "No hay suficiente cantidad en el inventario base",
                      icon: "warning",
                      reverseButtons: true,
                      confirmButtonColor: "#00373B",
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.value) {
                        this.progress = false;
                        this.capacity = "";
                      }
                    });
                  }
                  if (error.code == 409) {
                    Swal.fire({
                      title: "No existe material en el almacén base",
                      icon: "warning",
                      reverseButtons: true,
                      confirmButtonColor: "#00373B",
                      confirmButtonText: "Ok",
                    });
                    this.progress = false;
                  }
                });
            } else {
              Swal.fire({
                title: "No hay suficiente cantidad en el almacén que envia",
                icon: "warning",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "Ok",
              });
              this.progress = false;
            }
          }
        })
        .catch((error) => {
          this.progress = false;
          Swal.fire({
            title: "No existe información para dicho material y almacén",
            icon: "error",
            reverseButtons: true,
            confirmButtonColor: "#00373B",
            confirmButtonText: "Ok",
          });
          console.log(error);
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373b !important;
  border-color: #00373b !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}
</style>
