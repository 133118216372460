<template>
  <div id="Reports">
    <br />
    <div class="content">
      <b-row>
        <b-col md="12" style="text-align: -webkit-left">
          <h5>Reportes</h5>
        </b-col>
      </b-row>
      <br />

      <b-row>
        <b-col md="6" style="text-align: -webkit-left">
          <b-card style="height:400px">
            <h4><strong>Exportar</strong></h4>
            <br />
            <p style="text-align: -webkit-left">
              Podrás descargar el stock en formato XLSX (Excel)
            </p>
            <b-input-group-append>
              <b-select
                id="perPageSelectRoles"
                v-model="location"
                @change="exportStock()"
                :options="listLocation"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Seleccione un almacén</b-form-select-option
                  >
                </template>
              </b-select>
              <button class="primary2" @click="clearFilter">
                <font-awesome-icon
                  class="iconTable"
                  :icon="['fas', 'times']"
                  title="Limpiar busqueda"
                />
              </button>
            </b-input-group-append>
            <br />
            <b-button
              :disabled="progress"
              :href="link"
              download
              block
              class="primary"
            >
              Descargar stock del almacén
            </b-button>

            <b-row>
              <b-col md="4"></b-col>
              <b-col md="4">
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col md="2" style="text-align: center">
                    <b-spinner
                      variant="primary"
                      v-show="progress"
                      label="Text Centered"
                    ></b-spinner>
                  </b-col>
                  <b-col md="5"></b-col>
                </b-row> </b-col
              ><!--v-on:click="add_element()"-->
              <b-col md="4"> </b-col
              ><!--v-on:click="add_element()"-->
            </b-row>
          </b-card>
        </b-col>
        <br v-if="mobile" />
        <b-col md="6" style="text-align: -webkit-left">
          <b-row>
            <b-card v-if="id_rol == 1" style="height:400px">
              <h4><strong>Importar stock a nuevo almacén</strong></h4>
              <br v-if="!tablet"/>
              <p style="text-align: -webkit-left">
                Podrás subir el stock en formato XLSX (Excel),
              </p>
              <p style="text-align: -webkit-left">Podrás importar el Stock solo a almacenes nuevos que no posean ningún artículo disponible.
              </p>
              <b-input-group-append>
                <b-select
                  id="perPageSelectRoles"
                  v-model="location2"
                  :options="listLocation"
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >Seleccione un almacén</b-form-select-option
                    >
                  </template>
                </b-select>
                <button class="primary2" @click="clearFilter2">
                  <font-awesome-icon
                    class="iconTable"
                    :icon="['fas', 'times']"
                    title="Limpiar busqueda"
                  />
                </button>
              </b-input-group-append>
              <br />

              <div class="col-sm-12">
                <div class="row">
                  <b-form-file
                    browse-text="Buscar"
                    accept=".xlsx, .xls, .csv"
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Archivo"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </div>
                <br />
                <div class="row">
                  <b-button
                    class="primary btn-md"
                    style="margin-right: 5px"
                    @click="importStock"
                  >
                    Subir stock de materiales</b-button
                  ><span></span>
                </div>
              </div>

              <b-row>
                <b-col md="4"></b-col>
                <b-col md="4">
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align: center">
                      <b-spinner
                        variant="primary"
                        v-show="progress2"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row> </b-col
                ><!--v-on:click="add_element()"-->
                <b-col md="4"> </b-col
                ><!--v-on:click="add_element()"-->
              </b-row>
            </b-card>
          </b-row>
        </b-col>
        <br/>
        
        <b-col md="6" style="text-align: -webkit-left">
          <br/>
          <b-card v-if="id_rol == 1" style="height:400px">
            <h4><strong>Importar Nuevos Materiales</strong></h4>
            <br />
            <p style="text-align: -webkit-left">
              Podrás subir el stock en formato XLSX,
            </p>
            <p style="text-align: -webkit-left">
             Precaución. Si indicas códigos incorrectos, el sistema podría crear un material nuevo, debido que reconocerá el código erróneo como un nuevo material.
            </p>

            <br />
             <div class="col-sm-12">
            <b-row>
              <b-form-file
               browse-text="Buscar"
                accept=".xlsx, .xls, .csv"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Archivo"
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-row>
            <br />
            <b-row>
              <b-button
                class="primary btn-md"
                style="margin-right: 5px"
                @click="importMaterials"
              >
                Subir materiales
              </b-button></b-row
            ><span></span>
</div>
<br/>
            <b-row>
              <b-col md="4"></b-col>
              <b-col md="4">
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col md="2" style="text-align: center">
                    <b-spinner
                      variant="primary"
                      v-show="progress4"
                      label="Text Centered"
                    ></b-spinner>
                  </b-col>
                  <b-col md="5"></b-col>
                </b-row> </b-col
              ><!--v-on:click="add_element()"-->
              <b-col md="4"> </b-col
              ><!--v-on:click="add_element()"-->
            </b-row>
          </b-card>

        </b-col>

         <b-col md="6" style="text-align: -webkit-left">
          <br/>
          <b-card v-if="id_rol == 1" style="height:400px">
            <h4><strong>Descargar listado de materiales</strong></h4>
            <br />
            <p style="text-align: -webkit-left">
              Descarga toda la lista de materiales cargados al sistema en formato XLSX (Excel)
            </p>
           

            <br />
             <div class="col-sm-12">
          
            <br />
            <b-row>
              <b-button
                class="primary btn-md"
                style="margin-right: 5px"
                @click="downloadMaterials"
              >
                Generar listado de materiales
              </b-button>
             </b-row
            ><span></span>
</div>
 <br />
  <b-row>
              <b-col md="4"></b-col>
              <b-col md="4">
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col md="2" style="text-align: center">
                    <b-spinner
                      variant="primary"
                      v-show="progress3"
                      label="Text Centered"
                    ></b-spinner>
                  </b-col>
                  <b-col md="5"></b-col>
                </b-row> </b-col
              ><!--v-on:click="add_element()"-->
              <b-col md="4"> </b-col
              ><!--v-on:click="add_element()"-->
            </b-row>
            <b-row>
                <br>
                  <br>
              <a :href="link" v-if="link">Reporte completado. Presiona aquí para descargar el listado de materiales.</a>
             
              <b-col md="4"></b-col>
              <b-col md="4">
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col md="2" style="text-align: center">
                    <b-spinner
                      variant="primary"
                      v-show="progress2"
                      label="Text Centered"
                    ></b-spinner>
                  </b-col>
                  <b-col md="5"></b-col>
                </b-row> </b-col
              ><!--v-on:click="add_element()"-->
              <b-col md="4"> </b-col
              ><!--v-on:click="add_element()"-->
            </b-row>
          </b-card>

        </b-col>
      </b-row>
      <br/>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import Cookies from "js-cookie";
//import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Reports",
  data() {
    return {
      mobile: false,
      location: null,
      location2: null,
      progress: false,
      progress2: false,
      progress3: false,
      progress4: false,
      tablet:false,
      id_rol: Cookies.get("id_rol"),
      listLocation: [],
      file: [],
      items: [
        {
          text: "Descargas",
        },
      ],
      link: "",
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),

    rows() {
      return this.elementsTMP.length;
    },
  },
  mounted() {
       if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }

    this.allLocations();
  },
  methods: {
    ...mapActions([
      "loadLocation",
      "export_stock",
      "import_stock",
      "import_materials",
      "export_materials"
    ]),
    moment: function() {
      return moment();
    },
    clearFilter() {
      this.location = null;
      this.progress = false;
      return true;
    },
    clearFilter2() {
      this.location2 = null;
      return true;
    },
    downloadMaterials() {
      this.progress3 = true;

     
      this.export_materials()
        .then((response) => {
          if (response.code === 200) {
            this.progress3 = false;

            this.link = response.data.url_file;
           
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    importStock() {
      this.progress2 = true;
      let data = new FormData();
      data.append("id", this.location2);
      data.append("file", this.file);
      if (!this.location2) {
        Swal.fire({
          title: "Debes seleccionar un almacén",
          text:
            "Asegúrate de indicar un almacén, para poder realizar la importación",
          icon: "error",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
         this.progress2 = false;
           this.isBusy = false;
      } else {
        this.import_stock(data)
          .then((response) => {
            if (response.code === 200) {
               this.isBusy = false;
              this.progress2 = false;
              Swal.fire({
                title: "Importación exitosa",

                icon: "success",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  this.cancelP2();
                }
              });
            }
            if (response.code === 403) {
              this.isBusy = false;
              this.progress2 = false;
              Swal.fire({
                title: "Error",
                text:
                  "El almacén ya posee items en stock, debe agregarle items mediante transferencia, la importacion solo funciona para almacenes nuevos que no posean stock en el sistema",
                icon: "error",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  this.cancelP2();
                }
              });
            }
            if (response.code === 404) {
              this.isBusy = false;
              this.progress2 = false;
              let code_product = response.data;
              Swal.fire({
                title: "Código de producto no encontrado: " + code_product,
                text:
                  "Un producto en el archivo subido posee un código inexistente. Por favor comprueba los datos importados e intenta nuevamente.",
                icon: "error",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  this.cancelP2();
                }
              });
            }
          })
          .catch((error) => {
              this.progress2 = false;
           this.isBusy = false;
            console.log(error);
           
            Swal.fire({
              title: "Error",
              icon: "error",
              confirmButtonColor: "#00373B",
            });
          });
        this.cancelP2();
        this.location2 = null;
        this.file = "";
      }
    },
    importMaterials() {
      this.progress4 = true;
      let data = new FormData();

      data.append("file", this.file);

      this.import_materials(data)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
              this.progress4 = false;
            Swal.fire({
              title: "Importación exitosa",

              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.cancelP2();
              }
            });
          }
          if (response.code === 403) {
            this.isBusy = false;
              this.progress2 = false;
            Swal.fire({
              title: "Error",
              text:
                "El almacén ya posee items en stock, debe agregarle items mediante transferencia, la importacion solo funciona para almacenes nuevos que no posean stock en el sistema",
              icon: "error",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.cancelP2();
              }
            });
          }
          if (response.code === 404) {
            this.isBusy = false;
              this.progress2 = false;
        
            let code_product = response.data;
            Swal.fire({
              title: "Código de producto no encontrado: " + code_product,
              text:
                "Un producto en el archivo subido posee un código inexistente. Por favor comprueba los datos importados e intenta nuevamente.",
              icon: "error",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.cancelP2();
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
            this.progress2 = false;
           this.isBusy = false;
          
          Swal.fire({
            title: "Error",
            icon: "error",
            confirmButtonColor: "#00373B",
          });
        });
      this.cancelP2();
      this.location2 = null;
      this.file = "";
    },
    exportStock() {
      this.progress = true;

      let form = {
        id_location: this.location,
      };
      this.export_stock(form)
        .then((response) => {
          if (response.code === 200) {
            this.progress = false;

            this.link = response.data.url_file;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allLocations() {
      this.isBusy = true;
      let query = "?active=1";

      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listLocation = response.data;
            this.listLocation = this.listLocation.sort(function(a, b) {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInput() {
      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.elements.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.elementsTMP = elementsTMP;
      } else {
        this.elementsTMP = this.elements;
      }
      return true;
    },
    admin() {
      let form = {
        admin_id: parseInt(localStorage.getItem("id_admin")),
      };

      this.adminByID(form)
        .then((response) => {
          if (response.code == 200) {
            //  if(response.data.territories_managed.length>0){
            // this.listCountries=response.data.countries_managed;
            // }

            if (response.data.countries_managed.length > 0) {
              let countries = [];
              for (var i = 0; i < response.data.countries_managed.length; i++) {
                countries.push(response.data.countries_managed[i]);
                this.listCountries = countries;
              }
            }

            if (response.data.territories_managed.length > 0) {
              // let  cities=[];
              let countries = [];
              let cities = [];
              for (
                var x = 0;
                x <= response.data.territories_managed.length;
                x++
              ) {
                console.log(
                  "Response",
                  response.data.territories_managed[x].countries_with_cities
                );
                //  cities.push(response.data.territories_managed[i].countries_with_cities)
                // this.listCities=cities;
                // this.country_residence=

                for (
                  var p = 0;
                  p <
                  response.data.territories_managed[x].countries_with_cities
                    .length;
                  p++
                ) {
                  countries.push(
                    response.data.territories_managed[x].countries_with_cities[
                      p
                    ]
                  );
                  this.listCountries = countries;
                  for (
                    var y = 0;
                    y <
                    response.data.territories_managed[x].countries_with_cities[
                      p
                    ].cities.length;
                    y++
                  ) {
                    cities.push(
                      response.data.territories_managed[x]
                        .countries_with_cities[p].cities[y]
                    );
                  }
                  this.listCitiesTerri = cities;
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allElements() {
      this.isBusy = true;
      let form = {
        id: parseInt(localStorage.getItem("id_admin")),
      };

      this.loadCampusByAdmin(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.elements = response.data;
            this.elementsTMP = response.data;
            this.element_count = this.elements.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_element(data) {
      this.$router.push("/campus/view/" + data.id);
    },
    hideModal() {
      this.$refs["detail-user-modal"].hide();
    },
    cancelP2() {
      this.$refs["modal2"].hide();
      this.progress2 = false;
    },
    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "Desactivar" : "Activar";
      let title2 = data.active == 1 ? "Desactivado" : "Activado";

      Swal.fire({
        title: title,
        text: "¿ Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active,
          };
          this.change_active_campus(form)
            .then((response) => {
              if (response.code === 200) {
                data.active = active;
                Swal.fire({
                  title: title2,
                  icon: "success",
                });
                this.allElements();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    allCampusByDirector() {
      var id_admin = parseInt(localStorage.getItem("id_admin"));
      this.isBusy = true;
      let form = {
        id_director: id_admin,
      };
      this.loadCampusByDirector(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.elements = response.data;
            this.elementsTMP = response.data;
            this.element_count = this.users.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delete_element(row) {
      Swal.fire({
        title: "Eliminar elemento",
        text: "¿Estas seguro de realizar esta acción?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2,
          };
          this.delete_campus(form)
            .then((response) => {
              if (response.code === 200) {
                this.elements.splice(row.index, 1);
                Swal.fire({
                  title: "Elemento eliminado",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>


/*input*/
.fileUpload {
  position: relative !important;
  overflow: hidden !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.fileUpload input.upload {
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.imgStyles {
  width: 110px !important;
  height: 110px !important;
  object-fit: scale-down !important;
  filter: drop-shadow(rgba(221, 19, 123, 0.5) 2px 2px 3px) !important;
  border-radius: 50% !important;
  text-align: center !important;
}
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373b !important;
  border-color: #00373b !important;
}

.input-group-md > .form-control:not(textarea),
.input-group-md {
  height: calc(1.5em + 1rem + 2px);
  font-weight: 900 !important;
  color: black;
}

::placeholder {
  font-weight: 100 !important;
}
</style>
