<template>
  <div>
    <br />
    <div class="content" style="padding-bottom: 50px;">
      <h2 class="text21">Bienvenidos al Panel de Control de Arbibe</h2>
      <br />
       <br />
       <div class="row" v-show="!mobile && !tablet">
        <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
        <router-link :to="{ name: 'admin-users' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="usuarios" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220405_151423_VDCDwz_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.users}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Usuarios</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
        </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-materials-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Materiales" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101216_Bd1ZMa_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.materials}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Materiales</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link  :to="{ name: 'admin-warehouses-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Almacenes" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101310_aydGF9_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.locations}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Almacenes</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-projects-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Proyectos" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//2022-03-04_020510-SNWvYw3M-774531.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.projects}}</h2>
              <p style="font-size:16px; text-align:center;color:#000000">Proyectos</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
              style="max-width: 15rem;height:270px"
              img-top title="Operaciones de entrada" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220311_142413_e38S3D_image.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.entry_operation}}</h2>
              <p style="font-size:16px;padding-bottom:40px;text-align:center;color:#000000">Operaciones de entrada</p>
              
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
            style="max-width: 15rem;height:270px"
            img-top title="Transferencias de Material" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220401_173249_9vgE2L_image.png?authuser=6"></b-card-img>
            <br />
            <b-card-text >
              <h2 class="number">{{elements.material_transfers}}</h2>
              <p style="font-size:16px;padding-bottom:20px;text-align:center;color:#000000">Transferencias de Material</p>
            </b-card-text>
          </b-card>
        </router-link>
         </div>
        </div>
        <div class="row" v-show="!mobile && tablet">
        <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
        <router-link :to="{ name: 'admin-users' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="usuarios" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220405_151423_VDCDwz_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.users}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Usuarios</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
        </div>
         <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-materials-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Materiales" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101216_Bd1ZMa_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.materials}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Materiales</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
         <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link  :to="{ name: 'admin-warehouses-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Almacenes" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101310_aydGF9_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.locations}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Almacenes</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
        </div>
        <div class="row" v-show="!mobile && tablet">
        <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-projects-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Proyectos" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//2022-03-04_020510-SNWvYw3M-774531.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.projects}}</h2>
              <p style="font-size:16px; text-align:center;color:#000000">Proyectos</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
              style="max-width: 15rem;height:270px"
              img-top title="Operaciones de entrada" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220311_142413_e38S3D_image.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.entry_operation}}</h2>
              <p style="font-size:16px;padding-bottom:40px;text-align:center;color:#000000">Operaciones de entrada</p>
              
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-4" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
            style="max-width: 15rem;height:270px"
            img-top title="Transferencias de Material" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220401_173249_9vgE2L_image.png?authuser=6"></b-card-img>
            <br />
            <b-card-text >
              <h2 class="number">{{elements.material_transfers}}</h2>
              <p style="font-size:16px;padding-bottom:20px;text-align:center;color:#000000">Transferencias de Material</p>
            </b-card-text>
          </b-card>
        </router-link>
         </div>
       
        </div>
        <div class="row" v-show="mobile && !tablet">
        <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
        <router-link :to="{ name: 'admin-users' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="usuarios" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220405_151423_VDCDwz_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.users}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Usuarios</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
        </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-materials-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Materiales" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101216_Bd1ZMa_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.materials}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Materiales</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link  :to="{ name: 'admin-warehouses-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Almacenes" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220402_101310_aydGF9_image.png?authuser=1"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.locations}}</h2>
              <p  style="font-size:16px; text-align:center;color:#000000">Almacenes</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
         <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
  justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-projects-all' }"  >
           <b-card no-body 
    style="max-width: 15rem;height:270px"
    img-top title="Proyectos" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//2022-03-04_020510-SNWvYw3M-774531.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.projects}}</h2>
              <p style="font-size:16px; text-align:center;color:#000000">Proyectos</p>
               <br />
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
              style="max-width: 15rem;height:270px"
              img-top title="Operaciones de entrada" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220311_142413_e38S3D_image.png?authuser=2"></b-card-img>
            <br />
            <b-card-text>
              <h2 class="number">{{elements.entry_operation}}</h2>
              <p style="font-size:16px;padding-bottom:40px;text-align:center;color:#000000">Operaciones de entrada</p>
              
            </b-card-text>
          </b-card>
        </router-link>
         </div>
            <div class="col-md-2" v-if="id_rol!=2" style="display: flex;
              justify-content: center;padding-bottom: 50px;" >
         <router-link :to="{ name: 'admin-operations-all' }"  >
           <b-card no-body 
            style="max-width: 15rem;height:270px"
            img-top title="Transferencias de Material" class="cardstyle" >
        <b-card-img src="https://storage.googleapis.com/prod-arbibe/development//20220401_173249_9vgE2L_image.png?authuser=6"></b-card-img>
            <br />
            <b-card-text >
              <h2 class="number">{{elements.material_transfers}}</h2>
              <p style="font-size:16px;padding-bottom:20px;text-align:center;color:#000000">Transferencias de Material</p>
            </b-card-text>
          </b-card>
        </router-link>
         </div>
        </div>
       
      <br>
 
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import axios from "axios";
import moment from "moment";
export default {
  name: "Welcome",

  data() {
    return {
      elements:{},
      tablet: false,
      mobile:false,
      id_rol:Cookies.get("id_rol")
     
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      me_rol: state => state.auth.me_rol
    })
  },
  mounted() {
   
    if(parseInt(localStorage.getItem("reload"))==1){
      location.reload();
      localStorage.removeItem("reload");
    }

    if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }

   this.loadCants();

  },

  methods: {
    ...mapActions(["loadCantsAll"]),
    loadCants(){
      this.loadCantsAll()
        .then((response) => {
          this.elements = response.data;
        })
      
    },
  
  }
};
</script>
<style>
 .text2 {
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 8;
    }
    .text21 {
        text-align: left;
        color: #000000;
        font-weight: 600;
        font-size: 8;
    }
    
.cardstyle {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.cardstyle:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.number{
  color:#00373B;
  text-align:center;
  font-weight:600;
}
</style>