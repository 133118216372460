import Vue from "vue";
import VueRouter from "vue-router";

import store from "../vuex/store";
//const store from '../vuex/store')
import Swal from "sweetalert2";
import { sync } from "vuex-router-sync";
import Cookies from "js-cookie";

//const Permission from '../permission')
const Permission = {
    ADMIN: 1,
    INVITADO: 2,
    PUBLIC: 0,

    LOGIN: 100,
};



//RUTAS AIRBIBE

import Register from "../components/auth/register";
import LoginUser from "../components/auth/loginUser";
import RecoveryUser from "../components/auth/recoveryUser";
import RecoveryPassword from "../components/auth/recoveryPassword";

import EcydWelcome from "../components/pages/admin/welcome/index";
import AdminContainer from "../components/containers/AdminContainer";

import Users from "../components/pages/admin/users/all";
import AddUser from "../components/pages/admin/users/new";
import UpdateUser from "../components/pages/admin/users/update";

import Projects from "../components/pages/admin/projects/all";
import AddProjects from "../components/pages/admin/projects/new";
import UpdateProjects from "../components/pages/admin/projects/update";

import Materials from "../components/pages/admin/materials/all";
import AddMaterial from "../components/pages/admin/materials/new";
import UpdateMaterial from "../components/pages/admin/materials/update";

import Warehouses from "../components/pages/admin/warehouses/all";
import AddWarehouse from "../components/pages/admin/warehouses/new";
import UpdateWarehouse from "../components/pages/admin/warehouses/update";

import Stocks from "../components/pages/admin/stocks/all";
import StockTrash from "../components/pages/admin/stocks/all_trash";
import StocksTransfer from "../components/pages/admin/stocks/allTranfer";
import AddStock from "../components/pages/admin/stocks/new";
import TransferStock from "../components/pages/admin/stocks/transfer";
import UpdateStock from "../components/pages/admin/stocks/update";

import Operations from "../components/pages/admin/operations/all_paginate";
import AddOperation from "../components/pages/admin/operations/new";
import UpdateOperation from "../components/pages/admin/operations/update";

import Report from "../components/pages/admin/reports/index";

import Units from "../components/pages/admin/units/all";
import AddUnit from "../components/pages/admin/units/new";
import UpdateUnit from "../components/pages/admin/units/update";

import Workers from "../components/pages/admin/workers/all";
import AddWorker from "../components/pages/admin/workers/new";
import UpdateWorker from "../components/pages/admin/workers/update";

import Roles from "../components/pages/admin/roles/all";
import AddRole from "../components/pages/admin/roles/new";
import UpdateRole from "../components/pages/admin/roles/update";

import Categories from "../components/pages/admin/categories/all";
import AddCategory from "../components/pages/admin/categories/new";
import UpdateCategory from "../components/pages/admin/categories/update";

import NotFound from "../components/NotFound";
import Page403 from "../components/pages/403.vue";

// const ifNotAuthenticated = (to, from, next) => {
//     if (!store.getters.isAuthenticated) {
//         next();
//         return;
//     }
//     next("/");
// };

/*const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};*/

Vue.use(VueRouter);

const routes = [

   
    {
        path: "/recover_password",
        component: RecoveryUser,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: [Permission.PUBLIC] },
        name: "recoveryUser",
    },
    {
        path: "/reset_password",
        component: RecoveryPassword,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: [Permission.PUBLIC ]},
        name: "recoveryPassword",
    },

    {
        path: "/registro",
        component: Register,
        //  beforeEnter: ifNotAuthenticated,
        meta: { permission: [Permission.PUBLIC] },
        name: "register",
    },

    {
        path: "/",
        component: LoginUser,
        // beforeEnter: ifNotAuthenticated,
        meta: { permission: [Permission.PUBLIC ]},
        name: "webpage",
    },

    {
        path: "/403",
        name: "403",
        component: Page403,
        meta: { permission: [Permission.PUBLIC] },
        //  meta: { permission: Permission.LOGIN }
    },

    {
        path: "/admin",
        //redirect: '',
        name: "admin",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN,Permission.INVITADO] },

        children: [{
            path: "/admin/dashboard",
            component: EcydWelcome,
            name: "admin_welcome",
            meta: { permission: [Permission.ADMIN,Permission.INVITADO] },
        }, ],
    },

    {
        path: "/usuarios",
        //redirect: '',
        name: "user",
        component: AdminContainer,
        meta: { permission: Permission.ADMIN },

        children: [{
                path: "/usuarios/listado",
                component: Users,
                name: "admin-users",
                meta: { permission: [Permission.ADMIN ]},
            },
            {
                path: "/usuarios/agregar",
                component: AddUser,
                name: "admin-addUser",
                meta: {
                    permission: [Permission.ADMIN]
                },
            },
            {
                path: "/usuarios/modificar/:id",
                component: UpdateUser,
                name: "admin-update-user",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/proyectos",
        //redirect: '',
        name: "Projects",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/proyectos/listado",
                component: Projects,
                name: "admin-projects-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/proyectos/agregar",
                component: AddProjects,
                name: "admin-projects-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/proyectos/modificar/:id",
                component: UpdateProjects,
                name: "admin-projects-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/materiales",
        //redirect: '',
        name: "materials",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/materiales/listado",
                component: Materials,
                name: "admin-materials-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/materiales/agregar",
                component: AddMaterial,
                name: "admin-materials-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/materiales/modificar/:id",
                component: UpdateMaterial,
                name: "admin-materials-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/almacenes",
        //redirect: '',
        name: "Warehouses",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/almacenes/listado",
                component: Warehouses,
                name: "admin-warehouses-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/almacenes/agregar",
                component: AddWarehouse,
                name: "admin-warehouses-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/almacenes/modificar/:id",
                component: UpdateWarehouse,
                name: "admin-warehouses-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/stocks",
        //redirect: '',
        name: "Stocks",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/stocks/listado",
                component: Stocks,
                name: "admin-stocks-all",
                meta: { permission: [Permission.ADMIN,Permission.INVITADO ]},
            },
            {
                path: "/stocks/papelera",
                component: StockTrash,
                name: "admin-stocks-trash",
                meta: { permission: [Permission.ADMIN ]},
            },
            
            {
                path: "/stocks/transferencias",
                component: StocksTransfer,
                name: "admin-stocks-alltransfer",
                meta: { permission: [Permission.ADMIN,Permission.INVITADO ]},
            },

            {
                path: "/stocks/agregar",
                component: AddStock,
                name: "admin-stocks-new",
                meta: { permission: [Permission.ADMIN] },
            },
            {
                path: "/stocks/agregar/material/:id",
                component: AddStock,
                name: "admin-stocks-new-id",
                meta: { permission: [Permission.ADMIN] },
            },
            {
                path: "/stocks/transferir",
                component: TransferStock,
                name: "admin-stocks-transfer",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/stocks/modificar/:id",
                component: UpdateStock,
                name: "admin-stocks-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/solicitudes",
        //redirect: '',
        name: "Operation",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/solicitudes/listado",
                component: Operations,
                name: "admin-operations-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/solicitudes/agregar",
                component: AddOperation,
                name: "admin-operations-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/solicitudes/modificar/:id",
                component: UpdateOperation,
                name: "admin-operations-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },
    {
        path: "/reportes",
        //redirect: '',
        name: "reports",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
            path: "/reporte/XLSX",
            component: Report,
            name: "admin-report-index",
            meta: { permission: [Permission.ADMIN,Permission.INVITADO] },
        }, ],
    },



    {
        path: "/unidades",
        //redirect: '',
        name: "Units",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/unidades/listado",
                component: Units,
                name: "admin-units-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/unidades/agregar",
                component: AddUnit,
                name: "admin-units-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/unidades/modificar/:id",
                component: UpdateUnit,
                name: "admin-units-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/trabajadores",
        //redirect: '',
        name: "Workers",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/trabajadores/listado",
                component: Workers,
                name: "admin-workers-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/trabajadores/agregar",
                component: AddWorker,
                name: "admin-workers-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/trabajadores/modificar/:id",
                component: UpdateWorker,
                name: "admin-workers-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },


    {
        path: "/roles",
        //redirect: '',
        name: "Roles",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/roles/listado",
                component: Roles,
                name: "admin-roles-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/roles/agregar",
                component: AddRole,
                name: "admin-roles-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/roles/modificar/:id",
                component: UpdateRole,
                name: "admin-roles-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },

    {
        path: "/categorias",
        //redirect: '',
        name: "Categories",
        component: AdminContainer,
        meta: { permission: [Permission.ADMIN] },

        children: [{
                path: "/categorias/listado",
                component: Categories,
                name: "admin-categories-all",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/categorias/agregar",
                component: AddCategory,
                name: "admin-categories-new",
                meta: { permission: [Permission.ADMIN] },
            },

            {
                path: "/categorias/modificar/:id",
                component: UpdateCategory,
                name: "admin-categories-view",
                meta: { permission: [Permission.ADMIN] },
            },
        ],
    },





    //-No revisadooo-


    {
        path: "*",
        meta: {
            layout: "not-found-layout",
        },
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    //base: process.env.BASE_URL,
    routes,
});
// Sync Vuex and vue-router;
sync(store, router);

sync(store, router);

router.beforeEach((to, from, next) => {
 // console.log(to);
  //Token de acceso creado al logearse, se obtiene de las cookies
  //const accessToken = Cookies.get("access_token");
  //Obteniendo el rol del usuario
  const id_rol = Cookies.get("id_rol");

//   console.log("ROL",id_rol);

//   console.log("TO META ROUTE:" + to.meta.permission);
//   console.log("incluye 1",to.meta.permission.includes(1));

  //let array_permission = to.meta.permission;
  if (to.meta.permission == 0) {
    //Si el permiso es publico con valor 0, lo deja pasar, para rutas de login o rutas publicas
   // console.log("ITS A PUBLIC ROUTE");
    next();
  } 
  else if (id_rol == undefined && to.meta.permission == 1) {
    //Si no existe la variable rol no esta logeado
    Swal.fire({
      title: "Oops...",
      text: "Debe iniciar sesión para continuar.",
      icon: "warning",
    });
    // console.log("GO TO LOGIN BY INVALID ROLES");
    next(from);
  } else if (id_rol == 1  && to.meta.permission.includes(1)) {
    //Si el permiso es publico con valor 0, lo deja pasar, para rutas de login o rutas publicas

    next();
  } else if (id_rol == 2  && to.meta.permission.includes(2)) {
    //Si el permiso es publico con valor 0, lo deja pasar, para rutas de login o rutas publicas

    next();
  } else{
    next("/403");
  }/*else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.auth.me &&
    !accessToken
  ) {
    //Si no existe el token auth.me o accessToken
    Swal.fire({
      title: "Oops...",
      text: "Debe iniciar sesión para continuar.",
      icon: "warning",
    });
    console.log("GO TO LOGIN BY INVALID TOKEN");
    next("/");
  } else if (store.state.auth.me && accessToken) {
    //Si detecta que esta logeado lo deja entrar
    console.log("The user is LOGGED");
  //  console.log(to.meta.permission);
    let access = 0;
   // console.log('ARRAY PERMISSION');
  //  console.log(array_permission);
   // console.log(array_loged_roles);
    for (let i in array_permission) {
     // console.log('Entrando');
      //Chequea el meta permiso para ver si lo deja entrar
      let rest = array_permission[i];
      console.log(rest);
      //Si el permiso es igual al rol, o el permiso es 10 lo deja entrar
      if (array_loged_roles.includes(rest) || rest == 10) {
      //  console.log('Dando acceso')
        access = 1;
      }
    }

    // console.log(store.state.auth.me_rol)
    if (access == 1) {
      //Cuando la variable access es 1, lo deja entrar a la ruta
      console.log("Rol Acepted on Route");
      next();
    } else if (to.name != "403") {
      //SI no es 1, no lo deja entrar, y se valida que la ruta sea distinta a 403
      console.log("NOT ACCESS");
      console.log("Rol:" + id_rol);
      //Lo envia a la ruta 403, indicando que no tiene permisos
     next("/403");
    } else {
      next();
    }
  } else {
    //alert("LAST ELSE");
    next("/");
  }*/
});

// router.beforeEach((to, from, next) => {
//     const accessToken = Cookies.get("access_token");
//     //const id_rol = Cookies.get("id_rol");
//     //console.log('TO META')
//     //console.log(to.meta)
//     if (to.meta.permission == 0) {
//         // console.log('PUBLIC ROUTE')
//         next();
//     } else if (
//         to.matched.some((record) => record.meta.requiresAuth) &&
//         !store.state.auth.me &&
//         !accessToken
//     ) {
//         next("/login");
//     } else if (store.state.auth.me && accessToken) {
//         next();
//     } else {
//         next("/login");
//     }
// });

export default router;