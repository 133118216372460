let maxToastId = 0;

const state = {
    messages: [],
};

const getters = {
    toastMessages: (state) => state.messages
};

const actions = {
    addToastMessage({commit}, {text, type = 'info', dismissAfter = 5000}) {
        const id = ++maxToastId;

        commit('ADD_TOAST_MESSAGE', {
            id,
            text,
            type,
            dismissAfter
        });
        setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);
    },
    addToastMessageArray({commit}, {array, type = 'info', dismissAfter = 5000}) {

        array.map(function (v, i) {
            const id = ++i;
            if (v.field == 'email') {
                if (v.validation == 'unique') {
                    let text = 'Este correo eletrónico ya existe';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
                if (v.validation == 'required') {
                    let text = 'El correo eletrónico es requerido';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
            }
            if (v.field == 'dni') {
                if (v.validation == 'unique') {
                    let text = 'Este documento de identidad ya existe';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);
                }
                if (v.validation == 'required') {
                    let text = 'El documento de identidad es requerido';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
            }
            if (v.field == 'name') {
                if (v.validation == 'unique') {
                    let text = 'Este nombre ya existe';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
                if (v.validation == 'required') {
                    let text = 'El nombre es requerido';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
            }
            if (v.field == 'capacity') {
                if (v.validation == 'required') {
                    let text = 'La cantidad es requerida';
                    commit('ADD_TOAST_MESSAGE', {
                        id,
                        text,
                        type,
                        dismissAfter
                    });
                    setTimeout(() => commit('REMOVE_TOAST_MESSAGE', id), dismissAfter);

                }
            }
        })

    },

    removeToastMessage({commit}, id) {
        commit('REMOVE_TOAST_MESSAGE', id);
    }
};

const mutations = {
    ADD_TOAST_MESSAGE(state, data) {
        state.messages.push(data);
    },

    REMOVE_TOAST_MESSAGE(state, id) {
        state.messages = state.messages.filter(m => m.id !== id);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
