<template>
  <div id="AllLocation">
    <br />
    <div class="content">
      <b-row>
        <b-col md="9" style="text-align: -webkit-left">
           <h5>Almacenes</h5>
           <br/>
           <p>En esta ventana, usted podrá ver todos los almacenes registrados. Siendo el almacén principal o almacén base, representado por el check de color verde.</p>
          <p style="font-weight:600">Cantidad de almacenes: {{ element_count }}</p>
        </b-col>
        <b-col md="3" style="text-align: -webkit-right">
          <b-button block class="primary" :to="{ name: 'admin-warehouses-new' }">
            Crear almacén</b-button
          >
        </b-col>
        <b-col md="1" style="text-align: -webkit-right"> </b-col>
      </b-row>
      <b-row v-show="!tablet">
        <b-col lg="4" class="my-1">
          <b-form-group
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-input-group-append>
                <button class="primary2">
                   <font-awesome-icon class="iconTable" :icon= "['fas', 'search']"  title="Buscar"/>
                </button>
              </b-input-group-append>

              <b-form-input
                id="filterInput"
                v-model="filter"
                v-on:keyup="searchInput"
                type="text"
                placeholder="Buscar almacén"
              />

              <b-input-group-append>
                <button
                  class="primary2"
                  :disabled="!filter"
                  @click="clearFilter"
                >
                  <font-awesome-icon class="iconTable" :icon= "['fas', 'times']" title="Limpiar busqueda" />
                </button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="4" class="my-1">
          <b-form-group
            label="Por página"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group>
            <span  v-if="mobile == false && tablet==false" class="mt-3">Página actual: {{ currentPage }}</span>
             <span  v-if="mobile == false && tablet==true" style="margin-left:300px" class="mt-3">Página actual: {{ currentPage }}</span>
              <p
              v-if="mobile == true && tablet==false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group v-if="mobile == false && tablet==false" style="margin-left:-40px">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
           <b-form-group  v-if="mobile == false && tablet==true" style="margin-left:300px">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
            <b-form-group
            v-if="mobile == true && tablet==false"
            style="margin-left: 130px; margin-top: -25px"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
        </b-col>
      </b-row>
        <b-row v-show="tablet">
             
          <b-col md="6" class="my-1">
             <b-form-group
            >
              <b-input-group size="sm">
              <b-input-group-append>
                  <button class="primary2" > <font-awesome-icon class="iconTable" :icon= "['fas', 'search']"  title="Buscar" /></button>
                </b-input-group-append>
              
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    v-on:keyup="searchInput"
                    type="text"
                    placeholder="Buscar almacén"
                />
                
                <b-input-group-append>
                  <button class="primary2" :disabled="!filter" @click="clearFilter"><font-awesome-icon class="iconTable" :icon= "['fas', 'times']"  title="Limpiar busqueda"/></button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          
          </b-col>
        </b-row>
      <b-row class="mt-3 px-3">
        <b-col sm="12">
          <div>
            <b-table
              responsive
              id="my-table"
              show-empty
              small
              stacked="md"
              sticky-header="true"
              hover
              :primary-key="id"
              :items="elementsTMP"
              :fields="field"
              :current-page="currentPage"
              :per-page="perPage"
              empty-text="No hay almacenes disponibles"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :busy="isBusy"
            >
              <!--                :filter="filter"-->
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <template v-slot:table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Cargando ...</strong>
                </div>
              </template>
               <template v-slot:cell(image)="row">
                    <img v-if="row.item.image !=null"
              class="img-responsive center-block d-block mx-auto"
              style="
                width: 80px;
              "
              :src="row.item.image"
            />
             <img v-if="row.item.image ==null  || row.item.image=='' "  style="
                width: 80px;
              " class="img-responsive center-block d-block mx-auto imgStyles_"
              src="../../../../assets/img/image.png"/>
            
              </template>
               <template v-slot:cell(nameA)="row">
                <span v-if="row.item.type==1"> <font-awesome-icon class="iconTable" icon="check" style="color: green!important;" title="Almacén base"/></span><span> {{row.item.name}}</span>
               
              </template>
              <template v-slot:cell(actions)="row">
                <button
                  size="sm"
                  class="primary2"
                  @click="edit_element(row.item)"
                >
                  <font-awesome-icon class="iconTable" icon="pencil-alt" style="color: black!important;" title="Editar"/>
                </button>
                <button
                  class="primary2"
                  v-if="row.item.active === 0"
                  size="sm"
                  title="Desactivado"
                  @click="changeStatus(row.item)"
                >
                  <font-awesome-icon class="iconTable" :icon= "['far', 'square']" title="Desactivado" />
                </button>
                <button v-if="row.item.active === 1" size="sm" class="primary2" @click="changeStatus(row.item)">
                   <font-awesome-icon class="iconTable" :icon= "['far', 'check-square']"  title="Activado"/>
                </button>

                <button v-if="row.item.type!=1"  size="sm" class="primary2" @click="delete_element(row)">
                  <font-awesome-icon class="iconTable" icon="trash" style="color: red!important;" title="Eliminar"/>
                </button>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col sm="12">
          <div></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="4" class="my-1"> </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group>
            <span class="mt-3" v-if="mobile == false && tablet==false">Página actual: {{ currentPage }}</span>
             <span  v-if="mobile == false && tablet==true" style="margin-left:300px" class="mt-3">Página actual: {{ currentPage }}</span>
             <p
              v-if="mobile == true && tablet==false"
              style="font-size: 15px; text-align: center"
              class="mt-3"
            >
              Página actual: {{ currentPage }}
            </p>
          </b-form-group>
        </b-col>
        <b-col lg="2" class="my-1">
          <b-form-group  v-if="mobile == false && tablet==false" style="margin-left:-40px">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
          <b-form-group  v-if="mobile == false && tablet==true" style="margin-left:300px">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
           <b-form-group
            v-if="mobile == true && tablet==false"
            style="margin-left: 130px; margin-top: -25px"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              size="sm"
            ></b-pagination>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
function clearAccents(cadena) {
  let chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
    Ñ: "N",
  };
  let expr = /[áàéèíìóòúùñ]/gi;
  let res = cadena.replace(expr, function(e) {
    return chars[e];
  });
  return res;
}

import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "AllLocation",
  data() {
    return {
      element_count: 0,
      isBusy: false,
      id: "",
      items: [{
            text: "Almacenes",
          },],
      item_table: null,
      ttap_selected: null,
      field: [
          {
          key: "image",
          label: "Imagen",
          sortable: true,
        },
       
        {
          key: "nameA",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "super_manager",
          label: "Superintend.",
          sortable: true,
        },
        {
          key: "stock_manager",
          label: "Encarg. de almacén",
          sortable: true,
        },
          {
          key: "engineer_manager",
          label: "Ingeniero residente",
          sortable: true,
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: false,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 20, 50, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      elements: [],
      elementsTMP: [],
      loading: false,
      error: "",
      mobile:false,
      tablet:false
    };
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      me_rol: (state) => state.auth.me_rol,
    }),
    sortOptions() {
      return this.field
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows() {
      return this.elementsTMP.length;
    },
  },
  mounted() {

   if(window. innerWidth>=1024){
      this.mobile=false;
      this.tablet=false;
    }else if(window. innerWidth>=500 && window. innerWidth<1024){
       this.mobile=false;
      this.tablet=true;
    }else{
      this.mobile=true;
      this.tablet=false;
    }
    this.allElements();
  },
  methods: {
    ...mapActions([
      "loadLocation",
      "changeStatus_location"
    ]),
    alert() {
      alert("Próximamente");
    },
    clearFilter() {
      this.elementsTMP = this.elements;
      this.filter = "";
      return true;
    },
    searchInput() {
      let filterTMP = this.filter;
      let elementsTMP = [];
      if (filterTMP) {
        this.elements.filter(function(el, i) {
          let cadena = JSON.stringify(el);
          if (
            clearAccents(cadena)
              .toLowerCase()
              .indexOf(filterTMP.toLowerCase(), i) > -1
          ) {
            elementsTMP.push(el);
          }
        });
        this.elementsTMP = elementsTMP;
      } else {
        this.elementsTMP = this.elements;
      }
      return true;
    },
    allElements() {
      this.isBusy = true;
      let query = null;

      this.loadLocation(query)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.elements = response.data;
            this.elementsTMP = response.data;
            this.element_count = this.elements.length;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    edit_element(data) {
      this.$router.push("/almacenes/modificar/" + data.id);
    },

    changeStatus(data) {
      let active = data.active == 1 ? 0 : 1;
      let title = data.active == 1 ? "¿Desea desactivar este elemento?" : "¿Desea activar este elemento?";
      // let title2 = data.active == 1 ? "Desactivado" : "Activado";
      let text =
          data.active === 1
              ? "Elemento inactivado"
              : "Elemento activado";
      Swal.fire({
        title: title,
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Cambiar estatus",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: data.id,
            active: active
          };
          this.changeStatus_location(form)
              .then(response => {
                if (response.code === 200) {
                  data.active = active;
                  Swal.fire({
                    text: text,
                    icon: "success",
                    confirmButtonColor: "#00373B",
                  });
                  this.allElements();
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    delete_element(row) {
      Swal.fire({
        title: "¿Desea eliminar este elemento?",
        icon: "question",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: "#00373B",
        cancelButtonColor: "#DD137B",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let form = {
            id: row.item.id,
            active: 2
          };
          this.changeStatus_location(form)
              .then(response => {
                if (response.code === 200) {
                  this.elements.splice(row.index, 1);
                  Swal.fire({
                    title:"Elemento eliminado",
                    confirmButtonColor: "#00373B",
                    icon: "success"
                  });
                }
              })
              .catch(error => {
                console.log(error)
              });
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.primary {
  background: linear-gradient(180deg, #00373B 0%, #00373B 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
  color: white !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>
