import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {

    register_project({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "master/project/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    consult_project({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "master/project/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    modify_project({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "master/project/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    changeStatus_project({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "master/project/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadProjects({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "master/project/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};