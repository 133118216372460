<template>
  <div id="UpdateOperation">
    <br />
    <div class="content">
         </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "UpdateOperation",
  data() {
    return {
      element: {},
      loading: false,
      error: "",
      listCountries: [],
      listTerritories: [],
      id_country: '',
      id_territory: '',
      mobile:false
    };
  },
  computed: {
    ...mapState({}),
  },
  mounted() {
    this.allCountries();
        if(window. innerWidth>=1024){
    this.mobile=false;
  }else{
    this.mobile=true;
  }
  },
  methods: {
    ...mapActions(["find_city_by_id", "update_city", "loadCountries", "load_territories"]), 


    get_element() {
      let id = this.$route.params.id;
      if (!id) {
        Swal.fire({
          title: "Elemento no encontrado",
          text: "",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        return;
      }

      this.find_city_by_id(id)
        .then((response) => {
          if (response.code == 200) {
            this.element = response.data;
          }

          if (response.code == 404) {
            Swal.fire({
              title: "Elemento no encontrado",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    edit_element() {
      if (this.element.name == "" || this.element.id_country == "" || this.element.id_territory == "" ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
        return;
      }

      let form = {
        id: this.$route.params.id,
        name: this.element.name,
        id_country: this.element.id_country,
        id_territory: this.element.id_territory
      };


      this.update_city(form)
        .then((response) => {
          if (response.code == 200) {
            Swal.fire({
              title: "Registro modificado",
              
              icon: "success",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                this.progress = false;
                this.$router.push("/city");
              }
            });
          }

          if (response.code == 409) {
            Swal.fire({
              title: "Registro duplicado",
              icon: "warning",
              reverseButtons: true,
              confirmButtonColor: "#00373B",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.table thead th {
  cursor: pointer;
  max-width: 900px;
}
.icon {
  color: #88499d;
}
.icon:hover {
  color: #224abe;
}
.primary2 {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.5rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00373B !important;
  border-color: #00373B !important;
}
</style>
