<template class="main">
  <div class="heigthNew" id="div2">
    <b-row>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <b-col md="5" class="backgroundimg2">
        <div class="row">
         
        </div>
      </b-col>
      <b-col md="7">
     
        <br />
        <b-row>
          <b-col md="1"> </b-col>
          <b-col md="10">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <img
                  class="img-responsive center-block d-block mx-auto"
                  style="max-width: 150px; text-align: center"
                  src="../../assets/img/logo_colab_es.png"
                />
              </div>
              <div class="col-md34"></div>
            </div>
            <br />
          </b-col>
        </b-row>
        <b-row v-if="step == 1">
          <b-col md="6">
            <div center style="background: transparent">
              <h6>Nombres</h6>
              <b-input-group size="md">
                <b-form-input
                  v-model="name"
                  placeholder="Nombres"
                ></b-form-input>
              </b-input-group>
              <br />
              <h6>Correo</h6>

              <b-input-group size="md">
                <b-form-input
                  v-model="email"
                  placeholder="Correo"
                ></b-form-input>
              </b-input-group>
              <br />

            
            
             
            </div>
          </b-col>

          <b-col
            md="6"
            v-bind:class="{ marginRegisterNotMobile: mobile == false }"
          >
            <div center style="background: transparent">
              <!-- Using props -->
              <h6>Apellidos</h6>
              <b-input-group size="md">
                <b-form-input
                  v-model="lastname"
                  placeholder="Apellidos"
                ></b-form-input>
              </b-input-group>
              <br />
               <h6>Contraseña</h6>
              <b-input-group size="md">
                <b-form-input
                  v-bind:type="variable"
                  v-model="password"
                  placeholder="Contraseña"
                ></b-form-input>
                <button size="sm" class="primary2" @click="showPassword()">
                  <font-awesome-icon
                    v-if="variable == 'password'"
                    class="iconTable"
                    icon="eye"
                  />
                  <font-awesome-icon
                    v-if="variable == 'text'"
                    class="iconTable"
                    icon="eye-slash"
                  />
                </button>
              </b-input-group>
              <br />

             
              
            
              
            </div></b-col
          >
        </b-row>
        <b-row>
          <b-col md="2"> </b-col>
          <b-col md="8">
            <br v-if="step == 1" />
            <br v-if="step == 1" />
            <b-form center style="background: transparent">
              <div class="row">
                <div class="col-md-4" >
                  <b-button block class="primary" v-on:click="register()">
                    Guardar</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align: center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                 <div class="col-md-4"></div>
                 <div class="col-md-4" >
                  <b-button block class="primary3" v-on:click="register()">
                    cancelar</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align: center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
               
              </div>
            </b-form>
          </b-col>

          <b-col md="2"> </b-col>
        </b-row>
        <br />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import platform from "platform";
import "../../assets/custom-vars.scss";
//import Permission from "../../permission";
//import Cookies from "js-cookie";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
      date_of_birth: "",
      f_name: "",
      f_lastname: "",
      f_email: "",
      f_phone: "",
      m_name: "",
      m_lastname: "",
      m_email: "",
      m_phone: "",
      person_first_name: "",
      person_last_name: "",
      person_email: "",
      person_phone: "",
      gender: null,
      tutor: null,
      country: null,
      country_residence: null,
      city_residence: null,
      person: null,
      progress: false,
      btnLogin: false,
      listCountries: [],
      listCities: [],
      listDirectors: [],
      auth: {},
      lang: "es",
      langs: ["es", "en"],
      step: 1,
      variable: "password",
      variable2: "password",
      mobile: false,
    };
  },

  computed: {
    ...mapState({}),

    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted() {
    if (window.innerWidth >= 1024) {
      this.mobile = false;
    } else {
      this.mobile = true;
    }

    this.allCountries();
    let device =
      platform.name +
      " - " +
      platform.version +
      " - " +
      platform.os +
      " - " +
      platform.product;
    console.log(device);
  },
  methods: {
    ...mapActions([
      "loadCountries",
      "load_cities_by_country",
      "loadDirectors",
      "register_collaborator",
      "addToastMessage",
    ]),
    showPassword() {
      if (this.variable == "password") {
        this.variable = "text";
      } else {
        this.variable = "password";
      }
    },
    showPassword2() {
      if (this.variable2 == "password") {
        this.variable2 = "text";
      } else {
        this.variable2 = "password";
      }
    },
    nextStep() {
      if (
        this.name == "" ||
        this.lastname == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.gender == null ||
        this.country == null ||
        this.country_residence == null ||
        this.city_residence == null ||
        this.password == "" ||
        this.confirm_password == "" ||
        this.date_of_birth == "" ||
        this.person == null
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
      } else if (!this.email.includes("@")) {
        Swal.fire({
          title: "Error en email",
          text: "Por favor , verifica tu correo",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
      } else if (this.password !== this.confirm_password) {
        Swal.fire({
          title: "Error en las contraseñas",
          text: "Las contraseñas deben ser iguales",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
      } else {
        this.step += 1;
      }
    },
    changeCountry() {
      this.city_residence = null;
      this.person = null;
      this.person_first_name = "";
      this.person_last_name = "";
      this.person_email = "";
      this.person_phone = "";
      if (this.country_residence !== null) {
        this.allCities();
      }
    },
    changePerson() {
      this.person = null;
      this.person_first_name = "";
      this.person_last_name = "";
      this.person_email = "";
      this.person_phone = "";
      if (this.city_residence !== null && this.gender !== null) {
        this.allDirectors();
      }
    },
    register() {
      if (this.tutor == null) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
      } else if (
        (this.tutor == "F" &&
          (this.f_name == "" ||
            this.f_lastname == "" ||
            this.f_email == "" ||
            this.f_phone == "")) ||
        (this.tutor == "M" &&
          (this.m_name == "" ||
            this.m_lastname == "" ||
            this.m_email == "" ||
            this.m_phone == ""))
      ) {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor , verifica que los datos no esten vacios",
          icon: "warning",
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok",
        });
      } else {
        this.progress = true;
        var newdate = moment(this.date_of_birth).format("YYYY-MM-DD");
        let form = {
          first_name: this.name,
          last_name: this.lastname,
          birthdate: newdate,
          gender: this.gender,
          id_country_origin: this.country,
          id_country_residence: this.country_residence,
          id_city_residence: this.city_residence,
          phone: this.phone,
          email: this.email,
          password: this.password,
          password_confirmation: this.confirm_password,
          selected_representative: this.tutor,
          f_first_name: this.f_name,
          f_last_name: this.f_lastname,
          f_phone: this.f_phone,
          f_email: this.f_email,
          m_first_name: this.m_name,
          m_last_name: this.m_lastname,
          m_phone: this.m_phone,
          m_email: this.m_email,
          selected_admin: this.person,
          admin_first_name: this.person_first_name,
          admin_last_name: this.person_last_name,
          admin_phone: this.person_phone,
          admin_email: this.person_email,
        };

        this.register_collaborator(form)
          .then((response) => {
            if (response.code == 409) {
              Swal.fire({
                title: "Elemento no registrado",
                icon: "warning",
                reverseButtons: true,
                confirmButtonColor: "#00373B",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    allCountries() {
      this.isBusy = true;
      this.loadCountries("?active=1")
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listCountries = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allCities() {
      let form = {
        id_country_residence: this.country_residence,
      };
      this.isBusy = true;
      this.load_cities_by_country(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listCities = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    allDirectors() {
      let form = {
        city: this.city_residence,
        gender: this.gender,
      };
      this.isBusy = true;
      this.loadDirectors(form)
        .then((response) => {
          if (response.code === 200) {
            this.isBusy = false;
            this.listDirectors = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
.heigthNew {
  min-height: 100%;
}
#div2{
  flex: 1;
}
body {
   height:100%;
  margin:0;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
.backgroundimg2 {
  display: flex;
  flex-direction: column;
  height:100vh;
  background-image: url("../../assets/img/register.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.backgroundimg::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}
.textimg {
  text-align: center;
  color: #ffffff;
  font-weight: 600;
}
.text {
  text-align: right;
  color: #00373B;
  font-weight: 600;
}
.text2 {
  text-align: left;
  color: #000000;
  font-weight: 600;
  font-size: 8;
}
.text3 {
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 0.8rem;
}

.primary {
  background: linear-gradient(180deg, #00373B 0%, #00373B 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
}

.primary3 {
  background: linear-gradient(180deg, #DD137B 0%, #DD137B 100%);
  box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
  border-radius: 1px;
}
.form-control2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  background-color: #fff;
  border: 0px solid #ffffff;
}
.marginRegisterNotMobile {
  margin-left: -15px;
}

.step {
  background-color: #00373B;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
  border: none;
  height: 50px;
  border-radius: 50%;
}
.stepc {
  background-color: #00373B;
  color: white;
  padding: 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
  border: none;
  height: 50px;
  border-radius: 50%;
}

.step2 {
  background-color: #c4c4c4;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 4px 2px;
  height: 50px;
  border: none;
  border-radius: 50%;
}
.select {
  font-weight: 600 !important;
}
</style>