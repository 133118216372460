<template class="main">
  <div class="heigthNew" >
      <b-row>
          <b-col md="6" class="backgroundimg">
          <br />
          <br />
          <br />
         <div class="row" id="div2"> 
                 
              </div>
               <h1 class="textimg">Recuperar contraseña</h1>
                  
         </b-col>
        <b-col md="6">
         <b-row style="margin-top:15px">
          <b-col md="8"> </b-col>
          <b-col md="4">
                    </b-col>
          </b-row>
           <div class="row"  style="margin-top:80px">
                  <img v-if="$i18n.locale == 'es'"
                    class="img-responsive center-block d-block mx-auto"
                    style="
                      max-width: 100px;
                      text-align: center;
                    "
                    src="../../assets/img/logo_colab_es.png"
                  />
                  <img v-if="$i18n.locale == 'en'"
                    class="img-responsive center-block d-block mx-auto"
                    style="
                      max-width: 100px;
                      text-align: center;
                    "
                    src=""
                  />
              </div>
               <br />
        <b-row>
          <b-col md="2"> </b-col>
           <b-col md="8">
           <div center  v-if="step===1" style="background: transparent">
          
             
              <br />
              <div style="text-align:center">
              <h6 class="text2">Recuperar contraseña</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-input-group size="lg" prepend="Contraseña">
                <b-form-input v-bind:type="variable" v-model="password"></b-form-input>
                  <button
                      size="sm"
                      class="primary2"
                      @click="showPassword()"
                  >

                    <font-awesome-icon  v-if="variable=='password'" class="iconTable" icon="eye" />
                     <font-awesome-icon  v-if="variable=='text'" class="iconTable" icon="eye-slash" />
                  </button>
              </b-input-group>
              <br />
               <b-input-group size="lg" prepend="Confirmar contraseña">
                <b-form-input v-bind:type="variable2" v-model="confirm_password"></b-form-input>
                  <button
                      size="sm"
                      class="primary2"
                      @click="showPassword2()"
                  >

                    <font-awesome-icon  v-if="variable2=='password'" class="iconTable" icon="eye" />
                     <font-awesome-icon  v-if="variable2=='text'" class="iconTable" icon="eye-slash" />
                  </button>
              </b-input-group>
              <br />
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary" v-on:click="onSubmit">Solicitar</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align:center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
          </div>
          <div center  v-if="step===2" style="background: transparent">
          
             
              <br />
              <div style="text-align:justify">
              <h6 class="text2">Enviar</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              <br />
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary" v-on:click="onSubmit">Solicitar</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align:center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
          </div> </b-col>
          
            <b-col md="2"> </b-col>
             </b-row>
               <b-row>
          <b-col md="1"> </b-col>
           <b-col md="10"> 
           </b-col>
          
            <b-col md="1"> </b-col>
             </b-row>
        </b-col>
       
    
      </b-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import "../../assets/custom-vars.scss";
//import Permission from "../../permission";
import { validate } from "vee-validate";
//import Cookies from "js-cookie";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      password: "",
      confirm_password:"",
      token:"",
      step:1,
      auth: {},
      lang: "es",
      langs: ["es", "en"],
      variable:"password",
      variable2:"password"
    };
  },

  computed: {
    ...mapState({}),
    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted() {
       var pageURL = window.location.search
        var urlParams = new URLSearchParams(pageURL);
        var param = urlParams.get('token');
        this.token = param;
  },
  methods: {
    ...mapActions(["recoveryNewPassword", "addToastMessage"]),
    showPassword(){
      if(this.variable=="password"){
        this.variable="text";
      }else{
        this.variable="password";
      }
      
    },
    showPassword2(){
      if(this.variable2=="password"){
        this.variable2="text";
      }else{
        this.variable2="password";
      }
      
    },
    onSubmit() {
      if (this.password == "") {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor, verifica que los datos no esten vacios",
          icon: "warning",
          confirmButtonColor: "#00373B",
        });
      } else if (this.password !== this.confirm_password) {
        Swal.fire({
          title: "Error en la contraseña",
          text: "Las contraseñas deben ser iguales",
          icon: 'warning',
          reverseButtons: true,
          confirmButtonColor: "#00373B",
          confirmButtonText: "Ok"
        });
      }else {
        validate().then((result) => {
          if (result) {
            this.progress = true;
            this.btnLogin = true;
            //GET DEVICE INFO

            this.recoveryNewPassword({
              password: this.password,
              password_confirmation: this.confirm_password,
              token: this.token
            })
              .then((data) => {
                if (data.code == 200) {
                  this.auth = data;

                  this.btnLogin = false;
                   this.progress = false;
                  Swal.fire({
                    title: this.$i18n.t('newPassword'),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                      this.$router.push("/loginUser");
                  });
                }else if (data.code == 400) {
                  this.progress = false;
                  Swal.fire({
                    title: this.$i18n.t('error_token2'),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }else{
                    this.progress = false;
                  Swal.fire({
                    title: this.$i18n.t('text_newPassword'),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });

                }

              })

              .catch((data) => {
                this.progress = false;
                this.btnLogin = false;
                if (data.code == 404) {
                  this.addToastMessage({
                    text: this.$i18n.t('error_email_not_registered'),
                    icon: "warning",
                  });
                  this.progress = false;
                }
              });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.heigthNew {
  min-height: 100%;
}
#div2{
  flex: 1;
}
body {
   height:100%;
  margin:0;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
.backgroundimg{
  display: flex;
  flex-direction: column;
  height:100vh;
    background-image: url('../../assets/img/KAANA-min.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}
.backgroundimg::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
}
     .textimg {
        font-family: 'Syncopate', sans-serif;
        text-align: center;
        color: #ffffff;
        font-weight: 600;
    }
    .text {
        text-align: right;
        color: #00373B;
        font-weight: 600;
    }
     .text2 {
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 8;
    }
     .text3 {
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 0.8rem;
    }
   
    .primary {
 background:linear-gradient(180deg, #00373B 0%, #00373B 100%);
        box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
        border-radius: 1px;
}
    .form-control2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  background-color: #fff;
  border: 0px solid #ffffff;
}
</style>