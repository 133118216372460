<template>
  <div id="TSchool" class="noScroll">
  <toast />
    <router-view/>
  </div>

</template>
<style lang="scss">
  // Import custom SASS variable overrides, or alternatively
  // define your variable overrides here instead
  @import 'assets/custom-vars.scss';

  // Import Bootstrap and BootstrapVue source SCSS files
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';
  @import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap');


  // ...
</style>
<script>

import toast from './components/layout/Toast';

  export default {
    name: 'TSchool',
    components: {
            toast
        },
         data() {
            return {
                toggle: true,
                menu: []
            };
        },
  };
</script>
<style scoped>
#content {
    position: relative;
    
}
.noScroll{
   overflow-x: hidden;
}
</style>


