import axios from "axios";
import * as Config from "../../config";

const state = {
    services: {
        current_page: 1,
        data: [],
    },
    service: {},
};

const actions = {

    register_location({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "location/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    consult_location({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "location/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    modify_location({ commit, dispatch }, data) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "location/update/" + data.get("id"), data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    changeStatus_location({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "location/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    loadLocation({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "location/all";
        if (query) endpoint += query;

        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

};

const mutations = {
    LOAD_DATA_COUNTERS(state, data) {
        state.counter = data;
    },
};

export default {
    state,
    actions,
    mutations,
};