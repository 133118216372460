import Vue from "vue";
//import Vue from 'vue/dist/vue.min.js'
import axios from "axios";
import store from "./vuex/store"; // vuex store instance
import App from "./App.vue";
import router from "./router/routes";
import Cookies from "js-cookie";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


require('./fontawesome');



//BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//import UUID from "vue-uuid";

window.axios = require("axios");


import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
//import "./global-components";
import AudioVisual from 'vue-audio-visual'
 
Vue.use(AudioVisual)
//Sidebar Menu
//import VueSidebarMenu from "vue-sidebar-menu";
//import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
//import "./assets/css/custom.css";
import Datepicker from 'v-calendar/lib/components/date-picker.umd'

Vue.component('date-picker', Datepicker)

import "sweetalert2/src/sweetalert2.scss";

import swal from 'sweetalert2';
window.Swal = swal;
//window.Swal = require("sweetalert2");

window.Moment = require("moment");
window.Moment.locale("es");

//FONT AWESOME
// or import all icons if you don't care about bundle size
//import 'vue-awesome/icons'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/users'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/user-graduate'
import 'vue-awesome/icons/user-tie'
import 'vue-awesome/icons/credit-card'
import 'vue-awesome/icons/file-alt'
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/search'
import 'vue-awesome/icons/money-bill'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/minus-square'
import 'vue-awesome/icons/check-square'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/question'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/hand-pointer'
import 'vue-awesome/icons/thumbs-down'
import 'vue-awesome/icons/thumbs-up'
import 'vue-awesome/icons/clipboard-list'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/angle-left'
import 'vue-awesome/icons/angle-right'
import 'vue-awesome/icons/school'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/download'
import 'vue-awesome/icons/paper-plane'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/key'
import 'vue-awesome/icons/chevron-down'
import 'vue-awesome/icons/play-circle'
import 'vue-awesome/icons/paperclip'
import 'vue-awesome/icons/dog'
/* Register component with one of 2 methods */

import Icon from 'vue-awesome/components/Icon'


// globally (in your main .js file)
Vue.component('v-icon', Icon)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';

axios.interceptors.request.use(
  function(config) {
    config["headers"] = {
      Authorization: "Bearer " + Cookies.get("access_token"),
      tokentap:'bCZf5V9SkeK7ePawLLq6XQdjg',
      Accept: "application/json"
    };   

    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (
      typeof error.response.data.data === "object" &&
      error.response.data.code === 400
    ) {
      for (var key in error.response.data.data) {
        store.dispatch("addToastMessage", {
          text:
            error.response.data.data[key][0] ||
            "Request error status: " + error.response.status,
          icon: "danger"
        });
      }
    } else {
      /* store.dispatch('addToastMessage', {
             text: error.response.data.message || 'Request error status: ' + error.response.status,
             type: 'danger'
         });*/
    }
    return Promise.reject(error);
  }
);

Vue.config.ignoredElements = [/^ion-/];
/* eslint-disable no-new */
/*new Vue({
  el: "#app",
  store: store,
  router,
  components: {
    App
  },
  template: "<App/>"
});*/

import VueI18n from 'vue-i18n'

import es_json from "./i18n/es.json";


Vue.use(VueI18n)
// Ready translated locale messages
const messages = {
  es: es_json
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'es', // set locale
  messages, // set locale messages
})


new Vue({
  render: h => h(App),
  store,
  router,
  i18n,
  components: { App }
}).$mount('#app')




