<template class="main">
  <div class="heigthNew" id="div2">
      <b-row>
          <b-col md="6" class="backgroundimg4">
          <br />
          <br />
          <br />
      
         <div class="row">
                 
              </div>
               <br />
        <h1 class="textimg">Recuperar contraseña</h1>
                  
         </b-col>
        <b-col md="6">
         <b-row style="margin-top:15px">
          <b-col md="8"> </b-col>
          <b-col md="4">
                    </b-col>
          </b-row>
           <div class="row"  style="margin-top:80px">
                  <img v-if="$i18n.locale == 'es'"
                    class="img-responsive center-block d-block mx-auto"
                    style="
                      max-width: 200px;
                      text-align: center;
                    "
                    src="../../assets/img/logo_colab_es.png"
                  />
                  <img v-if="$i18n.locale == 'en'"
                    class="img-responsive center-block d-block mx-auto"
                    style="
                      max-width: 200px;
                      text-align: center;
                    "
                    src=""
                  />
              </div>
               <br />
        <b-row>
          <b-col md="2"> </b-col>
           <b-col md="8">
           <b-form center @submit="onSubmit" v-if="step===1" style="background: transparent; padding: 20px">
          
             
              <div style="text-align:center">
              <h6 class="text2">Para solicitar la recuperación de tu contraseña debes ingresar el correo con el que te registraste. Te enviaremos un correo para confirmar que la solicitud fue realizada por ti</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-input-group size="lg" prepend="Correo">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              <br />
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary" type="submit">Solicitar</b-button>
                  <br />
                    <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align:center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
          </b-form>
          <b-form center @submit="onSubmit" v-if="step===2" style="background: transparent; padding: 20px" >
          
             
            
              <div style="text-align:justify">
              <h6 class="text2">Para solicitar la recuperación de tu contraseña debes ingresar el correo con el que te registraste. Te enviaremos un correo para confirmar que la solicitud fue realizada por ti</h6>
              </div>
              <br />
              <!-- Using props -->
              <b-input-group size="lg" prepend="email">
                <b-form-input type="email" v-model="email"></b-form-input>
              </b-input-group>
              <br />
              <br />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <b-button block class="primary" type="submit">Solicitar</b-button>
                  <br />
                  <b-row>
                    <b-col md="5"></b-col>
                    <b-col md="2" style="text-align:center">
                      <b-spinner
                        variant="primary"
                        v-show="progress"
                        label="Text Centered"
                      ></b-spinner>
                    </b-col>
                    <b-col md="5"></b-col>
                  </b-row>
                </div>
                <div class="col-md-1"></div>
              </div>
          </b-form> </b-col>
          
            <b-col md="2"> </b-col>
             </b-row>
               <br />
               <b-row>
          <b-col md="1"> </b-col>
           <b-col md="10"> 
           </b-col>
          
            <b-col md="1"> </b-col>
             </b-row>
        </b-col>
       
    
      </b-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import "../../assets/custom-vars.scss";
//import Permission from "../../permission";
import { validate } from "vee-validate";
//import Cookies from "js-cookie";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      progress: false,
      btnLogin: false,
      email: "",
      step:1,
      auth: {},
      lang: "es",
      langs: ["es", "en"],
    };
  },

  computed: {
    ...mapState({}),
    listRolesHasData() {
      return this.listRoles.length ? true : false;
    },
    isSuccessfulUsernameEmailValid() {
      let isValid = false;
      if (this.username) {
        isValid = this.username.validated && this.username.valid;
      }
      return isValid;
    },
    isSuccessfulPasswordValid() {
      let isValid = false;
      if (this.password) {
        isValid = this.password.validated && this.password.valid;
      }
      return isValid;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(["recoveryPassword", "addToastMessage"]),
    onSubmit(evt) {
      evt.preventDefault();
      if (this.email == "") {
        Swal.fire({
          title: "Campos vacios",
          text: "Por favor, verifica que los datos no esten vacios",
          icon: "warning",
          confirmButtonColor: "#00373B",
        });
      } else {
        validate().then((result) => {
          if (result) {
            this.progress = true;
            this.btnLogin = true;
            //GET DEVICE INFO

            this.recoveryPassword({
              email: this.email,
            })
              .then((data) => {
                if (data.code == 200) {
                  this.auth = data;

                  this.btnLogin = false;
                   this.progress = false;
                  Swal.fire({
                    title: this.$i18n.t('requestPassword'),
                    text: this.$i18n.t('textrequestPassword'),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                       if (result.value) {
                          this.step=2;
                        }
                  });
                }
                if (data.code == 404) {
                  this.progress = false;
                  Swal.fire({
                    title: this.$i18n.t('error_email_not_registered'),
                    text: this.$i18n.t('text_error_email_not_registered'),
                    icon: "warning",
                    confirmButtonColor: "#00373B",
                  }).then((result2) => {
                    console.log(result2);
                  });
                }

              })

              .catch((data) => {
                this.progress = false;
                this.btnLogin = false;
                if (data.code == 404) {
                  this.addToastMessage({
                    text: this.$i18n.t('error_email_not_registered'),
                    icon: "warning",
                  });
                  this.progress = false;
                }
              });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.heigthNew {
  min-height: 100%;
}
#div2{
  flex: 1;
}
body {
   height:100%;
  margin:0;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}
.backgroundimg4{
  display: flex;
  flex-direction: column;
  height:100vh;
  min-height: 100%;
  background-image: url("../../assets/img/KAANA-min.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}
.backgroundimg::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.25);
}
     .textimg {
        font-family: 'Syncopate', sans-serif;
        text-align: center;
        color: #ffffff;
        font-weight: 600;
    }
    .text {
        text-align: right;
        color: #00373B;
        font-weight: 600;
    }
     .text2 {
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 8;
    }
     .text3 {
        text-align: center;
        color: #000000;
        font-weight: 600;
        font-size: 0.8rem;
    }
   
    .primary {
 background:linear-gradient(180deg, #00373B 0%, #00373B 100%);
        box-shadow: 0px 4px 18px rgba(1, 167, 172, 0.4);
        border-radius: 1px;
}
    .form-control2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  background-color: #fff;
  border: 0px solid #ffffff;
}
</style>

