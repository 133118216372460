import axios from "axios";
import * as Config from "../../config";

const state = {
    users: {
        current_page: 1,
        data: [],
    },
    user: {},
};
const actions = {

    login({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
            axios
                .post(Config.apiPath + "user/login", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    register({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "user/save", form)
                .then((response) => {
                    console.log("register", form);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    consult_user({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios.get(Config.apiPath + "user/" + form.id, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    modify_user({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "user/update/" + form.id, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    loadUsers({ commit, dispatch }, ) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "user/all";


        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    changeStatus_user({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "user/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


    /* SIN USAR */
    recoveryPassword({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "auth/recover_password/", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    recoveryNewPassword({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(Config.apiPath + "auth/set_new_password", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


    adminByID({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + "admin/" + form.admin_id)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },


    changeStatusUser({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .put(
                    Config.apiPath + "user/change_status/" + form.id + "/" + form.active
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    loadUserLocation({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "user_location/all?active=1&id_user="+query;
        return new Promise((resolve, reject) => {
            axios
                .get(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    add_userlocation({ commit, dispatch }, form) {
        console.log(dispatch);
        console.log(commit);
        return new Promise((resolve, reject) => {
            axios
                .post(Config.apiPath + "user_location/save", form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    delete_userlocation({ commit, dispatch }, query) {
        console.log(dispatch);
        console.log(commit);
        let endpoint = "user_location/delete/"+query.id;
        return new Promise((resolve, reject) => {
            axios.delete(Config.apiPath + endpoint)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

};

const mutations = {
    LOAD_DATA_USER(state, data) {
        state.user = data;
    },

    LOAD_USERS_OK(state, users) {
        state.users = users;
    },

    LOAD_USER_OK(state, user) {
        state.user = user;
    },

    LOAD_USER_POINT_OK(state, user) {
        state.user = user;
    },

    UPDATE_USER_OK(state, user) {
        state.user = user;
    },
    LOAD_DATA_REQUEST(state, data) {
        state.request = data;
    },
    LOAD_DATA_UNCREATED(state, data) {
        state.uncreated = data;
    },
};

export default {
    state,
    actions,
    mutations,
};